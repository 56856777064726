// Angular
import { Component, OnInit } from '@angular/core'
import { UserProfileService } from './../../../../../core/services/user-profile.service'
import { UserProfile } from '../../../../../shared/models/corebusiness/user-profile'
import { AuthService } from '@app/core/services/auth.service';
import { DOMAIN } from '@app/shared/app.constant';
import { Router } from '@angular/router';
import { MenuConfig } from '@app/core/_config/default/menu.config'
import { UserRole } from '@app/shared/models/user_role'

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user: UserProfile
	roleIssuer: boolean = true;
	public dwLicenseExpireDate = null;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private userProfileService: UserProfileService,
		private authenService: AuthService,
		private router: Router,
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.user = this.userProfileService.getUserProfile()
		if (this.user.isDwSET == true || this.user.isDwSEC == true || this.user.isOperation == true) {
			this.roleIssuer = false
			const config = new MenuConfig();
			const items: { page: string, only: string[] }[] = config.configs.aside.items;
			const item = items.find(({ page, only }) => this.router.url.includes(page) && ([UserRole.DW_SEC, UserRole.DW_SET, UserRole.DW_OPERATION].some((dw) => only.includes(dw))));
			if (!item) {
				this.router.navigate([DOMAIN.URL + '/sec-admin/submit-application'])
			}
		}
		this.dwLicenseExpireDate = this.getDwLicenseExpireDate();
	}

	getDwLicenseExpireDate(): string {
		return this.user.company.dwLicenseExpireDate ? this.user.company.dwLicenseExpireDate : null;
	}

	/**
	 * Log out
	 */
	logout() {
		this.authenService.logout()
	}
}
