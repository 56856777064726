// Angular
import { Component, Inject, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { isNullOrUndefined } from 'util';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'kt-confirm-entity-dialog',
	templateUrl: './confirm-entity-dialog.component.html'
})
export class ConfirmEntityDialogComponent implements OnInit {
	// Public properties
	viewLoading: boolean = false;
	alert: any = false;
	messages: string[]
	messagesBtn: string[];
	messagesBtnSubmit: string
	messagesBtnClose: string
	@Output() beforeClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(
		public dialogRef: MatDialogRef<ConfirmEntityDialogComponent>,
		private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.alert = this.data.alert;
		this.messages = this.data.messages;
		this.messagesBtnSubmit = this.data.messagesBtn && !isNullOrUndefined(this.data.messagesBtn[0]) ? this.data.messagesBtn[0] : this.translate.instant('MODAL.BUTTON.SUBMIT');
		this.messagesBtnClose = this.data.messagesBtn && !isNullOrUndefined(this.data.messagesBtn[1]) ? this.data.messagesBtn[1] : this.translate.instant('MODAL.BUTTON.CLOSE');
	}


	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.beforeClosed.emit(false);
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		/* Server loading imitation. Remove this */
		this.viewLoading = true;
		// this.dialogRef.close(true); // Keep only this row
		this.beforeClosed.emit(true);
	}
}
