import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable, isDevMode } from '@angular/core'
import { RefreshToken } from '../../shared/models/refresh-token'
import { UserProfile } from '../../shared/models/corebusiness/user-profile'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { ApiClientService } from './api-client.service'
import { Term } from '../_base/layout/models/term.model';
import { environment } from '@env/environment'
import { API } from '@app/shared/app.constant'

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private currentUserSubject: BehaviorSubject<
		UserProfile
	> = new BehaviorSubject<UserProfile>(null)
	public currentUser: Observable<UserProfile>
	constructor(
		private apiService: ApiClientService,
		private http: HttpClient,
		@Inject('API_HOST') private apiUrl: string,
	) { }

	createHeaders(): HttpHeaders {
		let headers = new HttpHeaders()
		if (!isDevMode()) {
			headers = headers.append(
				'Cache-Control',
				'no-cache, no-store, must-revalidate'
			)
			headers = headers.append('Pragma', 'no-cache')
			headers = headers.append('Expires', '0')
		}
		return headers
	}
	createRequestOptions() {
		const httpOptions = {
			headers: this.createHeaders()
		}
		return httpOptions
	}

	setToken(token: string, refreshToken?: string) {
		sessionStorage.setItem('token', token)
		sessionStorage.removeItem('term')
		if (refreshToken) {
			sessionStorage.setItem('refreshToken', refreshToken)
		}
	}

	getToken(): string {
		return sessionStorage.getItem('token')
	}

	getRefreshToken(): Observable<RefreshToken> {
		const path = API.refreshToken
		const refreshToken = sessionStorage.getItem('refreshToken')
		let headers = new HttpHeaders({
			Authorization: `Bearer ${refreshToken}`
		})
		let options = { headers: headers }
		return this.http.post<RefreshToken>(path, null, options)
	}

	getUserProfile(): Observable<any> {
		const url = API.auth
		return this.apiService.postAuthen<UserProfile>(url).pipe(
			map(
				userProfile => {
					sessionStorage.setItem('currentUser', JSON.stringify(userProfile))
					this.currentUserSubject.next(userProfile.data)
					return userProfile
				}),
			catchError(this.handleError)
		)
	}

	logout() {
		sessionStorage.removeItem('token')
		sessionStorage.removeItem('refreshToken')
		sessionStorage.removeItem('currentUser')
		sessionStorage.removeItem('term')
		location.href = environment.LoginUrl;
		// this.currentUserSubject.next(null)
	}

	public get currentUserValue(): UserProfile {
		return this.currentUserSubject.value
	}

	private handleError(error: any) {
		// In a real world app, we might send the error to remote logging infrastructure
		const errMsg = error.message || 'Server error'
		return throwError(errMsg)
	}

	public setTerm(req) {
		let accept = req === true ? true : false
		const term = new Term({ accept: accept });
		sessionStorage.setItem('term', JSON.stringify(term));
	}
}
