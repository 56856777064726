export class Action {
	name: string
	label: string
	alignment?: string
	type?: ActionType
	valueClass?: string
	width = ''
	btnText: string
	flagName: string
	id: string
	href: string
	link: string
	style: string
	dropdownItems: ActionDropdownItem[]
	disabled: boolean

	constructor(fields) {
		this.valueClass = ''
		this.alignment = 'left'
		Object.assign(this, fields)

	}
}

export class ActionDropdownItem {
	label: string
	onClick: boolean
	link: any
	params: any

	constructor(fields) {
		Object.assign(this, fields)
	}
}

export enum ActionType {
	submit,
	btn,
	link,
	text,
	danger,
	dropdown
}

export function enableAction(action: Action, enable: boolean) {
	action.disabled = !enable
}
