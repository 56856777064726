// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { UserProfileService } from '@app/core/services/user-profile.service';
import { UserProfile } from '@app/shared/models/corebusiness/user-profile';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class MenuAsideService {
	// Public properties
	classes: string;
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);


	/**
	 * Service Constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 * @param store: Store<AppState>
	 */
	constructor(
		private menuConfigService: MenuConfigService,
		private userProfileService: UserProfileService,
		private activeRoute: ActivatedRoute
	) {
		this.loadMenu();
	}

	/**
	 * Load menu
	 */
	loadMenu() {
		// get menu list
		const menuItems: any[] = this.getMenuItems();
		this.menuList$.next(menuItems);
	}

	private getMenuItems(): any[] {
		if (!this.userProfileService.isSession()) {
			return []
		}
		const userProfile: UserProfile = this.userProfileService.getSessionUser();
		const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
		return menuItems.filter((item: { only?: string[] }) => {
			if (Array.isArray(item.only) && item.only.length > 0) {
				return item.only.filter(r => userProfile.rolesName.includes(r)).length > 0;
			}
			return item;
		});
	}
}
