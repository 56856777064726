
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiClientService } from '../api-client.service'
import { API } from '../../../shared/app.constant'
import { UserProfileService } from '../user-profile.service';
import { Qualification } from '@app/shared/models/corebusiness/qualification';
import { isNullOrUndefined } from 'util';
import { TranslateService } from '@ngx-translate/core';
import * as numeral from 'numeral';

@Injectable()
export class QualificationService {

  constructor(
    private apiService: ApiClientService,
    public userProfileService: UserProfileService,
    public translate: TranslateService
  ) { }

  defaultOption = { text: this.translate.instant('SELECT'), value: null }

  public getShareholderEquityQuarter() {
    return [
      { text: this.translate.instant('SELECT'), value: null },
      { text: 'ไตรมาส 1', value: 'Q1' },
      { text: 'ไตรมาส 2', value: 'Q2' },
      { text: '6 เดือน หรือครึ่งปี', value: '6M' },
      { text: 'ไตรมาส 3', value: 'Q3' },
      { text: 'ปี', value: 'ANNUAL' }
    ]
  }

  public getShareholderEquityYear() {
    const now = new Date()
    let shareholderEquityYear = new Array()
    let buddhaYear = now.getUTCFullYear() + 1
    shareholderEquityYear.push({ text: this.translate.instant('SELECT'), value: null })
    for (let index = 0; index < 3; index++) {
      let buddhaYearPlus = buddhaYear - index
      shareholderEquityYear.push({ text: buddhaYearPlus, value: buddhaYearPlus })
    }
    return shareholderEquityYear
  }

  // ผู้จัดอันดับความน่าเชื่อถือ
  public getCompanyRating(): Observable<any> {
    const url = API.GET_FINANCIAL_COMPANY_RATING
    return this.apiService.get(url)
  }

  // อันดับเครดิต
  // logic : เอาบริษัทผู้คำประกัน ส่งข้อมูลมา Filter ที่ อันดับเครดิต
  public getRatingAgencyByUniqueIdReference(uniqueIdReference): Observable<any> {
    const url = API.GET_FINANCIAL_RATING_AGENCY
    return this.apiService.get(url + `/${uniqueIdReference}`)
  }

  // บริษัทผู้ค้ำประกัน
  public getCompanyListByKeyword(keyword: string): Observable<any> {
    const url = API.GET_FINANCIAL_COMPANY_LIST
    return this.apiService.get(url + `?keyword=${keyword}`)
  }

  // อันดับความน่าเชื่อถือของ
  // API : qualification/financial/rating/preference-type
  public getRatingPreferenceType(): Observable<any> {
    const url = API.GET_FINANCIAL_RATING_PREFERENCE
    return this.apiService.get(url)
  }


  public setCreditRatingOf(ratingAgency) {
    let creditRatingOf = new Array()
    creditRatingOf.push(this.defaultOption)
    if (!isNullOrUndefined(ratingAgency)) {
      ratingAgency.forEach(
        element => {
          creditRatingOf.push({
            text: element.ratingDesc,
            value: element.ratingCode,
            data: element
          })
        });
    }
    return creditRatingOf
  }

  public getFinancial(id: string): Observable<any> {
    const url = API.GET_FINANCIAL
    return this.apiService.get<Qualification>(url + `/${id}`)
  }

  public updateFinancial(id: string, data: { [s: string]: any }): Observable<any> {
    const url = API.UPDATE_FINANCIAL
    const json = new FormData()


    json.set('creditRatingFile', data.creditRatingFile)
    json.set('guarantorCreditRatingFile', data.guarantorCreditRatingFile)
    json.set('guarantorFile', data.guarantorShareholderFile)
    json.set('guarantorDocumentFile', data.guarantorDocumentFileName)
    json.set('guarantorContractFile', data.guarantorContractFileName)
    json.set('isGuarantorContractFile', `${!!data.guarantorContractFileName}`);

    json.set('financialQualificationRequest',
      JSON.stringify({
        interestRate: data.interestRate ? this.toNumeral(data.interestRate) : 0,
        isCreditRating: data.isCreditRating,
        creditRatingCompanyName: data.creditRatingCompanyName,
        creditRatingCompanyNameEn: data.creditRatingCompanyNameEn,
        creditRatingCompanyId: data.creditRatingCompanyId,
        creditRatingPreferenceTypeSecId: data.creditRatingPreferenceType ? data.creditRatingPreferenceType['value'] : null,
        creditRatingPreferenceTypeNameTh: data.creditRatingPreferenceType ? data.creditRatingPreferenceType['text'] : null,
        creditRating: data.creditRating,
        creditRatingDesc: data.creditRatingDesc,
        creditRatingMaturityFlag: data.creditRatingMaturityFlag,
        creditRatingDate: data.creditRatingDate,

        isShareholderEquity: data.isShareholderEquity,
        shareholderEquityQuarter: data.shareholderEquityQuarter,
        shareholderEquityYear: data.shareholderEquityYear,
        shareholderEquityAmount: Math.floor(this.toNumeralInt(data.shareholderEquityAmount)),

        isGuarantor: data.isGuarantor,
        guarantorDocumentNo: data.guarantorDocumentNo,
        guarantorCompanyName: data.guarantorCompanyName,
        guarantorDisplayCompanyName: data.guarantorDisplayCompanyName,
        guarantorCompanyId: data.guarantorCompanyId,
        guarantorCreditRatingCompanyAddress: data.guarantorCreditRatingCompanyAddress,
        guarantorCreditRatingCompanyName: data.guarantorCreditRatingCompanyName,
        guarantorCreditRatingCompanyNameEn: data.guarantorCreditRatingCompanyNameEn,
        guarantorCreditRatingCompanyId: data.guarantorCreditRatingCompanyId,
        guarantorCreditRatingPreferenceTypeSecId: data.guarantorCreditRatingPreferenceType ? data.guarantorCreditRatingPreferenceType['value'] : null,
        guarantorCreditRatingPreferenceTypeNameTh: data.guarantorCreditRatingPreferenceType ? data.guarantorCreditRatingPreferenceType['text'] : null,
        guarantorCreditRating: data.guarantorCreditRating,
        guarantorCreditRatingDesc: data.guarantorCreditRatingDesc,
        guarantorCreditRatingMaturityFlag: data.guarantorCreditRatingMaturityFlag,
        guarantorCreditRatingDate: data.guarantorCreditRatingDate,

        guarantorShareholderEquityQuarter: data.guarantorShareholderEquityQuarter,
        guarantorShareholderEquityYear: data.guarantorShareholderEquityYear,
        guarantorShareholderEquityAmount: Math.floor(this.toNumeralInt(data.guarantorShareholderEquityAmount)),
        remark: data.remark
      })
    )
    return this.apiService.post<any>(url + `/${id}`, json)
  }

  public downloadFilingQualification(id: string, fileSystemName: string): Observable<any> {
    const url = API.DOWNLOAD_FILE_QUALIFICATION
    return this.apiService.getBlob<any>(url + `/${id}/` + fileSystemName)
  }

  toNumeral(value) {
		return numeral(value).format('0,0.00[000]').split(',').join('')
	}
	
	toNumeralInt(value) {
		return numeral(value).format('0,0').split(',').join('')
	}
}

