export class Allowance {
  indexLicense: boolean
  shelf: boolean
  tsd: boolean
  marketMaker: boolean
  paid: boolean
  accessTime: boolean
  financialStatementUpToDate: boolean;
  financialStatementSent: boolean;
  errorAccessTimeMessage: string;
}
