// Angular
import { AfterViewInit, Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ViewRef_ } from '@angular/core/src/view';
// RxJS
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';

@Component({
	selector: 'kt-subheader1',
	templateUrl: './subheader1.component.html',
	styleUrls: ['./subheader1.component.scss']
})
export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
	// Public properties
	today: number = Date.now();
	title: string = '';
	desc: string = '';
	breadcrumbs: Breadcrumb[] = [];

	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param subheaderService: SubheaderService
	 */
	constructor(
		public subheaderService: SubheaderService,
		private cdr: ChangeDetectorRef
	) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.subscriptions.push(
			this.subheaderService.title$.subscribe(bt => {
				// breadcrumbs title sometimes can be undefined
				if (!isNullOrUndefined(bt)) {
					Promise.resolve(null).then(() => {
						this.title = bt.title;
						this.desc = bt.desc;
						this.cdr.markForCheck();
					});
				} else {
					this.title = ''
				}
				this.detectChanges()
			}));

		this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(bc => {
			// Promise.resolve(null).then(() => {
			this.breadcrumbs = bc;
			// });
		}));
	}

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	detectChanges() {
		setTimeout(() => {
		  if (!isNullOrUndefined(this.cdr) &&
			!(this.cdr as ViewRef_).destroyed) {
			  this.cdr.detectChanges()
		  }
		}, 100);
	  }
}
