import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private $store: BehaviorSubject<boolean>;
  constructor(
    public coreService: CoreService,
  ) {
    this.$store = new BehaviorSubject<boolean>(false);
  }

  public show() {
    this.setSpinner(true)
  }

  public hide() {
    this.setSpinner(false)
  }

  public setSpinner(spinner: boolean) {
    this.$store.next(spinner);
  }
  public getSpinner(): Observable<boolean> {
    return this.$store.asObservable();
  }
}
