// Angular
import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
	MatAutocompleteModule,
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatDialogModule,
	MatIconModule,
	MatInputModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSnackBarModule,
	MatSortModule,
	MatTableModule,
	MatTabsModule,
	MatTooltipModule,
	DateAdapter,
	MAT_DATE_LOCALE,
	MAT_DATE_FORMATS
} from '@angular/material'
// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
// Core module
import { CoreModule } from '../../core/core.module'
// CRUD Partials
import {
	ActionNotificationComponent,
	AlertComponent,
	ConfirmEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from './content/crud'
// Layout partials
import {
	LanguageSelectorComponent,
	ScrollTopComponent,
	SplashScreenComponent,
	Subheader1Component,
	UserProfileComponent
} from './layout'
// General
import { NoticeComponent } from './content/general/notice/notice.component'
import { PortletModule } from './content/general/portlet/portlet.module'
// Errpr
import { ErrorComponent } from './content/general/error/error.component'
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg'
import { TranslateModule } from '@ngx-translate/core';
import { ActionbarComponent } from './layout/actionbar/actionbar.component';
import { DisclaimerComponent } from '../pages/disclaimer/disclaimer.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActionComponent } from './layout/actionbar/action/action.component';
import { RoutePageComponent } from '../pages/route-page/route-page.component';

export const MY_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'DD/MM/YYYY',
		dateA11yLabel: 'DD/MM/YYYY',
		monthYearA11yLabel: 'DD/MM/YYYY',
	},
};

@NgModule({
	declarations: [
		ScrollTopComponent,
		NoticeComponent,
		ActionNotificationComponent,
		ConfirmEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,

		// topbar components
		ScrollTopComponent,
		SplashScreenComponent,
		Subheader1Component,
		LanguageSelectorComponent,
		UserProfileComponent,
		ErrorComponent,
		ActionbarComponent,
		DisclaimerComponent,
		ActionComponent,
		RoutePageComponent
	],
	exports: [
		PortletModule,
		ScrollTopComponent,
		NoticeComponent,
		ActionNotificationComponent,
		ConfirmEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,

		// topbar components
		ScrollTopComponent,
		SplashScreenComponent,
		Subheader1Component,
		LanguageSelectorComponent,
		UserProfileComponent,
		ErrorComponent,
		ActionbarComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		PerfectScrollbarModule,
		InlineSVGModule,
		CoreModule,
		PortletModule,
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		TranslateModule.forChild()
	], providers: [
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
})
export class PartialsModule { }
