import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClientService } from '../api-client.service';
import { API } from '@app/shared/app.constant';
import { DefaultStorePipe } from './default-store-pipe';
import { mergeAll } from 'rxjs/operators';
import { Issuer } from '@app/shared/models/issuer';

@Injectable()
export class DwIssuerService {

  private dmIssuerStore$: Observable<Issuer[]>;

  constructor(
    private apiService: ApiClientService,
  ) { }

  public dmIssuers$(): Observable<Issuer[]> {
    if (!this.dmIssuerStore$) {
      this.dmIssuerStore$ = this.apiService.get(API.GET_DW_ISSUER)
      .pipe(
        mergeAll(),
        DefaultStorePipe,
      ) as Observable<Issuer[]>
    }
    return this.dmIssuerStore$;
  }
}
