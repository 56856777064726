import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AuthService } from './services/auth.service'

@Injectable()
export class JwtService implements HttpInterceptor {
	constructor(private authService: AuthService) {}
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const isUrlRefreshToken = request.url.includes('refreshToken')
		const isCallApi = request.url.includes('api')

		if (isCallApi && !isUrlRefreshToken) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${this.authService.getToken()}`
				}
			})
		}
		return next.handle(request)
	}
}
