import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { ActionType, Action } from '../../../../shared/models/action'
import { Allowance } from '@app/shared/models/response/allowance';

export interface ActionEventEmit {
	action: string;
	item: any;
	spliceIndex: number;
}

@Component({
	selector: 'kt-actionbar',
	templateUrl: './actionbar.component.html',
	styleUrls: ['./actionbar.component.scss']
})
export class ActionbarComponent implements OnInit {
	columnType = ActionType

	@Input() columns: Action[]
	@Input() allowance: Allowance
	@Input() displayMessage: boolean
	@Output() eventClick: EventEmitter<ActionEventEmit> = new EventEmitter<ActionEventEmit>()
	@Input() displayAccessTime: boolean
	@Input() displayCannotCancelQuota: boolean
	@Input() displayCheckBeforeSubmit: boolean
	@Input() showSubmit?:boolean=true

	constructor() { }

	ngOnInit() {

	}

	onClick(event, eventId) {
		// 'btn-' is button save , update , delete
		if (eventId.includes('btn-')) {
			this.disabledButton($('#' + eventId))
		}
		this.eventClick.emit(event)
		if (eventId.includes('btn-')) {
			this.enabledButton($('#' + eventId))
		}
	}

	enabledButton(id) {
		setTimeout(() => {
			id.removeAttr('disabled')
		}, 1500);
	}

	disabledButton(id) {
		id.attr('disabled', 'true')
	}

}
