import { UserRole } from '@app/shared/models/user_role';

export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			'items': [
				{
					'title': 'Pages',
					'root': true,
					'icon-': 'flaticon-add',
					'toggle': 'click',
					'custom-class': 'kt-menu__item--active',
					'alignment': 'left',
					submenu: []
				},
				{
					'title': 'Features',
					'root': true,
					'icon-': 'flaticon-line-graph',
					'toggle': 'click',
					'alignment': 'left',
					submenu: []
				},
				{
					'title': 'Apps',
					'root': true,
					'icon-': 'flaticon-paper-plane',
					'toggle': 'click',
					'alignment': 'left',
					submenu: []
				}
			]
		},
		companyprofile: {
			self: {},
			'items': [
				{
					'title': 'ข้อมูลบริษัท',
					'root': true,
					'icon-': 'flaticon-add',
					'toggle': 'click',
					'custom-class': 'kt-menu__item--active',
					'alignment': 'left',
					submenu: []
				},
				{
					'title': 'ข้อมูลผู้มีอำนาจ',
					'root': true,
					'icon-': 'flaticon-line-graph',
					'toggle': 'click',
					'alignment': 'left',
					submenu: []
				},
				{
					'title': 'ผู้ดูแลสภาพคล่อง',
					'root': true,
					'icon-': 'flaticon-paper-plane',
					'toggle': 'click',
					'alignment': 'left',
					submenu: []
				},
				{
					'title': 'เอกสารแนบ',
					'root': true,
					'icon-': 'flaticon-paper-plane',
					'toggle': 'click',
					'alignment': 'left',
					submenu: []
				}
			]
		},
		aside: {
			self: {},
			items: [
				{ section: 'ยื่นคำขอรายครั้ง' },
				{
					title: 'QUOTA',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'quota',
					translate: 'MENU.SECURITIESINDEXPROFILESHORT',
					bullet: 'dot',
					only: [UserRole.DW_SENDER, UserRole.DW_ISSUER]
				},
				{
					title: 'SEC Admin Filing 35-DW-1',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'sec-admin/shelf-issuer',
					translate: 'MENU.FILING35DW_1',
					bullet: 'dot',
					only: [UserRole.DW_SEC, UserRole.DW_OPERATION]
				},
				{
					title: 'SEC Admin Filing 69-DW-Full\/Short',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'sec-admin/submit-application',
					translate: 'MENU.FILING69DWSHORTANDFULL',
					bullet: 'dot',
					only: [UserRole.DW_SEC, UserRole.DW_SET, UserRole.DW_OPERATION]
				},
				{
					title: 'SEC Admin Filing 69-DW-Short',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'short',
					translate: 'MENU.FILING69DWSHORT',
					bullet: 'dot',
					only: [UserRole.DW_SENDER, UserRole.DW_ISSUER]
				},
				{
					title: 'Company Profile',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'company-profile-list',
					translate: 'MENU.COMPANY_PROFILE_LIST',
					bullet: 'dot',
					only: [UserRole.DW_SEC, UserRole.DW_SET, UserRole.DW_OPERATION]
				},
				{
					title: 'Subscription Profile',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'subscripiton-profile',
					translate: 'MENU.SUBSCRIPTION_PROFILE_MENU',
					bullet: 'dot',
					only: [UserRole.DW_OPERATION]
				},
				{
					section: 'ยื่นคำขอครั้งแรก/ต่ออายุ',
					only: [UserRole.DW_SENDER, UserRole.DW_ISSUER]
				},
				{
					title: 'แบบคำขอ35-DW-1',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'shelf-issuer-application/list',
					translate: 'MENU.FILING35DW_1',
					bullet: 'dot',
					only: [UserRole.DW_SENDER, UserRole.DW_ISSUER]
				},
				{
					title: 'Filing 69-DW-Full',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'submit-application/list',
					translate: 'MENU.FILING69DWFULL',
					bullet: 'dot',
					only: [UserRole.DW_SENDER, UserRole.DW_ISSUER]
				},
				{
					title: 'Monitoring Board',
					root: true,
					icon: 'flaticon-dashboard',
					page: 'monitoring-board',
					translate: 'MENU.MONITORING',
					bullet: 'dot',
					only: [UserRole.DW_MONITOR]
				},
				{
					section: 'ข้อมูลประกอบการจองโควตา'
				},
				{
					title: 'หุ้นอ้างอิงคงเหลือ',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'issued-room/list',
					translate: 'MENU.ISSUED_ROOM',
					bullet: 'dot',
				},
				{
					title: 'รายชื่อหุ้นอ้างอิงที่มี Corporate Action',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'corporate-action/list',
					translate: 'MENU.CORPORATE_ACTION',
					bullet: 'dot',
				},
				{
					title: 'ผลการทำหน้าที่ MM / ส่วนลด listing fee',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'incentive-report/list',
					translate: 'MENU.INCENTIVE_REPORT',
					bullet: 'dot',
					only: [UserRole.DW_SENDER, UserRole.DW_ISSUER]
				},
				{
					section: 'การเรียกดูข้อมูล/Log',
					only: [UserRole.DW_OPERATION]
				},
				{
					title: 'Inquiry',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'inquiry-log/list',
					translate: 'MENU.INQUIRY_LOG',
					bullet: 'dot',
					only: [UserRole.DW_OPERATION]
				},
				{
					section: 'ข้อมูลที่เกี่ยวข้อง'
				},
				{
					title: 'ข้อมูลที่เกี่ยวข้อง Derivatives Warrant',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'document/list',
					translate: 'MENU.DWS_DOCUMENT',
					bullet: 'dot'
				},
				{
					section: 'การจัดการสินทรัพย์อ้างอิง',
					only: [UserRole.DW_SENDER, UserRole.DW_ISSUER]
				},
				{
					title: 'แบบคำขอเพิ่ม Foreign Underlying',
					root: true,
					icon: 'flaticon-interface-11',
					page: 'isos',
					translate: 'MENU.ISOS_URL_REDIRECT',
					bullet: 'dot',
					only: [UserRole.DW_SENDER, UserRole.DW_ISSUER]
				}
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
