import { Injectable } from '@angular/core'
import { UserProfile } from '../../shared/models/corebusiness/user-profile'
import { isNullOrUndefined } from 'util';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class UserProfileService {

	private _key: string = 'currentUser';

	constructor(
		private store: Store<AppState>,
		private authService: AuthService
	) {

	}
	public getUserProfile(): UserProfile {
		if (!isNullOrUndefined(sessionStorage.getItem(this._key))) {
			return Object.assign(new UserProfile(), JSON.parse(sessionStorage.getItem(this._key)));
		} else {
				this.authService.logout();
				return null;
		}
	}

	public getSessionUser(): UserProfile {
		return Object.assign(new UserProfile(), JSON.parse(sessionStorage.getItem(this._key)));
	}

	public isSession(): boolean {
		return !!sessionStorage.getItem(this._key);
	}

}
