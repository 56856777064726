export const environment = {
	production: false,
	requestTimeout: 180000,
	dwsApiBaseUrl: 'https://dwsuat.setportal.set.or.th/api/',
	LoginUrl: 'https://uat.setportal.set.or.th/setdd',
	digitalSignatureBaseUrl: 'https://web-example-secsignv4-api-iwt.sec.or.th',
	SEC_API: 'https://web-dw-api-iwt.sec.or.th/api/',
	SET_URL: 'https://uat.setportal.set.or.th/',
	DW_QUOTA_FLAG: true,
	digitalSignShortTimeout: 600000,
	digitalSignFullTimeout: 600000,
	isosApiBaseUrl: 'https://iosuat.setportal.set.or.th'
}
