import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfileService } from '../user-profile.service';
import { ApiClientService } from '../api-client.service';
import * as moment from 'moment'
import { isNullOrUndefined } from 'util';
import { OwnerDw } from '@app/shared/models/response/owner-dw';
import { AllocateDWFilter } from '@app/shared/models/request/allocate-dw-filter';
import { DigitalSignature } from '../../../shared/models/corebusiness/digital-signature';
import * as numeral from 'numeral';
import { map } from 'rxjs/operators';
import { API } from '@app/shared/app.constant';
import { QuotaExcel } from '@app/shared/models/request/quota-excel';

@Injectable()
export class QuotaService {
  constructor(
    private apiService: ApiClientService,
    public userProfileService: UserProfileService
  ) { }

  public search(allocateDWFilter: AllocateDWFilter): Observable<any> {
    const url = API.SEARCH_ALLOCATEDW
    return this.apiService.post<any>(url, allocateDWFilter)
  }

  public getAllocateDW(id): Observable<any> {
    const url = API.GET_ALLOCATEDW
    return this.apiService.get<any>(url + `/${id}`)
  }

  public saveListed(fg): Observable<any> {
    const url = API.SAVE_ALLOCATE_LISTED
    const deviation = this.transformDeviation(fg)

    const allocateProfileDW = {
      id: fg.id,
      allocateType: fg.allocateType,
      bidOfferPriceSpread: !isNullOrUndefined(fg.bidOfferPriceSpread) ? this.toNumeralInt(fg.bidOfferPriceSpread) : null,
      blackScholesModelPriceAsOf: !isNullOrUndefined(fg.blackScholesModelPriceAsOf) ? fg.blackScholesModelPriceAsOf : null,
      conversionRatio: !isNullOrUndefined(fg.conversionRatio) ? this.toNumeral(fg.conversionRatio) : null,
      conversionRatioDenominator: fg.conversionRatioDenominator ? this.toNumeral(fg.conversionRatioDenominator) : null,
      conversionRatioNumerator: fg.conversionRatioNumerator ? this.toNumeral(fg.conversionRatioNumerator) : null,
      deviation: deviation,
      deviationAsOf: fg.deviationAsOf ? fg.deviationAsOf : null,
      distributeDWPercent: fg.distributeDWPercent ? this.toNumeral(fg.distributeDWPercent) : null,
      distributeDWValue: fg.distributeDWValue ? this.toNumeral(fg.distributeDWValue) : null,
      dw: fg.dw ? fg.dw : null,
      dwExpectTradingDate: fg.dwExpectTradingDate ? fg.dwExpectTradingDate : null,
      dwExpiredDate: fg.dwExpiredDate ? fg.dwExpiredDate : null,
      dwLastTradingDate: fg.dwLastTradingDate ? moment(fg.dwLastTradingDate, 'YYYY-MM-DD').toDate() : null,
      requestDate: fg.requestDate ? fg.requestDate : null,
      dwRequestCode: fg.dwRequestCode,
      dwSeries: fg.dwSeries ? fg.dwSeries : null,
      dwType: fg.dwType ? fg.dwType : null,
      endSPPostedDate: fg.endSPPostedDate ? moment(fg.endSPPostedDate, 'YYYY-MM-DD').toDate() : null,
      exercisePrice: fg.exercisePrice ? this.toNumeral(fg.exercisePrice) : null,
      exerciseType: fg.exerciseType ? fg.exerciseType : null,
      exchangeRate: fg.exchangeRate ? fg.exchangeRate : null,
      exchangeRateAsOf: fg.exchangeRateAsOf ? moment(fg.exchangeRateAsOf, 'YYYY-MM-DD').toDate() : null,
      issuePrice: fg.issuePrice ? this.toNumeral(fg.issuePrice) : null,
      minimumBidOfferTradingHour: !isNullOrUndefined(fg.minimumBidOfferTradingHour) ? this.toNumeral(fg.minimumBidOfferTradingHour) : null,
      minimumBidOfferUnit: !isNullOrUndefined(fg.minimumBidOfferUnit) ? this.toNumeralInt(fg.minimumBidOfferUnit) : null,
      minimumMarketMakerDWPrice: fg.minimumMarketMakerDWPrice ? this.toNumeral(fg.minimumMarketMakerDWPrice) : null,
      modifyDate: fg.modifyDate ? fg.modifyDate : null,
      nameEng: fg.nameEng,
      nameThai: fg.nameThai,
      offerDW: fg.offerDW ? this.toNumeralInt(fg.offerDW): null,
      offerType: fg.offerType ? fg.offerType : null,
      par: fg.par ? fg.par : null,
      passFurtherDate: fg.passFurtherDate ? fg.passFurtherDate : null,
      requestedName: fg.requestedName,
      reserveUnderlying: fg.reserveUnderlying ? numeral(fg.reserveUnderlying).format('0,0').split(',').join('') : null,
      securitiesIndex: fg.securitiesIndex ? fg.securitiesIndex : null,
      settlementStyle: fg.settlementStyle ? fg.settlementStyle : null,
      specialFeature: fg.specialFeature ? fg.specialFeature : null,
      startSPPostedDate: fg.startSPPostedDate ? moment(fg.startSPPostedDate, 'YYYY-MM-DD').toDate() : null,
      subscriptionProfile: fg.subscriptionProfile ? { id: fg.subscriptionProfile } : null,
      relevantStockExchange: fg.relevantStockExchange ? fg.relevantStockExchange : null,
      valuePrice: fg.valuePrice ? this.toNumeral(fg.valuePrice) : null,
      valuePriceTotal: fg.valuePriceTotal ? fg.valuePriceTotal : null,
      underlying: fg.underlying ? fg.underlying : null,
      urlCompany: fg.urlCompany ? fg.urlCompany : null,
      urlStockPrice: fg.urlStockPrice ? fg.urlStockPrice : null,
      urlStockFinancialStatement: fg.urlStockFinancialStatement ? fg.urlStockFinancialStatement : null,
    }
    const json = new FormData();
    json.set('allocateListedDW', JSON.stringify(allocateProfileDW))
    return this.apiService.post<any>(url, json)
  }

  transformDeviation(fg) {
    if (!isNullOrUndefined(fg.deviation90)) {
      const deviation90 = (!isNullOrUndefined(fg.deviation90) && fg.deviation90 !== '') ? this.toNumeral(fg.deviation90) : ''
      const deviation120 = (!isNullOrUndefined(fg.deviation120) && fg.deviation120 !== '') ? this.toNumeral(fg.deviation120) : ''
      const deviation180 = (!isNullOrUndefined(fg.deviation180) && fg.deviation180 !== '') ? this.toNumeral(fg.deviation180) : ''
      const deviation250 = (!isNullOrUndefined(fg.deviation250) && fg.deviation250 !== '') ? this.toNumeral(fg.deviation250) : ''
      return `${deviation90}:90|${deviation120}:120|${deviation180}:180|${deviation250}:250`
    } else {
      return null
    }
  }

  public saveProfile(fg): Observable<any> {
    const url = API.SAVE_ALLOCATE_PROFILE
    const deviation = this.transformDeviation(fg)
    let allocateProfileDW = {
      id: fg.id,
      allocateType: fg.allocateType,
      bidOfferPriceSpread: !isNullOrUndefined(fg.bidOfferPriceSpread) ? this.toNumeralInt(fg.bidOfferPriceSpread) : null,
      blackScholesModelPriceAsOf: !isNullOrUndefined(fg.blackScholesModelPriceAsOf) ? fg.blackScholesModelPriceAsOf : null,
      conversionRatio: !isNullOrUndefined(fg.conversionRatio) ? this.toNumeral(fg.conversionRatio) : null,
      conversionRatioDenominator: 1,
      conversionRatioNumerator: !isNullOrUndefined(fg.conversionRatio) ? this.toNumeral(fg.conversionRatio) : null,
      deviation: deviation,
      deviationAsOf: fg.deviationAsOf ? fg.deviationAsOf : null,
      distributeDWPercent: fg.distributeDWPercent ? this.toNumeral(fg.distributeDWPercent) : null,
      distributeDWValue: fg.distributeDWValue ? this.toNumeral(fg.distributeDWValue) : null,
      dw: fg.dw ? fg.dw : null,
      dwExpectTradingDate: fg.dwExpectTradingDate ? fg.dwExpectTradingDate : null,
      dwExpiredDate: fg.dwExpiredDate ? fg.dwExpiredDate : null,
      dwLastTradingDate: fg.dwLastTradingDate ? fg.dwLastTradingDate : null,
      dwRequestCode: fg.dwRequestCode,
      dwSeries: fg.dwSeries ? fg.dwSeries : null,
      dwType: fg.dwType ? fg.dwType : null,
      endSPPostedDate: fg.endSPPostedDate ? moment(fg.endSPPostedDate, 'YYYY-MM-DD').toDate() : null,
      exchangeRate: fg.exchangeRate ? fg.exchangeRate : null,
      exchangeRateAsOf: fg.exchangeRateAsOf ? moment(fg.exchangeRateAsOf, 'YYYY-MM-DD').toDate() : null,
      exercisePrice: fg.exercisePrice ? this.toNumeral(fg.exercisePrice) : null,
      exerciseType: fg.exerciseType ? fg.exerciseType : null,
      foreignStockExchangeKey: fg.foreignStockExchange,
      futuresContract: fg.futuresContract,
      issuePrice: fg.issuePrice ? this.toNumeral(fg.issuePrice) : null,
      minimumBidOfferTradingHour: !isNullOrUndefined(fg.minimumBidOfferTradingHour) ? this.toNumeral(fg.minimumBidOfferTradingHour) : null,
      minimumBidOfferUnit: !isNullOrUndefined(fg.minimumBidOfferUnit) ? this.toNumeralInt(fg.minimumBidOfferUnit) : null,
      minimumMarketMakerDWPrice: fg.minimumMarketMakerDWPrice ? this.toNumeral(fg.minimumMarketMakerDWPrice) : null,
      modifyDate: fg.modifyDate ? fg.modifyDate : null,
      nameEng: fg.nameEng ? fg.nameEng : null,
      nameThai: fg.nameThai ? fg.nameThai : null,
      offerDW: fg.offerDW ? this.toNumeralInt(fg.offerDW) : null,
      offerType: fg.offerType ? fg.offerType : null,
      par: fg.par ? fg.par : null,
      passFurtherDate: fg.passFurtherDate ? fg.passFurtherDate : null,
      requestDate: fg.requestDate ? fg.requestDate : null,
      requestedName: fg.requestedName,
      securitiesIndex: fg.securitiesIndex ? fg.securitiesIndex : null,
      seriesID: fg.seriesID,
      settlementPriceIndexType: fg.settlementPriceIndexType,
      settlementStyle: fg.settlementStyle ? fg.settlementStyle : null,
      specialFeature: fg.specialFeature ? fg.specialFeature : null,
      startSPPostedDate: fg.startSPPostedDate ? moment(fg.startSPPostedDate, 'YYYY-MM-DD').toDate() : null,
      subscriptionProfile: { id: fg.subscriptionProfile },
      valuePrice: fg.valuePrice ? this.toNumeral(fg.valuePrice) : null,
      valuePriceTotal: fg.valuePriceTotal ? fg.valuePriceTotal : null,
      underlying: fg.underlying ? fg.underlying : null,
      urlIndexConstituents: fg.urlIndexConstituents ? fg.urlIndexConstituents : null,
      urlIndexSeries: fg.urlIndexSeries ? fg.urlIndexSeries : null
    }
    const json = new FormData()
    json.set('allocateProfileDWParam', JSON.stringify(allocateProfileDW))
    if (!isNullOrUndefined(fg.termsAndConditions) && fg.termsAndConditions !== '' && fg.securitiesIndex == 'F') {
      json.set('termsAndConditionsFile', fg.termsAndConditions)
    }
    return this.apiService.post<any>(url, json)
  }

  public getUnderlyingList(underlyingType): Observable<any> {
    const url = API.GET_UNDERLYING_LIST
    return this.apiService.get<any>(url + `/${underlyingType}`)
  }

  public generateDWName(fg): Observable<any> {
    const url = API.GET_REQCODE_NAME
    const currentAllocateDWId = !isNullOrUndefined(fg.id) ? fg.id : null;
    const dwSeries = !isNullOrUndefined(fg.dwSeries) ? fg.dwSeries : ''
    const dwType = !isNullOrUndefined(fg.dwType) ? (fg.dwType === 'P' ? 'PUT' : 'CALL') : ''
    const dwExpiredDate = !isNullOrUndefined(fg.dwExpiredDate) ? moment(fg.dwExpiredDate).format('YYYY-MM-DD') : ''
    const securitiesSymbols = !isNullOrUndefined(fg.securitiesSymbols) ? fg.securitiesSymbols : ''
    let params = ``
    if (!isNullOrUndefined(currentAllocateDWId)) {
      params = `?currentAllocateDWId=${currentAllocateDWId}&dwSeries=${dwSeries}&dwType=${dwType}&expireDate=${dwExpiredDate}&underlyingId=${securitiesSymbols}`
    } else {
      params = `?dwSeries=${dwSeries}&dwType=${dwType}&expireDate=${dwExpiredDate}&underlyingId=${securitiesSymbols}`
    }

    return this.apiService.get<any>(url + params)
  }

  public nextBussinessDay(add: string, date: string): Observable<any> {
    const url = API.GET_NEXT_BUSINESSDAY
    return this.apiService.get<any>(url + '?add=' + add + '&date=' + date)
  }

  public isWorkingDate(date): Observable<any> {
    const url = API.IS_WORKINGDATE
    return this.apiService.get<any>(`${url}?date=${date}`)
  }

  public rollbackStep(id: number): Observable<any> {
    const url = API.ROLLBACK_STEP
    return this.apiService.get<any>(`${url}/${id}`)
  }

  public confirm(id: string, modifyDate): Observable<any> {
    const url = API.CONFIRM_ALLOCATE_DW
    return this.apiService.post<any>(`${url}?id=${id}&modifyDate=${modifyDate}`)
  }

  public getOwnerDwList(isProfileDW: boolean, currentAllocateDWId: number = null): Observable<OwnerDw[]> {
    const url = API.GET_OWNERDW_LIST
    const currentAllocateDWIdPathParam = currentAllocateDWId === null ? '' : `&currentAllocateDWId=${currentAllocateDWId}`;
    return this.apiService.get(`${url}?isProfileDW=${isProfileDW}${currentAllocateDWIdPathParam}`)
  }

  public getOwnerDw(allocateDWId: number, validateFurtherIssue: boolean, ignoreCheckActive: boolean): Observable<any> {
    const url = API.GET_OWNERDW
    return this.apiService.get(`${url}/${allocateDWId}?validateFurtherIssue=${validateFurtherIssue}&ignoreCheckActive=${ignoreCheckActive}`);
  }

  public cancel(id: string): Observable<any> {
    const url = API.CANCEL_ALLOCATE_DW
    return this.apiService.post<any>(`${url}/${id}`)
  }

  public checkRemainRoomUnderlying(underlyingId: string, requestAmount: string, allocateDWId: string | number, tradingDate: string) {
    const url = API.CHECK_REMAIN_ROOM;
    const allocateDWIdPathParam = allocateDWId === null ? '' : `&allocateDWId=${allocateDWId}`;
    return this.apiService.post<any>(`${url}/${underlyingId}?requestAmount=${requestAmount}${allocateDWIdPathParam}&tradingDate=${tradingDate}`);
  }

  public checkRemainRoomUnderlyingByDwUnderlyingType(dwUnderlyingType: string) {
    const url = API.CHECK_REMAIN_ROOM_BY_DW_UNDERLYING_TYPE;
    return this.apiService.get<any>(`${url}/${dwUnderlyingType}`).pipe(map((b) => b[0]));
  }

  public checkAllowance() {
    const url = API.CHECK_ALLOWANCE
    return this.apiService.get<any>(url)
  }

  public saveDigitalSignature(request: DigitalSignature): Observable<any> {
    const url = API.SAVE_DIGITAL_SIGNATURE
    return this.apiService.postWithNormalResponse<any>(url, request)
  }

  public saveShortDigitalSignature(request: DigitalSignature): Observable<any> {
    const url = API.SAVE_SHORT_DIGITAL_SIGNATURE
    return this.apiService.post<any>(url, request).pipe(map((b) => b[0]))
  }

  public getListForeignStockExchange(foreignExchangeKey: string): Observable<any>  {
    const url = API.GET_FOREIGN_STOCK;
    return this.apiService.get(`${url}/${foreignExchangeKey}`);
  }

  public calculateDistributeDWPercent(dwRequestCode: string, distributeDWValue: number): Observable<any> {
    const url = API.CALCULATE_DISTRIBUTE_DW_PERCENT;
    return this.apiService.get(`${url}?dwRequestCode=${dwRequestCode}&distributeDWValue=${distributeDWValue}`).pipe(map((b) => b[0]));
  }

  public getTradingDate() {
    return moment(moment().add(7, 'days').format('YYYY-MM-DD')).toDate()
  }

  public getSecurityTradingDate(id: number, type: string) {
    const url = API.GET_SECURITY_TRADING_DATE.replace(/{id}/gi, `${id}`).replace(/{type}/gi, `${type}`);
    return this.apiService.get(`${url}`).pipe(map((b) => b[0]))
  }

  public checkHV(appId: number) {
    const url = API.GET_CHECK_HV.replace(/{appId}/gi, `${appId}`);
    return this.apiService.get(`${url}`).pipe(map((b) => b[0]));
  }

  public unlockAttachFileStatus(id: string) {
    const url = API.UNLOCK_ATTACH_FILE_STATUS.replace(/{id}/gi, `${id}`);
    return this.apiService.get(`${url}`).pipe(map((b) => b[0]));
  }

  public lockAttachFileStatus(id: string) {
    const url = API.LOCK_ATTACH_FILE_STATUS.replace(/{id}/gi, `${id}`);
    return this.apiService.get(`${url}`).pipe(map((b) => b[0]));
  }

  public approveAttachFile(id: string) {
    const url = API.APPROVE_ATTACH_FILE.replace(/{id}/gi, `${id}`);
    return this.apiService.get(`${url}`).pipe(map((b) => b[0]));
  }

  public rejectAttachFile(id: string) {
    const url = API.REJECT_ATTACH_FILE.replace(/{id}/gi, `${id}`);
    return this.apiService.get(`${url}`).pipe(map((b) => b[0]));
  }

  public saveDigitalSignatureAttachFile(request: DigitalSignature) {
    const url = API.SAVE_DIGITAL_SIGNATURE_ATTACH_FILE
    return this.apiService.post<any>(url, request).pipe(map((b) => b[0]))
  }

  public uploadReserveQuota(request: QuotaExcel) {
    const url = API.UPLOAD_RESERVE_QUOTA;
    const json = new FormData()
    json.set('file', request.file);
    json.set('detail', JSON.stringify(request.detail));
    return this.apiService.post<any>(url, json).pipe(map((b) => b[0]));
  }

  public downloadReserveQuotaTemplate(type: string, fileType: string) {
    const url = API.DOWNLOAD_RESERVE_QUOTA_TEMPLATE.replace(/{type}/gi, `${type}`).replace(/{fileType}/gi, `${fileType}`);
    return this.apiService.getBlob(url);
  }

  public getMaxTradingDate(underlyingId: number, add: string, date: string, allocateDWId: number = null) {
    const url = API.GET_MAX_TRADING_DATE.replace(/{underlyingId}/gi, `${underlyingId}`);
    const allocateDWIdPathParam = allocateDWId === null ? '' : `&allocateDWId=${allocateDWId}`;
    return this.apiService.get(`${url}?add=${add}&date=${date}${allocateDWIdPathParam}`).pipe(map((b) => b[0]))
  }

  public checkRemainRoomTotalUnderlying(id: number) {
    const url = API.GET_REMAIN_ROOM_TOTAL.replace(/{underlyingId}/gi, `${id}`);
    return this.apiService.get(`${url}`).pipe(map((b) => b[0]));
  }

  public resendDW(id) {
    const url = API.RESEND_DW.replace(/{allocateDWId}/gi, `${id}`);
    return this.apiService.getWithNormalResponse(`${url}`);
  }

  public sendMailDS(request): Observable<any> {
    const url = API.SEND_MAIL_DS;
    return this.apiService.post<any>(url,request)
  }

  public sendMailDS35dw1(request): Observable<any> {
    const url = API.SEND_MAIL_DS_35dw1;
    return this.apiService.post<any>(url,request)
  }

  public checkExercisePrice(id) {
    const url = API.DW_CLOSE_PRICE.concat(`?id=${id}`)
    return this.apiService.get(`${url}`).pipe(map((b) => b[0]));
  }

  toNumeral(value) {
    return Math.abs(numeral(value).format('0,0.00[000]').split(',').join(''))
  }

  toNumeralInt(value) {
    return numeral(value).format('0,0').split(',').join('')
  }

}
