import {
	HttpClient,
	HttpParams,
	HttpHeaders,
	HttpErrorResponse,
	HttpResponse
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Data } from '../../shared/models/data'
import { Observable, throwError } from 'rxjs'
import { environment } from '../../../environments/environment'
import { timeout, map, catchError, count } from 'rxjs/operators'
import { Logger } from '../logger/logger'
import { isNullOrUndefined } from '@swimlane/ngx-datatable/release/utils';

const responseCode = {
	success: 200,
	failure: 400,
	unAuthorize: 401,
	error: 500
}

export interface IResponseBlob<T> extends HttpResponse<T> {
	filename?: string;
}

@Injectable({
	providedIn: 'root'
})
export class ApiClientService {
	static logger
	constructor(
		private http: HttpClient,
		public logger: Logger
	) {
		ApiClientService.logger = this.logger
	}

	public createRequestOptions() {
		const httpOptions = {
			headers: this.createHeaders()
		}
		return httpOptions
	}
	public createHeaders(): HttpHeaders {
		let headers = new HttpHeaders()
		return headers
	}

	private get requestBlobHeader() {
		const options: any = this.createRequestOptions()
		options.responseType = 'blob'
		return options
	}

	public getBlobWithPath(path: string): Observable<any> {
		return this.http.get<any>(`${path}`, this.requestBlobHeader).pipe(
			map(this.checkResponseBlob),
		)
	}

	public get<T>(url: string, header?: HttpHeaders, options?: { includeCount: boolean }): Observable<T | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: GET', url)

		return this.http.get<any>(url, { headers: header }).pipe(
			timeout(environment.requestTimeout),
			map((res) => this.extractData(res, options ? options.includeCount : false)),
			catchError(this.handleError)
		)
	}

	public put<T>(url: string, parameter: any, header?: HttpHeaders): Observable<T | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: PUT', url, parameter, JSON.stringify(parameter));

		return this.http.put(url, parameter, { headers: header }).pipe(
			timeout(environment.requestTimeout),
			map((res) => this.extractData(res)),
			catchError(this.handleError)
		)
	}

	public patch<T>(url: string, parameter: any, header?: HttpHeaders): Observable<T | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: PATCH', url, parameter, JSON.stringify(parameter));

		return this.http.patch(url, parameter, { headers: header }).pipe(
			timeout(environment.requestTimeout),
			map((res) => this.extractData(res)),
			catchError(this.handleError)
		)
	}

	public post<T>(url: string, parameter?: any, header?: HttpHeaders, options?: { includeCount: boolean }, responseType?:string): Observable<Data<T> | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: POST', url, parameter, header, JSON.stringify(parameter));

		return this.http.post(url, parameter, { headers: header }).pipe(
			timeout(environment.requestTimeout),
			map((res) => this.extractData(res, options ? options.includeCount : false)),
			catchError(this.handleError)
		)
	}

	public postSec<T>(url: string, parameter?: any, header?: HttpHeaders): Observable<Data<T> | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: POST', url, parameter, header, JSON.stringify(parameter));

		return this.http.post(url, parameter, { headers: header }).pipe(
			timeout(environment.requestTimeout),
			map(this.extractDataSec),
			catchError(this.handleErrorSec)
		)
	}

	public postSecWithErrorCode<T>(url: string, parameter?: any, header?: HttpHeaders): Observable<Data<T> | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: POST', url, parameter, header, JSON.stringify(parameter));

		return this.http.post(url, parameter, { headers: header }).pipe(
			timeout(environment.requestTimeout),
			map(this.extractDataSec),
			catchError(this.handleErrorSecWithErroCode)
		)
	}

	public getSec<T>(url: string, header?: HttpHeaders): Observable<T | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: GET', url)
		header = this.getHeader(header)

		return this.http.get<any>(url, { headers: header }).pipe(
			timeout(environment.requestTimeout),
			map(this.extractDataSec),
			catchError(this.handleErrorSec)
		)
	}

	public getBlob<T>(url: string, header?: HttpHeaders): Observable<T | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: GET BLOB', url)
		return this.http.get(url, { headers: header, observe: 'response', responseType: 'blob' }).pipe(
			timeout(environment.requestTimeout),
			map(this.checkResponseBlob),
			map(this.extractBlob)
		);
	}

	public postAuthen<T>(path: string, body?): Observable<Data<T>> {
		return this.http.post<Data<T>>(`${path}`, body || {})
	}

	private extractData(res, includeCount = false) {
		ApiClientService.logger.info('API Service', '=> Response', res.data, res.messageResponse, { 'size': res.count }, JSON.stringify(res.data));
		const body = res.data
		const responseStatus = res.messageResponse
		if (responseStatus.code != responseCode.success) {
			throw responseStatus
		}
		if (includeCount) {
			return {
				body: body ? body : null,
				count: res.count,
			}
		}
		return body ? body : null
	}

	private extractDataSec(res) {
		ApiClientService.logger.info('API Service', '=> Response', res.data, res.messageResponse, { 'size': res.count }, JSON.stringify(res.data));
		const body = res
		if (isNullOrUndefined(body)) {
			throw null
		}
		return body ? body : null
	}

	private checkResponseBlob<T extends Blob = Blob>(res: HttpResponse<T>): HttpResponse<T> {
		if (res.status === 204) {
			throw new Error('File not found');
		}
		return res;
	}

	private extractBlob<T>(res: HttpResponse<T>): IResponseBlob<T> {
		const response: IResponseBlob<T> = res;
		const filename = (res.headers.get('content-disposition') || '').replace('attachment; filename=', '');
		if (filename) {
			response.filename = filename;
		}
		return response;
	}

	private handleErrorSec(error: HttpErrorResponse) {
		let err;
		if (isNullOrUndefined(error)) {
			return throwError('Something went wrong.');
		}

		if (error.status === responseCode.unAuthorize) {
			location.href = environment.LoginUrl;
		}

		ApiClientService.logger.error('API Service', '=> Response Error', error ? error : err);

		if (!isNullOrUndefined(error.error)) {
			if (!isNullOrUndefined(error.error[''])) {
				err = error.error[''][0]
			}
		}

		if (!isNullOrUndefined(error.statusText)) {
			err = error.statusText
		}

		return throwError(Object.keys(err).length > 0 ? err : error);
	}

	private handleErrorSecWithErroCode(error: HttpErrorResponse) {
		let err;
		if (isNullOrUndefined(error)) {
			return throwError('Something went wrong.');
		}

		if (error.status === responseCode.unAuthorize) {
			location.href = environment.LoginUrl;
		}

		ApiClientService.logger.error('API Service', '=> Response Error', error ? error : err);

		if (!isNullOrUndefined(error.error)) {
			if (!isNullOrUndefined(error.error[''])) {
				err = error.error[''][0]
			}
		}

		if (!isNullOrUndefined(error.statusText)) {
			err = error
		}

		return throwError(Object.keys(err).length > 0 ? err : error);
	}

	private handleError(error: HttpErrorResponse) {
		let err;
		if (isNullOrUndefined(error)) {
			return throwError('Something went wrong.');
		}

		if (error.status === responseCode.unAuthorize) {
			location.href = environment.LoginUrl;
		}

		ApiClientService.logger.error('API Service', '=> Response Error', error ? error : err);

		// if (!isNullOrUndefined(error.statusText) && !isNullOrUndefined(error.error)) {
		// 	err = {
		// 		responseStatus: {
		// 			responseCode: !isNullOrUndefined(error.error.status) ? error.error.status : error.status,
		// 			responseMessage: !isNullOrUndefined(error.error.message) ? error.error.message : error.statusText
		// 		}
		// 	}
		// }

		if (!isNullOrUndefined(error.statusText) && !isNullOrUndefined(error.error)) {
			err = {
				responseStatus: {
					responseMessage: !isNullOrUndefined(error.error) && !isNullOrUndefined(error.error.messageResponse) ? error.error.messageResponse.resMessage : error.statusText
				}
			}
		} else if (!isNullOrUndefined(error['code']) && !isNullOrUndefined(error['message'])) {
			err = {
				responseStatus: {
					responseMessage: error['message'],
					responseCode: error['code']
				}
			}
		} else if (!error.hasOwnProperty('responseStatus')) {
			err = {
				responseStatus: {
					responseMessage: 'Cannot connect to server'
				}
			}
		}

		return throwError(Object.keys(err).length > 0 ? err : error);
	}

	public getHeader(header?: HttpHeaders): HttpHeaders {
		let h: HttpHeaders = header || new HttpHeaders();
		return h;
	}

	public getWithNormalResponse<T>(url: string, header?: HttpHeaders, options?: { includeCount: boolean }): Observable<T | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: GET', url)

		return this.http.get<any>(url, { headers: header }).pipe(
			timeout(environment.requestTimeout),
			map((res) => res),
			catchError(this.handleError)
		)
	}

	public postWithNormalResponse<T>(url: string, parameter?: any, header?: HttpHeaders, options?: { includeCount: boolean }): Observable<Data<T> | any> {
		ApiClientService.logger.debug('API Service', '=> Requesting', 'Method :: POST', url, parameter, header, JSON.stringify(parameter));

		return this.http.post(url, parameter, { headers: header }).pipe(
			timeout(environment.requestTimeout),
			map((res) => res),
			catchError(this.handleError)
		)
	}
}
