import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DOMAIN } from '@app/shared/app.constant';
import { AuthService } from '@app/core/services/auth.service';
import { FormGroup } from '@angular/forms';
import { UserProfileService } from '@app/core/services/user-profile.service';

@Component({
  selector: 'kt-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  fg: FormGroup
  isChecked = false
  disabled: boolean
  isOperation: boolean
  constructor(
    private router: Router,
    private authService: AuthService,
    private userProfileService: UserProfileService
  ) { }

  ngOnInit() {
    this.isOperation = this.userProfileService.getUserProfile().isOperation;
   }

  onCheck() {
    this.disabled = this.isChecked == true ? false : true
  }

  onAccept() {
    this.authService.setTerm(true)
    setTimeout(() => {
      this._redirect()
    }, 500);
  }

  _redirect() {
    if(this.isOperation ){
      this.router.navigate([DOMAIN.URL + '/sec-admin/shelf-issuer/list'])
    } else {
       this.router.navigate([DOMAIN.URL + '/quota'])
    }
  }

  onCancel() {
    this.authService.setTerm(false)
  }

}
