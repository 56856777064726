import { Injectable } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { UserProfileService } from '../user-profile.service';
import { API } from '@app/shared/app.constant';
import { map } from 'rxjs/operators';
import { ShelfIssuer } from '@app/shared/models/corebusiness/shelf-issuer';
import { Observable, forkJoin } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AccessDocument } from '@app/shared/models/corebusiness/access-document';

export interface FilingThirtyFiveDwOneRequest {
  id?: number;
  authorizedPersons?: string;
}

export interface FilingThirtyFiveDwOneAccessDoc {
  refCode: string;
  password: string;
}

export interface FilingThirtyFiveDwOneQueryParam {
  page: number;
  size: number;
  createDateFrom?: string;
  createDateTo?: string;
  status?: string;
  symbol?: string;
}

export interface FilingThirtyFiveDwOneQueryConsidering {
  considerationResult: string;
  effectiveDate: string;
  expireDate: string;
  remark: string;
}

@Injectable()
export class FilingThirtyFiveDwOneService {
  constructor(
    private apiService: ApiClientService,
    public userProfileService: UserProfileService
  ) {

  }

  getOne(id): Observable<any> {
    let url = API.GET_SHELF
    return this.apiService.get<ShelfIssuer>(url + `/${id}`)
  }

  getList(data: FilingThirtyFiveDwOneQueryParam = { page: 0, size: 10 }): Observable<{ body: ShelfIssuer[], count: number }> {
    const queries = [];
    Object.keys(data).forEach((key) => {
      if (data[key] !== null) {
        queries.push(`${key}=${data[key]}`);
      }
    });
    const url = [API.GET_SHELF_LIST, queries.join('&')].join('?');
    return this.apiService.get<ShelfIssuer>(url, null, { includeCount: true })
      .pipe(map(({ body, count }) => ({ body: Array.isArray(body) ? body : [], count })));
  }

  public getById(id: any): Observable<ShelfIssuer> {
    const url = API.GET_SHELF + `/${id}`;
    return this.apiService.get<ShelfIssuer>(url).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public cancel(id: any): Observable<ShelfIssuer> {
    let url = API.GET_SHELF
    return this.apiService.get<ShelfIssuer>(url + `/${id}/cancel`).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public uploadFile(id: any, uploadedFile: FormData, otherDescription: string, listingDocRepoCode: string) {
    const url = `${API.POST_UPLOAD_FILE}/${id}?otherDescription=${otherDescription}&listingDocRepoCode=${listingDocRepoCode}`
    return this.apiService.post(url, uploadedFile).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public deleteAdditionalFile(id: any, attachListingDocId: string | number) {
    const url = `${API.POST_DELETE_FILE}/${id}?attachListingDocId=${attachListingDocId}`;
    return this.apiService.post(url, null);
  }

  public confirm(id: string | number): Observable<any> {
    let url = API.GET_SHELF
    return this.apiService.get<ShelfIssuer>(url + `/${id}/confirm`).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public send(id: string | number, digitalSign, file): Observable<any> {
    let url = API.SEND_SHELF
    const body = new FormData()
    body.append('certification_35dw1', file)
    body.append('digitalSignatureRequest', digitalSign);
    return this.apiService.post<any>(url + `/${id}/send`, body).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public save(id: any): Observable<ShelfIssuer> {
    let url = API.POST_SHELF_SAVE;
    return this.apiService.post<any>(`${url}/${id}`).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public create(body: FilingThirtyFiveDwOneRequest): Observable<ShelfIssuer> {
    const url = API.POST_SHELF_CREATE;
    return this.apiService.post<any>(url, body).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public downloadDocument(fileSystemName: string, companyId: string | number): Observable<any> {
    const url = API.SHELF_DOWNLOAD_DOCUMENT
    return this.apiService.getBlob<any>(`${url}/${companyId}/${fileSystemName}`);
  }

  public genPassword(ticket: string): Observable<string[]> {
    const url = `${API.SHELF_ISSUER_GEN_PASSWORD}?ticket=${ticket}`;
    return this.apiService.get(url);
  }

  public accessDocument(ticker: string, doc: FilingThirtyFiveDwOneAccessDoc): Observable<AccessDocument> {
    const url = `${API.SHELF_ISSUER_ACCESS_DOC}?ticket=${ticker}`;
    return this.apiService.post(url, doc).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public validateCompany(ignore35: boolean = false): Observable<{ message: string, validateValue: boolean }> {
    let url = `${API.GET_VALIDATE_COMPANY}?ignore35=${ignore35}`
    return this.apiService.get<any>(url).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public validateShelf(): Observable<boolean> {
    let url = API.GET_VALIDATE_SHELF;
    return this.apiService.get<any>(url).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public validateCreate(ignore35: boolean = false): Observable<{ shelf: boolean, company: { message: string, validateValue: boolean } }> {
    return forkJoin(this.validateShelf(), this.validateCompany(ignore35))
      .pipe(
        map(([shelf, company]) => {
          return {
            shelf,
            company
          }
        })
      )
  }

  /**
   *
   * @param id string | number
   * @param effectiveDate string (YYYY/MM/DD)
   */
  public getLicenseExpireDate(id: string | number, effectiveDate: string): Observable<{ expireDate: string }> {
    const url = API.GET_LICENSE_EXPIRE_DATE + `?effectiveDate=${effectiveDate}&shelfIssuerId=${id}`;
    return this.apiService.get<any>(url).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }

  public considering(id: number | string, request: FilingThirtyFiveDwOneQueryConsidering): Observable<ShelfIssuer> {
    const url = API.PATCH_CONSIDER_SHELF_ISSUER.replace(/{shelfId}/gi, `${id}`);
    return this.apiService.patch<any>(url, request).pipe(map(d => Array.isArray(d) ? d[0] : null));
  }
}
