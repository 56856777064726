// Angular
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material'
import { OverlayModule } from '@angular/cdk/overlay'
// Angular in memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api'
// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
// Perfect Scroll bar
import {
	PERFECT_SCROLLBAR_CONFIG,
	PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar'
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg'
// Env
import { environment } from '../environments/environment'
// Hammer JS
import 'hammerjs'
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions'
// NGRX
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
// State
import { metaReducers, reducers } from './core/reducers'
// Copmponents
import { AppComponent } from './app.component'
// Modules
import { AppRoutingModule } from './app-routing.module'
import { CoreModule } from './core/core.module'
// Partials
import { PartialsModule } from './views/partials/partials.module'
// Layout Services
import {
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService,
	PageConfigService,
	SplashScreenService,
	SubheaderService,
	KtDialogService
} from './core/_base/layout'
// Auth
import { JwtService } from './core/jwt.service'

// Config
import { LayoutConfig } from './core/_config/default/layout.config'
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from 'ngx-highlightjs'
import * as typescript from 'highlight.js/lib/languages/typescript'
import * as scss from 'highlight.js/lib/languages/scss'
import * as xml from 'highlight.js/lib/languages/xml'
import * as json from 'highlight.js/lib/languages/json'
import { NgxLoadingModule } from 'ngx-loading';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { TreeviewModule } from 'ngx-treeview';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300
}

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs)
		}
	}
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{ name: 'typescript', func: typescript },
		{ name: 'scss', func: scss },
		{ name: 'xml', func: xml },
		{ name: 'json', func: json }
	]
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		OverlayModule,
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
		StoreDevtoolsModule.instrument(),
		NgbModule,
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		NgxLoadingModule.forRoot({}),
		ScrollToModule.forRoot(),
		TreeviewModule.forRoot()
	],
	exports: [],
	providers: [
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService],
			multi: true
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: { languages: hljsLanguages }
		},
		{
			provide: 'API_HOST',
			useValue: environment.dwsApiBaseUrl
		},
		{
			provide: 'API_LOGIN',
			useValue: environment.LoginUrl
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtService,
			multi: true
		},
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
