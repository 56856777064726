import { Company } from './company'
import { Role } from './role'
import { UserRole } from '../user_role';

export class UserProfile {
	id: number
	username: string
	name: string
	surname: string
	email: string
	fullName: string
	company: Company
	roles: Role[];
	rolesName: string[];
	isNonListed: boolean;
	isListed: boolean;

	get isDwSEC(): boolean {
		return (this.rolesName || []).includes(UserRole.DW_SEC);
	}

	get isDwSET(): boolean {
		return (this.rolesName || []).includes(UserRole.DW_SET);
	}

	get isRegulator(): boolean {
		return this.isDwSEC || this.isDwSET || this.isOperation;
	}

	get isIssuer(): boolean {
		return (this.rolesName || []).includes(UserRole.DW_ISSUER) || (this.rolesName || []).includes(UserRole.DW_SENDER);
	}

	get isSender(): boolean {
		return this.rolesName.includes(UserRole.DW_SENDER)
	}

	get isMonitor(): boolean {
		return this.rolesName.includes(UserRole.DW_MONITOR)
	}

	get isOperation(): boolean {
		return this.rolesName.includes(UserRole.DW_OPERATION)
	}

}
