import { Injectable } from '@angular/core'
import { environment } from '@env/environment';

@Injectable()
export class AppConstant { }
@Injectable()
export class DOMAIN {
	public static readonly URL = '/dws/'
}
@Injectable()
export class API {
	public static readonly refreshToken = API.getMethod('refreshToken')
	public static readonly auth = API.getMethod('auth')
	public static readonly SUBSCRIPTION_PROFILE_LIST = API.getMethod('subscription-profile/list')
	public static readonly SUBSCRIPTION_PROFILE_SEARCH = API.getMethod('subscription-profile/search')
	public static readonly SUBSCRIPTION_PROFILE_CREATE = API.getMethod('subscription-profile/create')
	public static readonly SUBSCRIPTION_PROFILE_UPDATE = API.getMethod('subscription-profile/update')
	public static readonly NATION_LIST = API.getMethod('getNationlityLists')

	public static readonly LIST_SHAREHOLDER = API.getMethod('authorized-profile/shareholder/list')
	public static readonly SAVE_SHAREHOLDER = API.getMethod('authorized-profile/shareholder/create')
	public static readonly UPDATE_SHAREHOLDER = API.getMethod('authorized-profile/shareholder/update')
	public static readonly REMOVE_SHAREHOLDER_BY_ID = API.getMethod('authorized-profile/shareholder/delete')

	public static readonly LIST_PRESIDENT = API.getMethod('authorized-profile/chief/list')
	public static readonly SAVE_PRESIDENT = API.getMethod('authorized-profile/chief/create')
	public static readonly UPDATE_PRESIDENT = API.getMethod('authorized-profile/chief/update')
	public static readonly REMOVE_PRESIDENT = API.getMethod('authorized-profile/chief/delete')

	public static readonly LIST_DIRECTOR = API.getMethod('authorized-profile/board/list')
	public static readonly SAVE_DIRECTOR = API.getMethod('authorized-profile/board/create')
	public static readonly UPDATE_DIRECTOR = API.getMethod('authorized-profile/board/update')
	public static readonly REMOVE_DIRECTOR = API.getMethod('authorized-profile/board/delete')
	public static readonly DOWNLOAD_DIRECTOR = API.getMethod('authorized-profile/download')

	public static readonly GET_COMPANY_PROFILE = API.getMethod('company/get')
	public static readonly GET_RENEWINFO = API.getMethod('company/renewInfo/get')
	public static readonly GET_FISCALEND = API.getMethod('company/get/fiscalEnd')
	public static readonly UPDATE_FISCALEND = API.getMethod('company/renewInfo/update')
	public static readonly GET_REPOSITORY_FILING = API.getMethod('companyDocument/repository/filing')
	public static readonly COMPANY_PROFILE_PUT = API.getMethod('company/update')
	public static readonly UPDATE_MARKETMAKER = API.getMethod('company/updateMarketMaker')
	public static readonly CHECK_ALLOWANCE = API.getMethod('company/allow')

	public static readonly UPLOAD_FILING = API.getMethod('companyDocument/filing/upload')
	public static readonly DOWNLOAD_FILING = API.getMethod('companyDocument/filing/download')
	public static readonly DELETE_FILING = API.getMethod('companyDocument/filing/delete')
	public static readonly UPDATE_FILING = API.getMethod('companyDocument/filing/update')
	public static readonly GET_QUALIFICATION = API.getMethod('qualification/risk/get')
	public static readonly UPDATE_QUALIFICATION = API.getMethod('qualification/risk/update')
	public static readonly DOWNLOAD_FILE_QUALIFICATION = API.getMethod('qualification/download')
	public static readonly GET_FINANCIAL = API.getMethod('qualification/financial/get')
	public static readonly UPDATE_FINANCIAL = API.getMethod('qualification/financial/update')

	public static readonly SEARCH_ALLOCATEDW = API.getMethod('allocateDW/search')
	public static readonly SEARCH_SUBMIT_APPLICATION = API.getMethod('submitApp/search')
	public static readonly SEARCH_SUBMIT_APPLICATION_ALL = API.getMethod('submitApp/search-all')

	public static readonly GET_QUALIFIED = API.getMethod('submitApp/getQualified')
	public static readonly GET_SUBMIT_APPLICATION = API.getMethod('submitApp/get')
	public static readonly CREATE_SUBMIT_APPLICATION = API.getMethod('submitApp/create')
	public static readonly UPDATE_SUBMIT_APPLICATION = API.getMethod('submitApp/update')
	public static readonly DOWNLOAD_SUBMIT_APPLICATION = API.getMethod('submitApp/download')
	public static readonly SEND_SUBMIT_APPLICATION = API.getMethod('submitApp/send')
	public static readonly SEND_SUBMIT_APPLICATION_SECOND_ROUND = API.getMethod('submitApp/sendWithCondition')
	public static readonly SUBMIT_APP_VALIDATEUNDERLYING = API.getMethod('submitApp/validateUnderlying')

	public static readonly GET_BATCH_LIST = API.getMethod('allocateDW/list/batch')
	public static readonly GET_ALLOCATEDW = API.getMethod('allocateDW/get')
	public static readonly UPDATE_ALLOCATEDW = API.getMethod('allocateDW/app/update')
	public static readonly UPLOAD_ALLOCATEDW_FILING = API.getMethod('allocateDW/app/document/update')
	public static readonly DELETE_ALLOCATEDW_FILE = API.getMethod('allocateDW/app/document/delete')

	public static readonly SAVE_ALLOCATE_LISTED = API.getMethod('allocateDW/saveAllocateListedDW')
	public static readonly SAVE_ALLOCATE_PROFILE = API.getMethod('allocateDW/saveAllocateProfileDW')

	public static readonly UPDATE_ALLOCATE_LISTED = API.getMethod('allocateDW/saveAllocateListedDW')
	public static readonly UPDATE_ALLOCATE_PROFILE = API.getMethod('allocateDW/saveAllocateProfileDW')
	public static readonly GET_UNDERLYING_LIST = API.getMethod('allocateDW/listUnderlying')
	public static readonly GET_REQCODE_NAME = API.getMethod('allocateDW/generateDWName')
	public static readonly CONFIRM_ALLOCATE_DW = API.getMethod('allocateDW/confirm')
	public static readonly CANCEL_ALLOCATE_DW = API.getMethod('allocateDW/cancel')
	public static readonly CHECK_REMAIN_ROOM = API.getMethod('allocateDW/checkRemainRoomUnderlying')
	public static readonly CHECK_REMAIN_ROOM_BY_DW_UNDERLYING_TYPE = API.getMethod('allocateDW/checkRemainRoomUnderlyingByDwUnderlyingType')
	public static readonly GET_REMAIN_ROOM_TOTAL = API.getMethod('allocateDW/checkRemainRoom/underlying/{underlyingId}');
	public static readonly GET_OWNERDW_LIST = API.getMethod('allocateDW/ownerDW/list')
	public static readonly GET_OWNERDW = API.getMethod('allocateDW/ownerDW/get')
	public static readonly GET_FOREIGN_STOCK = API.getMethod('allocateDW/list/foreign-stock-exchange');
	public static readonly CALCULATE_DISTRIBUTE_DW_PERCENT = API.getMethod('allocateDW/calculateDistributeDWPercent');
	public static readonly GET_CHECK_HV = API.getMethod('allocateDW/checkHV/{appId}');
	public static readonly GET_MAX_TRADING_DATE = API.getMethod('allocateDW/trading-date/max/{underlyingId}')
	public static readonly GET_DISCLAIMER_LIST = API.getMethod('allocateDW/disclaimer/get')
	public static readonly GET_DISCLAIMER_FOREIGN_INDEX = API.getMethod('allocateDW/disclaimer/foreignIndex')
	public static readonly GET_UNDERLYING_FOREIGN_INDEX_LIST = API.getMethod('allocateDW/disclaimer/underlying/foreignIndex')
	public static readonly RESEND_DW = API.getMethod('/allocateDW/resendDW/{allocateDWId}')
	public static readonly SEND_MAIL_DS = API.getMethod('allocateDW/send-mail-ds')
	public static readonly SEND_MAIL_DS_35dw1 = API.getMethod('allocateDW/send-mail-ds/35dw1')
	public static readonly DW_CLOSE_PRICE = API.getMethod('allocateDW/dw-alert/check-exercise-price')

	public static readonly UNLOCK_ATTACH_FILE_STATUS = API.getMethod('allocateDW/unlock/{id}');
	public static readonly LOCK_ATTACH_FILE_STATUS = API.getMethod('allocateDW/lock/{id}');
	public static readonly APPROVE_ATTACH_FILE = API.getMethod('allocateDW/{id}/unlock-attach-file/approve');
	public static readonly REJECT_ATTACH_FILE = API.getMethod('allocateDW/{id}/unlock-attach-file/reject');
	public static readonly SAVE_DIGITAL_SIGNATURE_ATTACH_FILE = API.getMethod('allocateDW/app/unlock-attach-file/save-digital-signature');
	public static readonly UPLOAD_RESERVE_QUOTA = API.getMethod('allocateDW/upload/reserve-quota');
	public static readonly DOWNLOAD_RESERVE_QUOTA_TEMPLATE = API.getMethod('allocateDW/upload/reserve-quota/template?type={type}&fileType={fileType}');
	public static readonly DELETE_DISCLAIMER = API.getMethod('allocateDW/disclaimer/delete');
	public static readonly UPLOAD_DISCLAIMER = API.getMethod('allocateDW/disclaimer/upload');
	public static readonly UPDATE_DISCLAIMER = API.getMethod('allocateDW/disclaimer/upload');
	public static readonly DOWNLOAD_DISCLAIMER = API.getMethod('allocateDW/disclaimer/download')

	public static readonly GET_SECURITY_TRADING_DATE = API.getMethod('security/countTradingDay/{id}/{type}');

	public static readonly GET_NEXT_BUSINESSDAY = API.getMethod('general/nextBusinessDay/get')
	public static readonly IS_WORKINGDATE = API.getMethod('general/isWorkingDate')

	public static readonly ROLLBACK_STEP = API.getMethod('submitApp/revertSubmitApp')

	// Qualification
	public static readonly GET_FINANCIAL_RATING_AGENCY = API.getMethod('qualification/financial/rating/agency')
	public static readonly GET_FINANCIAL_RATING_PREFERENCE = API.getMethod('qualification/financial/rating/preference-type')
	public static readonly GET_FINANCIAL_COMPANY_LIST = API.getMethod('qualification/financial/company/list')
	public static readonly GET_FINANCIAL_COMPANY_RATING = API.getMethod('qualification/financial/company/rating')
	public static readonly GET_FINANCIAL_COMPANY_PROFILE = API.getMethod('qualification/financial/company/profile')

	// Digital Signature
	public static readonly DIGITAL_SIGNATURE_INIT_SIGN = `${environment.digitalSignatureBaseUrl}/initsign/request`
	public static readonly DIGITAL_SIGNATURE_VERIFY = `${environment.digitalSignatureBaseUrl}/verify/onestop`
	public static readonly DOWNLOAD_PUBLIC_DOC = API.getMethod('publicListingDoc/download')
	public static readonly DOWNLOAD_PUBLIC_DOC_TEMP = API.getMethod('publicListingDoc/download-second-sign')
	public static readonly SAVE_DIGITAL_SIGNATURE = API.getMethod('allocateDW/app/saveDigitalSignature') // this is allocateDW
	public static readonly SAVE_SHORT_DIGITAL_SIGNATURE = API.getMethod('submitApp/saveDigitalSignature') // this is submitApplicationDW

	public static readonly GET_IM = API.getMethod('im/get')
	public static readonly REQUEST_IM = API.getMethod('im/request')
	public static readonly UPDATE_IM = API.getMethod('im/update')

	public static readonly GET_DW_ISSUER = API.getMethod('company/dwIssuer/list')
	public static readonly SEND_APPROVE_BATCH = API.getMethod('submitApp/approve')
	public static readonly SEND_RECEIVE_BATCH = API.getMethod('submitApp/receive')
	public static readonly TRADE_ON_NEXT_BUSINESSDAY = API.getMethod('submitApp/tradeOnNextBusinessDay')
	public static readonly CHECK_ROOM_BATCH = API.getMethod('submitApp/checkRemainRoomAndReserve/{submitApplicationDWId}')
	public static readonly CHECK_ROOM_FULL = API.getMethod('69-dw-full/checkRemainRoomAndReserve/{submitApplicationDWId}')
	public static readonly CANCEL_RESERVE_ROOM = API.getMethod('submitApp/cancelReserveRoom')
	public static readonly CANCEL_BATCH = API.getMethod('submitApp/cancel/{submitApplicationDWId}')

	public static readonly GET_SHELF_LIST = API.getMethod('35-dw-1/list')
	public static readonly GET_SHELF = API.getMethod('35-dw-1');
	public static readonly GET_AUTHORIZED_PROFILE = API.getMethod('authorizedProfile/35-dw-1');
	public static readonly POST_UPLOAD_FILE = API.getMethod('35-dw-1/upload-file');
	public static readonly POST_DELETE_FILE = API.getMethod('35-dw-1/upload-file/delete');
	public static readonly SHELF_DOWNLOAD_DOCUMENT = API.getMethod('35-dw-1/download');
	public static readonly SHELF_ISSUER_GEN_PASSWORD = API.getMethod('35-dw-1/document');
	public static readonly SHELF_ISSUER_ACCESS_DOC = API.getMethod('35-dw-1/access-doc');
	public static readonly SEND_SHELF = API.getMethod('35-dw-1')
	public static readonly POST_SHELF_CREATE = API.getMethod('35-dw-1')
	public static readonly POST_SHELF_SAVE = API.getMethod('35-dw-1')
	public static readonly GET_VALIDATE_SHELF = API.getMethod('35-dw-1/validate/shelf')
	public static readonly GET_VALIDATE_COMPANY = API.getMethod('35-dw-1/validate/companyProfile')
	public static readonly GET_LICENSE_EXPIRE_DATE = API.getMethod('35-dw-1/calculateLicenseExpireDate');
	public static readonly PATCH_CONSIDER_SHELF_ISSUER = API.getMethod('35-dw-1/{shelfId}/consider')

	// public download
	public static readonly DOWNLOAD_ATTACH_LISTING_DOC = API.getMethod('publicListingDoc/download-file');

	public static readonly GET_SUBMIT_APPLICATION_LIST = API.getMethod('69-dw-full');
	public static readonly GET_SUBMIT_APPLICATION_FULL = API.getMethod('69-dw-full');
	public static readonly UPDATE_SUBMIT_APPLICATION_FULL = API.getMethod('69-dw-full');
	public static readonly CREATE_SUBMIT_APPLICATION_FULL = API.getMethod('69-dw-full');
	public static readonly CANCEL_SUBMIT_APPLICATION_FULL = API.getMethod('69-dw-full')
	public static readonly CONFIRM_SUBMIT_APPLICATION_FULL = API.getMethod('69-dw-full')
	public static readonly VALIDATE_SUBMIT_APPLICATION_FULL = API.getMethod('69-dw-full/validate-create');
	public static readonly SUBMIT_APPLICATION_DOWNLOAD_DOC = API.getMethod('69-dw-full/download');
	public static readonly SUBMIT_APPLICATION_UPLOAD_ADDITIONAL_FILE = API.getMethod('69-dw-full/upload-file');
	public static readonly SUBMIT_APPLICATION_DELETE_ADDITIONAL_FILE = API.getMethod('69-dw-full/upload-file/delete');
	public static readonly GET_SUBMIT_APPLICATION_FULL_AUTHORIZED_PROFILE = API.getMethod('authorizedProfile/69-dw-full');
	public static readonly SEND_SUBMIT_APPLICATION_FULL = API.getMethod('69-dw-full');
	public static readonly GET_EFFECTIVE_FILING_DATE = API.getMethod('69-dw-full/{submitApplicationDWId}/calculateEffectiveFilingDate');
	public static readonly PATCH_CONSIDER_SUBMIT_APPLICATION = API.getMethod('69-dw-full/{submitApplicationDWId}/consider')
	public static readonly VALIDATE_DOCUMENT = API.getMethod('69-dw-full/validate69full')
	public static readonly SAVE_DIGITAL_SIGNATURE_UNLOCK = API.getMethod('69-dw-full/unlock-attach-file/save-digital-signature');

	// monitoring
	public static readonly GET_MONITOR = API.getMethod('monitor');
	public static readonly MONITOR_BYPASS_69_DW = API.getMethod('monitor/byPass-69-dw');
	public static readonly MONITORING_SEND_FILING_69_DW_SHORT = API.getMethod('monitor/byPassDocument-35-dw-2');
	public static readonly MONITORING_SEND_35_DW_2 = API.getMethod('monitor/byPassDocument-69-dw');

	public static readonly SEND_CLIENT_LOG = API.getMethod('logFrontEnd');

	public static readonly GET_QUOTA_CORPORATE_ACITON = API.getMethod('security/corporate-action/daily-report');

	//incentive report
	public static readonly GET_INCENTIVE_REPORT_LIST = API.getMethod('incentiveReport/search');
	public static readonly GET_INCENTIVE_START_LISTING_FEE_YAER = API.getMethod('incentiveReport/startyear');

	//inquiry log
	public static readonly GET_INQUIRY_LOG_OPERATION = API.getMethod('allocateDW/operation-search');
	public static readonly GET_EXCEL_LOG_OPERATION = API.getMethod('allocateDW/operation-search/generate-excel');

	public static getMethod(api: string) {
		return `${environment.dwsApiBaseUrl}${api}`
	}
}
