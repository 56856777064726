import { Injectable } from '@angular/core';
import { ApiClientService } from '../api-client.service';
import { Observable } from 'rxjs';
import { API } from '@app/shared/app.constant';
import { UserProfileService } from '../user-profile.service';
import { isNullOrUndefined } from 'util';

@Injectable()
export class ImService {
  constructor(
    private apiService: ApiClientService,
    public userProfileService: UserProfileService
  ) { }

  public update(data): Observable<any> {
    let url = API.UPDATE_IM
    const json = new FormData()
    if (!isNullOrUndefined(data.requestedIMFileNameTh)) {
      json.set('requestedIMFileNameTh', data.requestedIMFileNameTh)
    }

    if (!isNullOrUndefined(data.requestedIMFileNameEn)) {
      json.set('requestedIMFileNameEn', data.requestedIMFileNameEn)
    }
    return this.apiService.post<any>(url, json)
  }

  public request(): Observable<any> {
    let url = API.REQUEST_IM
    const userSession = this.userProfileService.getSessionUser()
    return this.apiService.get<any>(url + `/${userSession.company.id}`)
  }

  public get(): Observable<any> {
    let url = API.GET_IM
    const userSession = this.userProfileService.getSessionUser()
    return this.apiService.get<any>(url + `/${userSession.company.id}`)
  }
}
