import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Log, Level } from 'ng2-logger/browser'

@Injectable()
export class Logger {
	debug(topic: string, message: string, ...optionalParams: any[]) {
		if (!environment.production) {
			const log = Log.create(topic)
			message = this.setDateTime(message)
			log.color = 'blue'
			log.data(message, optionalParams)
		}
	}

	info(topic: string, message: string, ...optionalParams: any[]) {
		if (!environment.production) {
			const log = Log.create(topic)
			message = this.setDateTime(message)
			log.color = 'green'
			log.info(message, optionalParams)
		}
	}

	error(topic: string, message: string, ...optionalParams: any[]) {
		if (!environment.production) {
			const log = Log.create(topic)
			message = this.setDateTime(message)
			log.color = 'red'
			log.error(message, optionalParams)
		}
	}

	private setDateTime(message) {
		message = `[${new Date()}] ${message}`
		return message
	}
}
