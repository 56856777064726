import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SubscriptionProfile } from '../../../shared/models/corebusiness/subscription-profile'
import { Data } from '../../../shared/models/data'
import { ApiClientService } from '../api-client.service'
import { UserProfileService } from '../user-profile.service'
import { API } from '../../../shared/app.constant'

@Injectable()
export class SubscriptionProfileService {
	constructor(
		private apiService: ApiClientService,
		public userProfileService: UserProfileService
	) { }

	public getLists(): Observable<any> {
		const url = API.SUBSCRIPTION_PROFILE_LIST
		return this.apiService.get<any>(url)
	}

	public search(parameter): Observable<any> {
		const url = API.SUBSCRIPTION_PROFILE_SEARCH
		const json = {
			brokerageAccount: parameter.brokerageAccount,
			name: parameter.name,
			nationalityId: parameter.nationalityId,
			referenceNo: parameter.referenceNo,
			referenceType: parameter.referenceType !== 'null' ? parameter.referenceType : null,
			companyId: parameter.companyId
		}
		return this.apiService.post<any>(url, json)
	}

	public create(parameter): Observable<Data<SubscriptionProfile>> {
		const url = API.SUBSCRIPTION_PROFILE_CREATE
		return this.apiService.post<SubscriptionProfile>(url, parameter)
	}

	public update(parameter): Observable<SubscriptionProfile> {
		const url = API.SUBSCRIPTION_PROFILE_UPDATE + `/${parameter.id}`
		return this.apiService.put<SubscriptionProfile>(url, parameter)
	}
}
