import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { API } from '@app/shared/app.constant'
import { UserProfileService } from '../user-profile.service'
import { ApiClientService } from '../api-client.service'
import * as moment from 'moment'
import { isNullOrUndefined } from '@swimlane/ngx-datatable/release/utils'
import { SubmitApplication } from '@app/shared/models/corebusiness/submit-application'
import { Batch } from '@app/shared/models/corebusiness/batch'
import { map } from 'rxjs/operators'
import * as numeral from 'numeral'
import { DigitalSignature } from '@app/shared/models/corebusiness/digital-signature'
import { DigitalSignatureSeconRound } from '@app/shared/models/corebusiness/digital-signature-second-round'

// private SubmitApplicationDWStatus status;
// private FilingStatus filingStatus;
// private String allocateType;
// private String beginDate;
// private String endDate;
// private Long issuer;
// private String underlyingCode;
// private int page;
// private int size;
// private String name;
// private String filingType;

export interface SubmitApplicationShortAndFullParam {
  beginDate: string
  endDate: string
  status?: string
  issuer?: string
  filingStatus?: string;
  filingType?: string;
  name?: string;
  underlyingCode?: string;
  page: number;
  size: number;
}

@Injectable()
export class ShortService {
  constructor(
    private apiService: ApiClientService,
    public userProfileService: UserProfileService
  ) { }

  public search(body: SubmitApplicationShortAndFullParam, isRegulator: boolean): Observable<{ body: SubmitApplication[], count: number }> {
    const url = isRegulator ? API.SEARCH_SUBMIT_APPLICATION_ALL : API.SEARCH_SUBMIT_APPLICATION;
    return this.apiService.post<{ body: SubmitApplication[], count: number }>(url, body, null, { includeCount: true });
  }

  public getSubmitApplication(appId): Observable<SubmitApplication> {
    const url = API.GET_SUBMIT_APPLICATION
    return this.apiService.get<any>(url + `/${appId}`)
  }

  public getBatchList(fg): Observable<any> {
    const url = API.GET_BATCH_LIST
    const json = {
      tradingDate: moment(fg.tradingDate, 'YYYY/MM/DD').format('DD/MM/YYYY'),
      allocateType: fg.allocateType,
      submitAppId: fg.id
    }
    return this.apiService.post<any>(url, json)
  }

  public getQualified(fg): Observable<Batch[]> {
    const url = API.GET_QUALIFIED
    const json = new FormData()

    json.set('listedDWListParam', JSON.stringify(fg.selectList))

    if (!isNullOrUndefined(fg.file)) {
      json.set('ncConfirmationLetterFile', fg.file)
    }

    json.set('submitApplicationDWRequestParam', JSON.stringify({
      appId: !isNullOrUndefined(fg.appId) ? fg.appId : null,
      selfDeclare: true,
      nc: fg.nc ? fg.nc : 0,
      ncr: fg.ncr ? fg.ncr : 0,
      authorizePersons: fg.authorizePersons ? fg.authorizePersons : '',
      allocateType: fg.allocateType
    })
    )

    return this.apiService.post<any>(url, json)
  }

  public createSubmitApp(fg): Observable<any> {
    const url = API.CREATE_SUBMIT_APPLICATION
    const json = new FormData()

    json.set('listedDWListParam', JSON.stringify(fg.selectList))

    if (!isNullOrUndefined(fg.file)) {
      json.set('ncConfirmationLetterFile', fg.file)
    }

    json.set('submitApplicationDWRequestParam', JSON.stringify({
      selfDeclare: true,
      nc: fg.nc ? numeral(fg.nc).value() : 0,
      ncr: fg.ncr ? fg.ncr : 0,
      authorizePersons: fg.authorizePersons ? fg.authorizePersons : '',
      allocateType: fg.allocateType,
      authorizedPersonAccept: fg.authorizedPersonAccept
    })
    )

    return this.apiService.post<any>(url, json)
  }

  public updateDocumentSubmitApp(fg): Observable<any> {
    const url = API.UPDATE_SUBMIT_APPLICATION
    const json = new FormData()

    json.set('listedDWListParam', JSON.stringify(fg.selectList))

    if (!isNullOrUndefined(fg.file)) {
      json.set('ncConfirmationLetterFile', fg.file)
    }

    json.set('submitApplicationDWRequestParam', JSON.stringify({
      selfDeclare: true,
      nc: fg.nc ? numeral(fg.nc).value() : 0,
      ncr: fg.ncr ? fg.ncr : 0,
      authorizePersons: fg.authorizePersons ? fg.authorizePersons : '',
      allocateType: fg.allocateType,
      authorizedPersonAccept: fg.authorizedPersonAccept,
      lastUpdated: fg.lastUpdated ? fg.lastUpdated : null,
    }))

    return this.apiService.post<any>(url + `/${fg.appId}`, json)
  }

  public updateSubmitApp(fg, appId?: string): Observable<any> {
    const url = API.UPDATE_ALLOCATEDW
    const deviation = this.transformDeviation(fg)
    const json = {
      specialFeature: fg.specialFeature,
      deviation: deviation,
      deviationAsOf: fg.deviationAsOf ? fg.deviationAsOf : null,
      appId: appId ? appId : null,
    }
    return this.apiService.post<any>(url + `/${fg.appId}`, json)
  }

  transformDeviation(fg) {
    if (!isNullOrUndefined(fg.deviation90)) {
      const deviation90 = (!isNullOrUndefined(fg.deviation90) && fg.deviation90 !== '') ? this.toNumeral(fg.deviation90) : ''
      const deviation120 = (!isNullOrUndefined(fg.deviation120) && fg.deviation120 !== '') ? this.toNumeral(fg.deviation120) : ''
      const deviation180 = (!isNullOrUndefined(fg.deviation180) && fg.deviation180 !== '') ? this.toNumeral(fg.deviation180) : ''
      const deviation250 = (!isNullOrUndefined(fg.deviation250) && fg.deviation250 !== '') ? this.toNumeral(fg.deviation250) : ''
      return `${deviation90}:90|${deviation120}:120|${deviation180}:180|${deviation250}:250`
    } else {
      return null
    }
  }

  toNumeral(value) {
    return Math.abs(numeral(value).format('0,0.00[000]').split(',').join(''))
  }

  public downloadFiling(fileSystemName, companyId: string): Observable<any> {
    const url = API.DOWNLOAD_FILE_QUALIFICATION
    const userSession = this.userProfileService.getSessionUser()
    return this.apiService.getBlob<any>(url + `/${companyId}/` + fileSystemName)
  }

  public downloadFilingSubmitApp(fileSystemName, companyId): Observable<any> {
    const url = API.DOWNLOAD_SUBMIT_APPLICATION
    const userSession = this.userProfileService.getSessionUser()
    const id = userSession.isRegulator ? companyId : userSession.company.id
    return this.apiService.getBlob<any>(url + `/${id}/` + fileSystemName)
  }

  public uploadFilingAllocateDw(fg, listedDWId): Observable<any> {
    const url = API.UPLOAD_ALLOCATEDW_FILING
    const json = new FormData()
    if (!isNullOrUndefined(fg.specialCharacter)) {
      json.set('uploadedFile', fg.specialCharacter)
    }

    if (!isNullOrUndefined(fg.additionalFA)) {
      json.set('uploadedFile', fg.additionalFA)
    }

    if (!isNullOrUndefined(fg.additionalRight)) {
      json.set('uploadedFile', fg.additionalRight)
    }

    if (!isNullOrUndefined(fg.file)) {
      json.set('uploadedFile', fg.file)
    }

    if (!isNullOrUndefined(fg.listingDocRepoCode)) {
      json.set('listingDocRepoCode', fg.listingDocRepoCode)
    }

    if (!isNullOrUndefined(fg.description)) {
      json.set('otherDescription', fg.description)
    }

    return this.apiService.post<any>(url + `/${listedDWId}`, json)
  }

  public uploadAllocateDwDoc(file, listedDWId, repoCode: string): Observable<any> {
    const url = API.UPLOAD_ALLOCATEDW_FILING
    const json = new FormData()

    if (!isNullOrUndefined(file)) {
      json.set('uploadedFile', file)
    }
    if (!isNullOrUndefined(repoCode)) {
      json.set('listingDocRepoCode', repoCode)
    }

    return this.apiService.post<any>(url + `/${listedDWId}`, json)
  }

  public deleteFile(attachListingDocId, listedDWId): Observable<any> {
    const url = API.DELETE_ALLOCATEDW_FILE
    return this.apiService.post<any>(url + `/${listedDWId}?attachListingDocId=` + attachListingDocId)
  }

  public sendSubmitApp(id: number, digitalSign: DigitalSignature): Observable<any> {
    const url = API.SEND_SUBMIT_APPLICATION;
    return this.apiService.post<any>(`${url}/${id}`, digitalSign);
  }

  public sendSubmitAppWithCondition(id: number, digitalSign: DigitalSignatureSeconRound): Observable<any> {
    const url = API.SEND_SUBMIT_APPLICATION_SECOND_ROUND;
    return this.apiService.post<any>(`${url}/${id}`, digitalSign);
  }

  public sendApprove(id: number): Observable<Batch> {
    const url = API.SEND_APPROVE_BATCH
    return this.apiService.get<Batch>(`${url}/${id}`).pipe(map((b) => b[0]))
    // return of(a as Batch)
  }


  public sendReceive(id: number): Observable<Batch> {
    const url = API.SEND_RECEIVE_BATCH
    return this.apiService.get<Batch>(`${url}/${id}`).pipe(map((b) => b[0]))
    // return of(a as Batch)
  }

  public sendRejectApprove(id: number): Observable<Batch> {
    const url = API.SEND_APPROVE_BATCH
    return this.apiService.get<Batch>(`${url}/${id}/reject`).pipe(map((b) => b[0]))
  }

  public sendRejectReceive(id: number): Observable<Batch> {
    const url = API.SEND_RECEIVE_BATCH
    return this.apiService.get<Batch>(`${url}/${id}/reject`).pipe(map((b) => b[0]))
  }

  public tradeOnNextBusinessDay(id: number): Observable<Batch> {
    const url = API.TRADE_ON_NEXT_BUSINESSDAY
    return this.apiService.get<any>(`${url}/${id}`)
  }

  public getValidateUnderlying(id: number): Observable<any> {
    const url = API.SUBMIT_APP_VALIDATEUNDERLYING
    return this.apiService.get<any>(`${url}/${id}`)
  }
}
