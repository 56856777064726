import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-route-page',
  templateUrl: './route-page.component.html',
  styleUrls: ['./route-page.component.scss']
})
export class RoutePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // this component get AuthGuard
  }
}
