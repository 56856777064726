// Anglar
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
// Layout Directives
import {
	MenuDirective
} from './_base/layout'
// Metronic Pipes
// Metornic Services
import { ScrollTopDirective, OffcanvasDirective, ToggleDirective } from './_base/metronic'
import { DataService } from './services/api/data.service'
import { SubscriptionProfileService } from './services/api/subscription-profile.service'
import { Logger } from './logger/logger'
import { CompanyProfileService } from './services/api/company-profile.service'
import { QuotaService } from './services/api/quota.service'
import { ShortService } from './services/api/short.service'
import { QualificationService } from './services/api/qualification.service'
import { ImService } from './services/api/im.service'
import { DwIssuerService } from './services/api/dw-issuer.service'
import { NgxLoadingModule } from 'ngx-loading'
import { FilingThirtyFiveDwOneService } from './services/api/filing-thirty-five-dw-one.service';
import { DocumentListService } from './services/document/document-list.service'
import { CorporateActionService } from './services/api/corporate-action.service'

@NgModule({
	imports: [
		CommonModule,
		NgxLoadingModule.forRoot({})
	],
	declarations: [
		// directives
		ScrollTopDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
	],
	exports: [
		// directives
		ScrollTopDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
	],
	providers: [
		DataService,
		SubscriptionProfileService,
		CompanyProfileService,
		QuotaService,
		ShortService,
		QualificationService,
		ImService,
		Logger,
		DwIssuerService,
		FilingThirtyFiveDwOneService,
		DocumentListService,
		CorporateActionService
	]
})
export class CoreModule { }
