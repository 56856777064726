import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ApiClientService } from '../api-client.service'
import { API } from '../../../shared/app.constant'

@Injectable()
export class DataService {
	constructor(
		private apiService: ApiClientService
	) {}

	public getReferenceType(setFilter) {
		let json = []
		let i = 0
		if (setFilter === true) {
			json.push({ name: 'null' })
		}

		for (i = 0; i <= 9; i++) {
			json.push({ name: i })
		}
		json.push({ name: 'A' })
		json.push({ name: 'B' })
		return json
	}

	public getNationlityLists(): Observable<any> {
		let url = API.NATION_LIST
		return this.apiService.get(url)
	}
}
