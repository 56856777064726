import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiClientService } from '../api-client.service'
import { UserProfileService } from '../user-profile.service'
import { API } from '../../../shared/app.constant'
import { isNullOrUndefined } from 'util';
import { Allowance } from '@app/shared/models/response/allowance';
import { Company } from '@app/shared/models/corebusiness/company';
import { concatAll } from 'rxjs/operators';
import * as numeral from 'numeral';
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class CompanyProfileService {
	constructor(
		private apiService: ApiClientService,
		public userProfileService: UserProfileService,
		private translate: TranslateService,
	) { }

	public getCompanies$(): Observable<Company[]> {
		const url = API.GET_COMPANY_PROFILE.concat('?isIssuerDW=true');
		return this.apiService.get<any>(url).pipe(concatAll());
	}

	public getCompanyProfileById(id: number | string): Observable<Company> {
		const url = API.GET_COMPANY_PROFILE
		return this.apiService.get<any>(url + `/${id}`);
	}

	// Company Profile
	public getCompanyProfile(): Observable<any> {
		const url = API.GET_COMPANY_PROFILE
		const userSession = this.userProfileService.getSessionUser()
		return this.apiService.get<any>(url + `/${userSession.company.id}`)
	}

	public updateCompanyProfileById(Id, req): Observable<any> {
		const url = API.COMPANY_PROFILE_PUT
		return this.apiService.put<any>(url + `/${Id}`, req)
	}

	// SharedHolder
	public getSharedHolderStructureLists(id: string): Observable<any> {
		const url = API.LIST_SHAREHOLDER
		return this.apiService.get<any>(url + `/${id}`)
	}

	public saveSharedHolder(id: string, req: any): Observable<any> {
		const url = API.SAVE_SHAREHOLDER
		let json = {}
		if (req.type === 'person') {
			json = {
				'title': req.title,
				'lastName': req.lastName,
				'name': req.name,
				'share': this.toNumeralInt(req.share),
				'sharePercent': this.toNumeral(req.sharePercent),
				'type': req.type
			}
		} else {
			json = {
				'name': req.name,
				'share': this.toNumeralInt(req.share),
				'sharePercent': this.toNumeral(req.sharePercent),
				'type': req.type
			}
		}
		return this.apiService.post<any>(url + `/${id}`, json)
	}

	public updateSharedHolder(req): Observable<any> {
		const url = API.UPDATE_SHAREHOLDER

		let json = {}
		if (req.type === 'person') {
			json = {
				'title': req.title,
				'lastName': req.lastName,
				'name': req.name,
				'share': this.toNumeralInt(req.share),
				'sharePercent': this.toNumeral(req.sharePercent),
				'type': req.type
			}
		} else {
			json = {
				'name': req.name,
				'share': this.toNumeralInt(req.share),
				'sharePercent': this.toNumeral(req.sharePercent),
				'type': req.type
			}
		}
		return this.apiService.put<any>(url + `/${req.id}`, json)
	}

	public removeSharedHolderById(Id, req): Observable<any> {
		const url = API.REMOVE_SHAREHOLDER_BY_ID
		return this.apiService.put<any>(url + `/${Id}`, req)
	}

	// President
	public getPresidentLists(Id) {
		const url = API.LIST_PRESIDENT
		return this.apiService.get<any>(url + `/${Id}`)
	}

	public savePresident(req): Observable<any> {
		const url = API.SAVE_PRESIDENT
		const userSession = this.userProfileService.getSessionUser()
		let json = {
			'lastName': req.lastName,
			'name': req.name,
			'position': req.position,
			'title': req.title,
			'email': req.email
		}
		return this.apiService.post<any>(url + `/${userSession.company.id}`, json)
	}

	public updatePresident(req): Observable<any> {
		const url = API.UPDATE_PRESIDENT
		let json = {
			'ceo': req.ceo,
			'cfo': req.cfo,
			'title': req.title,
			'lastName': req.lastName,
			'name': req.name,
			'position': req.position,
			'email': req.email
		}
		return this.apiService.put<any>(url + `/${req.id}`, json)
	}

	public removePresidentById(Id, req): Observable<any> {
		const url = API.REMOVE_PRESIDENT
		return this.apiService.put<any>(url + `/${Id}`, req)
	}

	// Director
	public getDirectorLists(id: string): Observable<any> {
		const url = API.LIST_DIRECTOR;
		return this.apiService.get<any>(url + `/${id}`)
	}

	public saveDirector(id: string, req: { [s: string]: any }): Observable<any> {
		const url = API.SAVE_DIRECTOR
		const json = new FormData()

		json.set('reqBoardDirector', JSON.stringify({
			title: req.title ? req.title : '',
			name: req.name ? req.name : '',
			lastName: req.lastName ? req.lastName : '',
			position: req.position ? req.position : '',
			email: req.email
		}))

		if (!isNullOrUndefined(req.file)) {
			json.set('writtenAuthorityFile', req.file)
		}

		return this.apiService.post<any>(url + `/${id}`, json)
	}

	public updateDirector(req): Observable<any> {
		const url = API.UPDATE_DIRECTOR
		const json = new FormData()
		json.set('reqBoardDirector', JSON.stringify({
			'id': req.id,
			'authorized': req.authorized,
			'committee': req.committee,
			'title': req.title,
			'lastName': req.lastName,
			'name': req.name,
			'position': req.position,
			'email': req.email
		}))

		if (!isNullOrUndefined(req.file)) {
			json.set('writtenAuthorityFile', req.file)
		}
		return this.apiService.put<any>(url + `/${req.id}`, json)
	}

	public downloadDirector(companyId: string, fileName: string): Observable<any> {
		const url = API.DOWNLOAD_DIRECTOR
		return this.apiService.getBlob(url + `/${companyId}/${fileName}`)
	}

	public removeDirectorById(Id, req): Observable<any> {
		const url = API.REMOVE_DIRECTOR
		return this.apiService.put<any>(url + `/${Id}`, req)
	}

	// MarketMaker
	public getMarketMaker(): Observable<any> {
		const url = API.GET_COMPANY_PROFILE
		const userSession = this.userProfileService.getSessionUser()
		return this.apiService.get<any>(url + `/${userSession.company.id}`)
	}

	public updateMarketMaker(params): Observable<any> {
		const url = API.UPDATE_MARKETMAKER
		const userSession = this.userProfileService.getSessionUser()
		let json = {}

		if (params.marketMakerObligation === true) {
			json = {
				marketMakerObligation: params.marketMakerObligation ? params.marketMakerObligation : 0,
				bidOfferPriceSpread: params.bidOfferPriceSpread ? this.toNumeralInt(params.bidOfferPriceSpread) : 0,
				minimumBidOfferUnit: params.minimumBidOfferUnit ? this.toNumeralInt(params.minimumBidOfferUnit) : 0,
				minimumBidOfferTradingHour: params.minimumBidOfferTradingHour ? this.toNumeral(params.minimumBidOfferTradingHour) : 0,
				minimumMarketMakerDWPrice: params.minimumMarketMakerDWPrice ? this.toNumeral(params.minimumMarketMakerDWPrice) : 0
			}
		} else {
			json = {
				marketMakerObligation: params.marketMakerObligation,
				bidOfferPriceSpread: 0,
				minimumBidOfferUnit: 0,
				minimumBidOfferTradingHour: 0,
				minimumMarketMakerDWPrice: 0
			}
		}

		return this.apiService.put<any>(url + `/${userSession.company.id}`, json)
	}

	// Filing
	public getRepositoryFiling(id: string | number): Observable<any> {
		const url = API.GET_REPOSITORY_FILING + `/${id}`;
		return this.apiService.get<any>(url);
	}

	public uploadFiling(file, item, selector): Observable<any> {
		const url = API.UPLOAD_FILING
		let path_req = ''
		if (!isNullOrUndefined(item.filings)) {
			path_req = '?filingId=' + (item.filings[0].id ? item.filings[0].id : null) + '&repositoryId=' + item.id + (!isNullOrUndefined(selector) ? '&subtype=' + selector : '')
		} else {
			path_req = '?repositoryId=' + item.id + (!isNullOrUndefined(selector) ? '&subtype=' + selector : '') + (!isNullOrUndefined(item.description) ? '&otherDescription=' + item.description : '')
		}
		return this.apiService.post<any>(url + path_req, file)
	}

	public selectSubType(item, selectItem): Observable<any> {
		const url = API.UPLOAD_FILING
		let path_req = ''
		if (!isNullOrUndefined(item.filings)) {
			path_req = '?filingId=' + (item.filings[0].id ? item.filings[0].id : null) + '&repositoryId=' + item.id + '&subtype=' + selectItem[0].key
		} else {
			path_req = '?repositoryId=' + item.id + '&subtype=' + selectItem[0].key
		}
		return this.apiService.post<any>(url + path_req)
	}

	public downloadFiling(filingId, companyId): Observable<any> {
		const url = API.DOWNLOAD_FILING
		let path_req = ''
		path_req = `?filingId=${filingId}&companyId=${companyId}`
		return this.apiService.getBlob(url + path_req)
	}

	public deleteFiling(item): Observable<any> {
		const url = API.DELETE_FILING

		let path_req = `?filingId=${!isNullOrUndefined(item.filings) ? item.filings[0].id : item.id}`

		return this.apiService.post<any>(url + path_req)
	}

	public updateFiling(item, selectItem): Observable<any> {
		const url = API.UPDATE_FILING
		const path_req = '?filingId=' + (item.filings[0].id ? item.filings[0].id : null) + '&subtype=' + selectItem
		return this.apiService.post<any>(url + path_req)
	}

	public getQualification(id: string): Observable<any> {
		const url = API.GET_QUALIFICATION
		return this.apiService.get<any>(url + `/${id}`);
	}

	public updateQualification(id: string, value: { [s: string]: any }): Observable<any> {
		const url = API.UPDATE_QUALIFICATION
		const json = new FormData()

		if (!isNullOrUndefined(value.risk_1_file)) {
			json.set('derivativeDealerFile', value.risk_1_file)
		}

		if (!isNullOrUndefined(value.risk_2_file)) {
			json.set('authorizedIssuerFile', value.risk_2_file)
		}

		if (!isNullOrUndefined(value.risk_3_file)) {
			json.set('backToBackFile', value.risk_3_file)
		}

		if (!isNullOrUndefined(value.risk_3_file_back)) {
			json.set('backToBackContractFile', value.risk_3_file_back)
		}

		if (!isNullOrUndefined(value.qual_1_file)) {
			json.set('counterPartyDerivativeDealerFile', value.qual_1_file)
		}

		if (!isNullOrUndefined(value.qual_2_file)) {
			json.set('counterPartyAuthorizedIssuerFile', value.qual_2_file)
		}

		if (!isNullOrUndefined(value.qual_3_file)) {
			json.set('commercialBankFile', value.qual_3_file)
		}

		if (!isNullOrUndefined(value.qual_4_file)) {
			json.set('foreignIssuerFile', value.qual_4_file)
		}

		json.set('riskQualificationRequest',
			JSON.stringify({
				'backToBackCompanyName': value.backToBackCompanyName ? value.backToBackCompanyName : null,
				'backToBackCompanyId': value.backToBackCompanyId ? value.backToBackCompanyId : null,
				'backToBackDisplayCompanyName': value.backToBackDisplayCompanyName ? value.backToBackDisplayCompanyName : null,

				'isDerivativeDealer': value.rick_check_1 ? value.rick_check_1 : false,
				'derivativeDealerDocumentNo': value.risk_1_no,

				'isAuthorizedIssuer': value.rick_check_2 ? value.rick_check_2 : false,
				'authorizedIssuerDocumentNo': value.risk_2_no,

				'isBackToBack': value.rick_check_3 ? value.rick_check_3 : false,
				'backToBackDocumentNo': value.risk_3_no,

				'isCounterPartyDerivativeDealer': value.qual_check_1 ? value.qual_check_1 : false,
				'counterPartyDerivativeDealerDocumentNo': value.qual_1_no,

				'isCounterPartyAuthorizedIssuer': value.qual_check_2 ? value.qual_check_2 : false,
				'counterPartyAuthorizedIssuerDocumentNo': value.qual_2_no,

				'isCommercialBank': value.qual_check_3 ? value.qual_check_3 : false,
				'commercialBankDocumentNo': value.qual_3_no,

				'isForeignIssuer': value.qual_check_4 ? value.qual_check_4 : false,
				'foreignIssuerDocumentNo': value.qual_4_no
			})
		)
		return this.apiService.post<any>(url + `/${id}`, json)
	}

	public getRenewInfo(id: string): Observable<any> {
		const url = API.GET_RENEWINFO
		return this.apiService.get<any>(url + `/${id}`)
	}

	public getFiscalEnd(id: string): Observable<any> {
		const url = API.GET_FISCALEND
		return this.apiService.get<any>(url + `/${id}`)
	}

	public updateRenewInfo(id: string, data: { [s: string]: any }): Observable<any> {
		const url = API.UPDATE_FISCALEND
		const json = new FormData()
		if (!isNullOrUndefined(data.shareholderMeetingUploadedFileNameTemp)) {
			json.set('shareholderMeetingFile', data.shareholderMeetingUploadedFile)
		}

		json.set('renewInfoRequest',
			JSON.stringify({
				fiscalEndMonth: data.fiscalEndMonth,
				fiscalEndDate: data.fiscalEndDate,
				shareholderMeetingDate: data.shareholderMeetingDate,
				determineQuarter: data.determineQuarter
			})
		)
		return this.apiService.post<any>(url + `/${id}`, json)
	}

	public checkAllowance(): Observable<Allowance> {
		const url = API.CHECK_ALLOWANCE
		return this.apiService.get<Allowance>(url)
	}

	public getDisclaimerList(companyId): Observable<any> {
		const url = `${API.GET_DISCLAIMER_LIST}/?companyId=${companyId}`
		return this.apiService.get<any>(url)
	  }

	public getDisclaimerForeignIndex(companyId): Observable<any> {
		const url = isNullOrUndefined(companyId) ? `${API.GET_DISCLAIMER_FOREIGN_INDEX}` :`${API.GET_DISCLAIMER_FOREIGN_INDEX}/?companyId=${companyId}`
		return this.apiService.get<any>(url)
	  }

	public getUnderlyingForeignIndexList(companyId): Observable<any> {
		const url = isNullOrUndefined(companyId) ? `${API.GET_UNDERLYING_FOREIGN_INDEX_LIST}` :`${API.GET_UNDERLYING_FOREIGN_INDEX_LIST}/?companyId=${companyId}`
		return this.apiService.get<any>(url)
	  }

	public deleteDisclaimer(documentId): Observable<any> {
		const url = API.DELETE_DISCLAIMER;
		const json = new FormData()
		if (!isNullOrUndefined(documentId)) {
			json.set('documentId', documentId)
		}
		return this.apiService.post<any>(url,json)

	}

	public uploadDisclaimerList(formdata): Observable<any> {
		const url = API.UPLOAD_DISCLAIMER
		return this.apiService.post<any>(url , formdata)
	}

	public updateDisclaimerList(formdata,documentId): Observable<any> {
		const url = API.UPDATE_DISCLAIMER;
		return this.apiService.put<any>(url + `/${documentId}`, formdata)
	}


	public downloadDisclaimer(disclaimerId , companyId?): Observable<any> {
		const url = API.DOWNLOAD_DISCLAIMER ;
		let path_req =  ''
		if(isNullOrUndefined(companyId)){
			path_req =  `?documentId=${disclaimerId}`
		} else {
			path_req = `?documentId=${disclaimerId}`+`&companyId=${companyId}`
		}
		return this.apiService.getBlob(url + path_req)
	}

	getErrorMessageAllowance(allowance: Allowance): string[] {
		let messages: string[] = []
		if (!allowance.financialStatementSent) {
		  messages.push(this.translate.instant('ALERT_MESSAGE.ALLOWANCE.FINANCIAL_SENT'));
		}
		if (!allowance.financialStatementUpToDate) {
		  messages.push(this.translate.instant('ALERT_MESSAGE.ALLOWANCE.FINANCIAL_UPTODATE'));
		}
		return messages;
	}

	toNumeral(value) {
		return numeral(value).format('0,0.00[000]').split(',').join('')
	}

	toNumeralInt(value) {
		return numeral(value).format('0,0').split(',').join('')
	}
}
