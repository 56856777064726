export const locale = {
	lang: 'th',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		LABEL: {
			BATCH_NO: 'Batch No.',
			STATUS: 'สถานะ',
			SIGN_TYPE: 'วิธีลงนาม',
			DOCUMENT_SHELF_ISSUER: 'เอกสารประกอบการยื่นคำขอ 35-DW-1',
			DOCUMENT_SHELF_ISSUER_TWO: 'เอกสารประกอบการยื่นคำขอ 35-DW-2',
			DOCUMENT_SUBMIT_APPLICATION_FULL:
				'เอกสารประกอบการยื่นคำขอ 69-DW-Full',
			AUTHORIZED_TITLE:
				'โปรดระบุชื่อผู้มีอำนาจลงนาม (ตามหนังสือรับรองกระทรวงพาณิชย์หรือหนังสือมอบอำนาจ)',
		},
		TITLE: {
			SHELF_ISSUER: '35-DW-1',
			SHELF_ISSUER_TWO: '35-DW-2',
			SUBMIT_APPLICATION_FULL: '69-DW-Full',
		},
		DOCUMENT: {
			SUPPORTING_DOCUMENTS: 'เอกสารประกอบการยื่นคำขอ {{value}}',
			ADDITIONAL_SEC_AND_SET: 'เอกสารแนบอื่นๆ (SEC/SET)',
			WAITING_UPLOAD: 'เอกสารที่รอการอัพโหลด',
		},
		SEC_ADMIN: {
			THIRTYFIVEDWONE: 'แบบคำขอ 35-DW-1',
			SUBMIT_APP_FULL: 'แบบคำขอ 69-DW-FULL',
			TABLE_HEADER: {
				STATUSDATE: 'วันที่ทำรายการ',
				ISSUER: 'ผู้ขอจัดสรร',
				STATUS: 'สถานะ',
				NAME: 'Batch No.',
				EFFECTIVE_DATE: 'วันที่อนุญาต',
				EXPIRE_DATE: 'วันที่สิ้นสุด',
				APPROVE: 'Approve',
				REF_TO_SUBMIT_APP: 'อ้างถึง 69-DW-Full',
				REF_TO_SHELF_ISSUER: 'อ้างถึง 35-DW-1',
				REVERT: 'ถอยสถานะ',
			},
			FILTER: {
				STATUSDATE: 'วันที่ทำรายการ',
				SECURITIESSYMBOL: 'สินทรัพย์อ้างอิง',
				SECURITIESSYMBOLTYPE: 'ประเภทสินทรัพย์อ้างอิง',
				STATUS: 'สถานะ',
				ISSUER: 'ผู้ขอจัดสรร',
			},
			FIELD: {
				TO: 'ถึง',
			},
			BUTTON: {
				FOR_APPROVE: 'เพื่อพิจารณา',
			},
		},
		QUOTA: {
			HEADER: {
				FILTER: 'Filter',
				QUOTA: 'สินทรัพย์อ้างอิง',
				DOCUMENT: 'สินทรัพย์อ้างอิง',
			},
			TABLE_HEADER: {
				STATUSDATE: 'วันที่ทำรายการ',
				ISSUER: 'ผู้ขอจัดสรร',
				SECURITIESSYMBOL: 'สินทรัพย์อ้างอิง',
				UNDERLYING: 'จำนวนหุ้นอ้างอิง',
				DW: 'ชื่อ DW',
				ALLOCATETYPE: 'New/Further',
				DWEXPECTRADINGDATE: 'วันที่ออก DW',
				LASTTRADINGDATE: 'วันทำการซื้อขายสุดท้าย',
				MODIFYDATE: 'วันที่แก้ไข',
				STATUS: 'สถานะ',
				RESERVEUNDERLYING: 'จำนวนหุ้นอ้างอิง',
			},
			FILTER: {
				STATUSDATE: 'วันที่ทำรายการ',
				SECURITIESSYMBOL: 'สินทรัพย์อ้างอิง',
				SECURITIESSYMBOLTYPE: 'ประเภทสินทรัพย์อ้างอิง',
				FILING_STATUS: 'สถานะ Filing',
				LISTING_STATUS: 'สถานะ Listing',
				STATUS: 'สถานะ',
				ISSUER: 'ผู้ขอจัดสรร',
				UNDERLYING: 'หุ้นอ้างอิง',
				DW: 'ชื่อ DW',
				TYPE: 'ประเภทแบบคำขอ',
				INCENTIVE_YEAR:'Incentive (ปี)'
			},
			BUTTON: {
				ALLOCATION: {
					NEW: 'สร้าง New',
					FURTHER: 'สร้าง Further',
				},
				UNDERLYING: {
					LISTED: 'จองหุ้นอ้างอิง',
					INDEX: 'จอง Index',
				},
				UPLOAD: 'Upload',
			},
			FIELD: {
				TO: 'ถึง',
				LISTED: {
					CONVERSION_RATIO: 'อัตราการใช้สิทธิ (คำนวณ)',
					EXERCISE_PRICE_DEFAULT: 'ราคาใช้สิทธิ (บาท)',
					EXERCISE_PRICE: 'ราคาใช้สิทธิ ({{currency}})'
				},
				INDEX: {
					CONVERSION_RATIO_DEFAULT: 'ตัวคูณดัชนี (บาท : จุดของดัชนี)',
					CONVERSION_RATIO: 'ตัวคูณดัชนี ({{currency}} : จุดของดัชนี)'
				}
			},
		},
		SHORT: {
			HEADER: {
				ISSUE: {
					New: 'New Issue',
					Further: 'Further Issue',
				},
				FILTER: 'Filter',
				QUOTA: 'สินทรัพย์อ้างอิง',
				SUBMITAPPLICATION: 'Submit Application',
				DOCUMENT: 'เอกสารประกอบการยื่นคำขอ 35-DW-2',
				DOCUMENT_QUOTA: 'เอกสารประกอบการยื่น Filing',
			},
			TABLE_HEADER: {
				STATUSDATE: 'วันที่ทำรายการ',
				NAME: 'Batch No.',
				ISSUER: 'ผู้ขอจัดสรร',
				TRADINGDATE: 'Trading Date',
				DWAMOUNT: 'จำนวน DW',
				ALLOCATETYPE: 'New/Further Issue',
				DOCUMENT: 'เอกสารประกอบ',
				FILING_STATUS: 'สถานะ Filing',
				LISTING_STATUS: 'สถานะ Listing',
				EFFECTIVE_DATE: 'วันที่มีผล',
				SUBMIT_DATE: 'วันที่อนุมัติ',
				SENDER: 'ส่ง',
				APPROVE: 'Approve',
				RECEIVE: 'Receive',
				ROLLBACK_STEP: 'ถอยสถานะ',
				REF_TO_SHELF_ISSUER: 'อ้างถึง 35-DW-1',
				UNLOCK_ATTACH_DOCUMENT: 'เพิ่มเอกสารพิเศษ',
				DW_ROOM_SUCCESS_OUT_OF_TOTAL: 'จำนวน DW ที่ผ่านโควตา ',
				DW_ROOM_SUCCESS_OUT_OF_TOTAL_UNIT : '(ผ่าน/ทั้งหมด)'
			},
			FILTER: {
				STATUSDATE: 'วันที่ทำรายการ',
				SECURITIESSYMBOL: 'สินทรัพย์อ้างอิง',
				STATUS: 'สถานะ',
			},
			BUTTON: {
				FURTHER_ISSUE: ' Further Issue',
				NEW_ISSUE: ' New Issue',
				SUBMIT: 'ตกลง',
				BACK: 'ยกเลิก',
				CLOSE: 'ปิด',
				APPROVE: 'Approve',
				REJECT: 'Reject',
				RECEIVE: 'Receive',
				ROLLBACK_STEP: 'ถอยสถานะ',
			},
			DISCLAIMER: {
				HEADER: 'คำรับรองในการยื่นคำขอให้รับ DW ผ่านระบบ',
				// tslint:disable-next-line:max-line-length
				PREFCE: 'ในการขออนุญาตเสนอขายใบสำคัญแสดงสิทธิอนุพันธ์ต่อสำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์ (“สำนักงาน ก.ล.ต.”) และในการยื่นคำขอให้ตลาดหลักทรัพย์แห่งประเทศไทย (“ตลาดหลักทรัพย์”) รับใบสำคัญแสดงสิทธิอนุพันธ์เป็นหลักทรัพย์จดทะเบียนนั้น บริษัทในฐานะผู้ออกใบสำคัญแสดงสิทธิอนุพันธ์ (“บริษัท”) ขอให้คำรับรองว่า บริษัทมีคุณสมบัติดังต่อไปนี้ครบถ้วน และตกลงจะดำรงคุณสมบัติดังกล่าวตลอดระยะเวลาที่ใบสำคัญแสดงสิทธิอนุพันธ์ของบริษัทเป็นหลักทรัพย์จดทะเบียนในตลาดหลักทรัพย์',
				1: '1.	บริษัทไม่อยู่ระหว่างถูกสั่งการเกี่ยวกับฐานะการเงินที่อาจก่อให้เกิดความเสียหาย',
				2: '2.	บริษัทไม่อยู่ระหว่างถูกสั่งการให้แก้ไขข้อบกพร่องหรือความไม่เหมาะสมเกี่ยวกับการควบคุมและการปฏิบัติงานอันดีของธุรกิจอย่างมีนัยสำคัญ',
				3: {
					VALUE: '3.	บริษัทไม่เป็นบุคคลภายในของบริษัทที่ออกหุ้นอ้างอิงตามความหมายที่กำหนดในประกาศของสำนักงาน ก.ล.ต. ที่เกี่ยวข้อง',
					SUB: {
						1: '3.1	บริษัทไม่มีการถือหุ้นไม่ว่าโดยทางตรงหรือทางอ้อมในบริษัทที่ออกหุ้นอ้างอิงเกินกว่าร้อยละยี่สิบห้าของจำนวนหุ้นที่มีสิทธิออกเสียงทั้งหมดของบริษัทที่ออกหุ้นอ้างอิงนั้น',
						2: '3.2	บริษัทไม่มีบริษัทที่ออกหุ้นอ้างอิงเป็นผู้ถือหุ้นไม่ว่าโดยทางตรงหรือทางอ้อมเกินกว่าร้อยละยี่สิบห้าของจำนวนหุ้นที่มีสิทธิออกเสียงทั้งหมด',
						// tslint:disable-next-line:max-line-length
						3: '3.3	บริษัทไม่มีผู้ถือหุ้นไม่ว่าโดยทางตรงหรือทางอ้อมรวมกันเกินกว่าร้อยละยี่สิบห้าของจำนวนหุ้นที่มีสิทธิออกเสียงทั้งหมด เป็นกลุ่มบุคคลเดียวกับผู้ถือหุ้นที่ถือหุ้นไม่ว่าโดยทางตรงหรือทางอ้อมในบริษัทที่ออกหุ้นอ้างอิงเกินกว่าร้อยละยี่สิบห้าของจำนวนหุ้นที่มีสิทธิออกเสียงทั้งหมดของบริษัทที่ออกหุ้นอ้างอิง',
						4: '3.4	บริษัทไม่มีกรรมการหรือผู้บริหารเป็นกลุ่มบุคคลเดียวกับกรรมการที่มีอำนาจลงนามผูกพันหรือผู้บริหารของบริษัทที่ออกหุ้นอ้างอิง',
						// tslint:disable-next-line:max-line-length
						5: '3.5	บริษัทไม่มีโครงสร้างการถือหุ้นหรือการจัดการในลักษณะอื่นใดที่แสดงให้เห็นได้ว่าบริษัทมีอำนาจควบคุมบริษัทที่ออกหุ้นอ้างอิง หรือมีบริษัทที่ออกหุ้นอ้างอิงเป็นผู้มีอำนาจควบคุม หรือมีผู้มีอำนาจควบคุมเป็นบุคคลเดียวกับผู้มีอำนาจควบคุมบริษัทที่ออกหุ้นอ้างอิง',
						// tslint:disable-next-line:max-line-length
						6: '3.6	หากบริษัทเป็นหรือเคยเป็นที่ปรึกษาทางการเงิน หรือผู้มีส่วนเกี่ยวข้องกับบริษัทที่ออกหุ้นอ้างอิงในลักษณะอื่นที่ทำให้สามารถเข้าถึงข้อมูลที่มีสาระสำคัญ ที่มิได้เปิดเผยต่อประชาชนทั่วไปในระยะเวลาหนึ่งปีก่อนวันใช้หุ้นเป็นหลักทรัพย์อ้างอิง บริษัทขอรับรองว่า บริษัทมีมาตรการป้องกันการล่วงรู้ข้อมูลภายในระหว่างหน่วยงานที่รับผิดชอบงานที่ปรึกษาทางการเงินหรืองานที่เกี่ยวข้องกับบริษัทที่ออกหุ้นอ้างอิงนั้น และหน่วยงานที่รับผิดชอบเกี่ยวกับการเสนอขายใบสำคัญแสดงสิทธิอนุพันธ์ที่ออก โดยได้เปิดเผยความเกี่ยวข้องและการรับรองดังกล่าวไว้ในแบบแสดงรายการข้อมูลและร่างหนังสือชี้ชวนแล้วด้วย ',
					},
				},
				// tslint:disable-next-line:max-line-length
				4: '4.	บริษัทในฐานะผู้ยื่นคำขอให้รับใบสำคัญแสดงสิทธิอนุพันธ์เป็นหลักทรัพย์จดทะเบียน และใบสำคัญแสดงสิทธิอนุพันธ์ที่บริษัทยื่นขอจดทะเบียนต่อตลาดหลักทรัพย์มีคุณสมบัติครบถ้วนตามข้อกำหนดของตลาดหลักทรัพย์ ว่าด้วยการรับ การเปิดเผยสารสนเทศ และการเพิกถอนใบสำคัญแสดงสิทธิอนุพันธ์',
				5: '5.	บริษัทได้ตรวจสอบข้อมูลในแบบคำขอและเอกสารประกอบแบบคำขอทั้งหมดแล้ว ขอรับรองว่าข้อมูลซึ่งปรากฏในแบบคำขอและเอกสารประกอบคำขอดังกล่าวถูกต้องตรงกับความเป็นจริง',
				// tslint:disable-next-line:max-line-length
				6: '6.	ในกรณีที่ใบสำคัญแสดงสิทธิอนุพันธ์ที่บริษัทยื่นคำขอจดทะเบียนมีสินทรัพย์อ้างอิงเป็นดัชนีหลักทรัพย์ บริษัทรับรองว่า บริษัทมีสิทธิตามสัญญาและ/หรือตามกฎหมายในการใช้ดัชนีหลักทรัพย์ดังกล่าวเพื่อเป็นสินทรัพย์อ้างอิงสำหรับใบสำคัญแสดงสิทธิอนุพันธ์ที่บริษัทได้ยื่นคำขอจดทะเบียนต่อตลาดหลักทรัพย์',
				// tslint:disable-next-line:max-line-length
				7: '7.	บริษัทตกลงปกป้องตลาดหลักทรัพย์จากความเสียหายใด ๆ อันเนื่องมาจากและ/หรือเกี่ยวข้องกับการที่บริษัทใช้ดัชนีหลักทรัพย์เพื่อเป็นสินทรัพย์อ้างอิงโดยไม่มีสิทธิ และในกรณีที่ตลาดหลักทรัพย์ได้รับความเสียหาย หรือถูกบุคคลภายนอกเรียกร้องค่าเสียหายจากเหตุดังกล่าว บริษัทตกลงชดใช้ค่าเสียหายที่เกิดขึ้นทั้งสิ้นให้แก่ตลาดหลักทรัพย์ด้วย',
				ACCEPT: 'ข้าพเจ้าขอรับรองว่ามีคุณสมบัติตรงตามเงื่อนไขทุกประการ',
			},
		},
		COMPANYPROFILE: {
			FORM: {
				NAME: {
					TH: 'ชื่อภาษาไทย',
					EN: 'ชื่อภาษาอังกฤษ',
				},
				ADDRESS: 'ที่ตั้ง',
				TEL: 'โทร',
				FAX: 'โทรสาร',
				WEBSITE: 'Website',
				SHOT_NAME: 'ชื่อย่อ',
				COMPANY_TYPE: 'ประเภทบริษัท',
				DW_ISSUER_ID: 'รหัสผู้ออก DW',
				COMPANY_NO: 'เลขทะเบียนบริษัท',
				COMMONSHARED: 'ทุนจดทะเบียนบริษัท',
				PAIDUPCAPITAL: 'ทุนชำระแล้ว',
				MARKET_MAKER: 'Market Maker',
				LICENSE_OBLIGATION: 'ข้อกำหนดในการดูแลสภาพคล่อง',
				LICENSE_BID_OFFER_PRICE_SPREAD:
					'ช่วงห่างระหว่างราคาเสนอซื้อและขาย',
				LICENSE_BID_MINIMUM_BID_OFFER_UNIT:
					'จำนวนเสนอซื้อและขายขั้นต่ำ(หน่วย)',
				LICENSE_BID_MINIMUM_BID_OFFER_TRADING_HOUR:
					'เวลาเสนอซื้อขายขั้นต่ำ (%)',
				LICENSE_BID_MINIMUM_MARKET_MAKER_DW_PRICE:
					'ผู้ดูแลสภาพคล่องจะไม่ทำการเสนอซื้อเสนอขาย หากราคา DW ต่ำกว่า (บาท)',
				INDEX_NAME:'ดัชนีหลักทรัพย์อ้างอิง'	,
				DISCLAIMER:'Disclaimer'
			},
			BUTTON: {
				COMPANYPROFILE: 'ข้อมูลบริษัท',
				AUTHORIZEPROFILE: 'ข้อมูลผู้มีอำนาจ',
				MARKETMAKER: 'ผู้ดูแลสภาพคล่อง',
				DOCUMENT: 'เอกสารแนบ',
				QUALIFICATION: 'เกณฑ์คุณสมบัติ (ความเสี่ยง)',
				FINANCE_QUALIDICATION: 'เกณฑ์คุณสมบัติ (การเงิน)',
				SUBMIT: 'บันทึก',
				ADD: 'เพิ่ม',
				RENEWINFORMATION: 'การต่ออายุคำขอ',
				DW_INFORMATION:'ข้อมูล DW'
			},
			HEADER: {
				COMPANY_PROFILE: 'ข้อมูลบริษัท',
				AUTHORIZE_PROFILE: 'ข้อมูลผู้มีอำนาจ',
				VALUE_TOTLE_BILL: 'ข้อมูลในการออกใบเสร็จ',
				SHAREHOLDER_STRUCTURE: 'โครงสร้างการถือหุ้น',
				AUTHORIZE_LIST:
					'รายชื่อกรรมการ / ผู้มีอำนาจลงนาม / ผู้รับมอบอำนาจ',
				PRESIDENT_LIST: 'รายชื่อผู้บริหาร',
				MARKET_MAKER: 'ผู้ดูแลสภาพคล่อง',
				DIGITAL_SIGNATURE:
					'รายชื่อผู้ลงนามที่มี EF-3 (กรณีใช้ Digital Signature)',
				REPOSITORY_FILING: 'เอกสารแนบ',
				QUALIFICATION: 'เกณฑ์คุณสมบัติ',
				FINANCE_QUALIDICATION: 'เกณฑ์คุณสมบัติ (การเงิน)',
				RE_NEW: 'ข้อมูลการต่ออายุคำขอ',
				DOCUMENT: 'เอกสารแนบ',
				QUALIDICATION: 'เกณฑ์คุณสมบัติ (ความเสี่ยง)',
				SHELF_ISSUER: 'แบบคำขอ 35-DW-1',
				SUBMIT_APP_FULL: 'แบบคำขอ 69-DW-FULL',
				DW_INFORMATION:'ข้อมูล DW'
			},
			TABLE_HEADER: {
				NAME_SURNAME: 'ชื่อ-นามสกุล',
				SHARE: 'จำนวนหุ้น',
				PERCENT: 'เปอร์เซ็นต์',
				EDIT: 'แก้ไข',
				DELETE: 'ลบ',
				POSITION: 'ตำแหน่ง',
				COMMITEE: 'กรรมการผูกพัน',
				CEO: 'CEO',
				CFO: 'CFO',
				AUTHORIZED: 'ผู้มีอำนาจลงนาม',
				DOWNLOAD: 'ดาวน์โหลด',
				EMAIL: 'e-mail',
			},
			AUTHORIZEPROFILE: {
				FILTER: {
					TITLE: 'คำนำหน้า',
					NAME: 'ชื่อ',
					LASTNAME: 'นามสกุล',
					SHARE: 'จำนวนหุ้น',
					SHAREPERCENT: 'จำนวนหุ้นเปอร์เซ็นต์',
					TYPE: 'ประเภท',
					POSITION: 'ตำแหน่ง',
					CEO: 'CEO',
					CFO: 'CFO',
					WRITTEN_AUTHORITY: 'หนังสือมอบอำนาจ',
					EMAIL: 'e-mail',
				},
				HEADER: {
					CREATE: 'สร้างโครงสร้างการถือหุ้น',
					EDIT: 'แก้ไขโครงสร้างการถือหุ้น',
				},
				MESSAGE: {
					NOTE: 'หมายเหตุ:',
					NOTE1: '1. ช่องผู้มีอำนาจลงนามคือรายชื่อกรรมการผูกพันที่ปรากฏในข้อกำหนดสิทธิ และแบบคำขอของตลาดหลักทรัพย์ฯ',
					NOTE2: '2. กรณีผู้รับมอบอำนาจ ต้องแนบเอกสารมอบอำนาจเพิ่มเติม​',
				},
				FIELD: {
					PERSON: 'บุคคล',
					CORPORATION: 'นิติบุคคล',
				},
			},
			PRESIDENT: {
				HEADER: {
					CREATE: 'สร้างรายชื่อผู้บริหาร',
					EDIT: 'แก้ไขรายชื่อผู้บริหาร',
				},
			},
			DIRECTOR: {
				HEADER: {
					CREATE: 'สร้างรายชื่อกรรมการ / ผู้มีอำนาจลงนาม / ผู้รับมอบอำนาจ',
					EDIT: 'แก้ไขรายชื่อกรรมการ / ผู้มีอำนาจลงนาม / ผู้รับมอบอำนาจ',
				},
			},
			ATTACHEDDOCUMENT: {
				HEADER: {
					CREATE: 'เพื่ม เอกสารแนบ',
				},
				FIELD: {
					DOCUMENT_NAME: 'ชื่อเอกสาร',
					UPLOAD_FILE: 'อัพโหลดเอกสาร',
				},
			},
			RENEW: {
				HEADER: {},
				FIELD: {
					DAY: 'วัน',
					MONTH: 'เดือน',
					END_OF_DAY: 'วันสิ้นรอบบัญชี',
					MEETING_DAY: 'วันที่ประชุมใหญ่ผู้ถือหุ้น',
					INVITATION: 'หนังสือเชิญประชุมผู้ถือหุ้น',
					DETERMINEQUARTER: 'ระบุประเภทงบการเงินที่ต้องการนำส่ง'
				},
			},
			FINANCE_QUALIDICATION: {
				HEADER: {
					CREATE: 'เพื่ม เอกสารแนบ',
				},
				FIELD: {
					FINANCE_STATUS: 'ด้านฐานะการเงิน',
					IS_CREDITRATING:
						'(1) ข้อมูลการจัดอันดับความน่าเชื่อถือของบริษัท',
					TRUSTEE: 'ผู้จัดอันดับความน่าเชื่อถือ',
					CREDIT_RATING_OF: 'อันดับความน่าเชื่อถือของ',
					CREDITRATING: 'อันดับเครดิต',
					TYPE: 'ประเภท',
					CREDITRATING_DATE: 'วันที่จัดอันดับ',
					DOCUMENT: 'เอกสารแนบ',
					BOOK_NO: 'หนังสือเลขที่',
					RISK: 'ด้านการบริหารความเสี่ยง',
					GUARANTOR_AGREEMENT: 'สัญญาผู้ค้ำประกัน',
				},
			},
			MARKET_MAKER : {
				REMARK : '*ข้อกำหนดดังกล่าวเป็นไปตามที่ระบุไว้ในแบบคำขอขึ้นทะเบียนเป็นผู้ดูแลสภาพคล่อง​หากต้องการเปลี่ยนแปลงเงื่อนไขการทำหน้าที่ของผู้ดูแลสภาพคล่อง กรุณาติดต่อฝ่ายปฏิบัติการซื้อขายหลักทรัพย์ ล่วงหน้าอย่างน้อย 2 วันทำการ ก่อนวันเริ่มซื้อขายวันแรก'
			}
		},
		COMPANY_PROFILE_LIST: {
			TABLE_HEADER: {
				ID: 'id',
				SHORT_NAME: 'ชื่อย่อ',
				FULL_NAME_ENG: 'ชื่อเต็มอังกฤษ',
				EXPIRE_SHELF: 'อายุ Shelf',
				INDEX_LICENSE: 'Index License',
				PAYMENT_STATUS: 'สถานะจ่ายเงิน',
			},
		},
		SUBSCRIPTIONPROFILE: {
			HEADER: {
				SUBSCRIPTIONPROFILE: 'SUBSCRIPTION PROFILE',
				FILTER: 'Filter',
				CREATE: 'สร้าง Subscription Profile',
				EDIT: 'แก้ไข Subscription Profile',
			},
			BUTTON: {
				CREATE: 'สร้าง PROFILE',
				SAVE: 'บันทึก',
				EDIT: 'บันทึกการแก้ไข',
			},
			FILTER: {
				BROKERANGEACCOUNT: 'เลขที่บัญชีลูกค้า',
				NAME: 'ชื่อ',
				REFERENCE_TYPE: 'ประเภทอ้างอิง',
				REFERENCE_NO: 'เลขที่อ้างอิง',
				NATIONALITY: 'สัญชาติ',
				COMPANY:'ผู้ขอจัดสรร'
			},
			TABLE_HEADER: {
				BROKERANGEACCOUNT: 'เลขที่บัญชีลูกค้า',
				NAME: 'ชื่อ',
				referenceType: 'ประเภทอ้างอิง',
				referenceNo: 'เลขที่อ้างอิง',
				nationlity: 'สัญชาติ',
			},
		},
		IM: {
			BUTTON: {
				SAVE: 'บันทึก',
				REQUEST: 'Send to SET',
			},
			HEADER: {
				IM: 'IM',
			},
		},
		FILING: {
			HEADER: {
				FILING: 'FILING',
				FILING69: 'FILING 69-DW-Short',
				CREATE: 'สร้าง Subscription Profile',
				EDIT: 'แก้ไข Subscription Profile',
			},
			FILTER: {
				BATCHNO: 'Batch No. ',
				STATUS: 'Listing Status',
				DWSYMBOL: 'DW Symbol ',
				AUTHORIZE: 'ผู้มีอำนาจลงนาม',
				FILING_STATUS: 'Filing Status',
				TRADINGDATE: 'วันที่เริ่มซื้อขาย',
			},
			DISCLAIMER: {
				0: 'พร้อมกันนี้ข้าพเจ้าได้แนบเอกสารประกอบแบบคำขอให้รับหลักทรัพย์ดังกล่าวเป็นหลักทรัพย์จดทะเบียนตามที่ตลาดหลักทรัพย์ฯ กำหนด และข้าพเจ้าขอรับรองว่า',
				1: '1. ข้าพเจ้าในฐานะผู้ยื่นคำขอ และใบสำคัญแสดงสิทธิอนุพันธ์ข้างต้นมีคุณสมบัติครบถ้วนตามข้อกำหนดของตลาดหลักทรัพย์ฯ ว่าด้วยการรับ การเปิดเผยสารสนเทศ และการเพิกถอนใบสำคัญแสดงสิทธิอนุพันธ์',
				2: '2. ข้าพเจ้าได้ตรวจสอบข้อมูลในแบบคำขอและเอกสารประกอบแบบคำขอทั้งหมดแล้ว ขอรับรองว่าข้อมูลซึ่งปรากฏในแบบคำขอและเอกสารประกอบคำขอดังกล่าวถูกต้องตรงกับความเป็นจริง',
				3: '3. ข้าพเจ้ามีสิทธิตามสัญญาหรือตามกฎหมายในการใช้ดัชนีหลักทรัพย์ข้างต้นเพื่อเป็นสินทรัพย์อ้างอิงสำหรับใบสำคัญแสดงสิทธิอนุพันธ์ที่ข้าพเจ้าได้ยื่นคำขอจดทะเบียนต่อตลาดหลักทรัพย์ฯ',
				// tslint:disable-next-line:max-line-length
				4: '4. ข้าพเจ้าตกลงปกป้องตลาดหลักทรัพย์ฯ จากความเสียหายใดๆ ที่เกิดขึ้นกับตลาดหลักทรัพย์ฯ อันเนื่องมาจากการที่ข้าพเจ้าใช้ดัชนีหลักทรัพย์โดยไม่มีสิทธิ และในกรณีที่ตลาดหลักทรัพย์ฯ ได้รับความเสียหาย หรือถูกบุคคลภายนอกเรียกร้องค่าเสียหายจากเหตุที่ข้าพเจ้าใช้ดัชนีหลักทรัพย์โดยไม่มีสิทธิดังกล่าว ข้าพเจ้าตกลงชดใช้ค่าเสียหายที่เกิดขึ้นทั้งสิ้นให้แก่ตลาดหลักทรัพย์ฯ ด้วย',
				SEC: {
					0: 'บริษัทขอรับรองว่าในขณะที่บริษัทเป็นผู้ออกใบสำคัญแสดงสิทธิอนุพันธ์ จะดำรงคุณสมบัติดังนี้',
					1: '1. ไม่อยู่ระหว่างถูกสั่งการเกี่ยวกับฐานะการเงินที่อาจก่อให้เกิดความเสียหาย',
					2: '2. ไม่อยู่ระหว่างถูกสั่งการให้แก้ไขข้อบกพร่องหรือความไม่เหมาะสมเกี่ยวกับการควบคุมและการปฏิบัติงานอันดีของธุรกิจอย่างมีนัยสำคัญ',
					3: '3. ไม่เป็นบุคคลภายในของบริษัทที่ออกหุ้นอ้างอิงตามความหมายที่กำหนดในประกาศที่เกี่ยวข้อง',
					4: '(3.1) ไม่มีการถือหุ้นไม่ว่าโดยทางตรงหรือทางอ้อมในบริษัทที่ออกหุ้นอ้างอิงเกินกว่าร้อยละยี่สิบห้าของจำนวนหุ้นที่มีสิทธิออกเสียงทั้งหมดของบริษัทที่ออกหุ้นอ้างอิงนั้น',
					5: '(3.2) ไม่มีบริษัทที่ออกหุ้นอ้างอิงเป็นผู้ถือหุ้นไม่ว่าโดยทางตรงหรือทางอ้อมเกินกว่าร้อยละยี่สิบห้าของจำนวนหุ้นที่มีสิทธิออกเสียงทั้งหมด',
					// tslint:disable-next-line:max-line-length
					6: '(3.3) ไม่มีผู้ถือหุ้นไม่ว่าโดยทางตรงหรือทางอ้อมรวมกันเกินกว่าร้อยละยี่สิบห้าของจำนวนหุ้นที่มีสิทธิออกเสียงทั้งหมด เป็นกลุ่มบุคคลเดียวกับผู้ถือหุ้นที่ถือหุ้นไม่ว่าโดยทางตรงหรือทางอ้อมในบริษัทที่ออกหุ้นอ้างอิงเกินกว่าร้อยละยี่สิบห้าของจำนวนหุ้นที่มีสิทธิออกเสียงทั้งหมดของบริษัทที่ออกหุ้นอ้างอิง',
					7: '(3.4) ไม่มีกรรมการหรือผู้บริหารเป็นกลุ่มบุคคลเดียวกับกรรมการที่มีอำนาจลงนามผูกพันหรือผู้บริหารของบริษัทที่ออกหุ้นอ้างอิง',
					// tslint:disable-next-line:max-line-length
					8: '(3.5) ไม่มีโครงสร้างการถือหุ้นหรือการจัดการในลักษณะอื่นใดที่แสดงให้เห็นได้ว่าผู้ที่ประสงค์จะยื่นคำขออนุญาตมีอำนาจควบคุมบริษัทที่ออกหุ้นอ้างอิง หรือมีบริษัทที่ออกหุ้นอ้างอิงเป็นผู้มีอำนาจควบคุม หรือมีผู้มีอำนาจควบคุมเป็นบุคคลเดียวกับผู้มีอำนาจควบคุมบริษัทที่ออกหุ้นอ้างอิง',
					// tslint:disable-next-line:max-line-length
					9: '(3.6)  หากผู้ขออนุญาตเป็น/เคยเป็นที่ปรึกษาทางการเงิน หรือผู้มีส่วนเกี่ยวข้องกับบริษัทที่ออกหุ้นอ้างอิงในลักษณะอื่นที่ทำให้สามารถเข้าถึงข้อมูลที่มีสาระสำคัญ ที่มิได้เปิดเผยต่อประชาชนทั่วไปในระยะเวลาหนึ่งปีก่อนวันใช้หุ้นเป็นหลักทรัพย์อ้างอิง  ผู้ขออนุญาตขอรับรองว่า มีมาตรการป้องกันการล่วงรู้ข้อมูลภายในระหว่างหน่วยงานที่รับผิดชอบงานที่ปรึกษาทางการเงินหรืองานที่เกี่ยวข้องกับบริษัทที่ออกหุ้นอ้างอิงนั้น และหน่วยงานที่รับผิดชอบเกี่ยวกับการเสนอขายใบสำคัญแสดงสิทธิอนุพันธ์ที่ออก โดยได้เปิดเผยความเกี่ยวข้องและการรับรองดังกล่าวไว้ในแบบแสดงรายการข้อมูลและร่างหนังสือชี้ชวนแล้วด้วย',
				},
				ACCEPT: 'ข้าพเจ้าขอรับรองว่ามีคุณสมบัติตรงตามเงื่อนไขทุกประการ',
			},
		},
		SIGN_DOCUMENT: {
			HEADER: 'การรับรองเอกสาร',
			FILTER: {
				CERT: 'ไฟล์ cert',
				PASSWORD: 'Password',
			},
			RESEND_DW: 'การรับรอง DW รายตัว (จำนวน {{dwAmount}} ตัว) ไม่สำเร็จ กรุณากดปุ่ม Resend เพื่อทำรายการอีกครั้ง',
			HEADER_RESEND_DW: 'การส่งข้อมูลไม่สำเร็จ',
		},
		INQUIRY_LOG: {
			HEADER:'Inquiry',
			TABLE_HEADER: {
				CREATE_DATE:'วันที่สร้างรายการ (Reserve Quota)',
				ISSUER:'ผู้ขอจัดสรร',
				PRODUCT_TYPE:'ประเภทสินทรัพย์อ้างอิง',
				UNDERLYING_NAME:'สินทรัพย์อ้างอิง',
				UNDERLYING_TYPE:'New/Further Issue',
				TRADING_DATE:'Trading Date',
				REQUESTER:'ผู้ทำรายการ',
				DW_SYMBOL:'ชื่อ DW',
				BATCH_NO:'Batch No.',
				BATCH_TYPE:'Batch Type',
				OFFER:'จำนวนหน่วย DW ที่ขอเสนอขาย',
				RESERVE_UNDERLYING:'จำนวนหุ้นอ้างอิงที่ได้รับจัดสรร',
				ISSUE_PRICE: 'ราคา DW',
				EXERCISE_PRICE: 'ราคาใช้สิทธิ',
				CONVERSION_RATIO: 'อัตราการใช้สิทธิ',
				APPROVED_DATE: 'วันที่อนุมัติ',
				RESERVE_STATUS:'Reserve Status',
				FILING_STATUS:'Filing Status',
				LISTING_STATUS: 'Listing Status',
				MODIFY_DATE_QUOTA: 'Last Modify Date (Quota)',
				MODIFY_DATE_BATCH: 'Last Modify Date (Batch)'
			},
			FILTER: {
				CREATE_DATE:'วันที่สร้างรายการ',
				ISSUER:'ผู้ขอจัดสรร',
				PRODUCT_TYPE:'ประเภทสินทรัพย์อ้างอิง',
				UNDERLYING_NAME:'สินทรัพย์อ้างอิง',
				UNDERLYING_TYPE:'New/Further Issue',
				TRADING_DATE:'Trading Date',
				REQUESTER:'Requester',
				DW_SYMBOL:'ชื่อ DW',
				BATCH_NO:'Batch No.',
				STATUS:'สถานะ'
			}
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard TH',
			SUBSCRIPTION_PROFILE: 'Subscription Profiles TH',
			REPORT35: 'คำขอ 35-DW-1',
			SECURITIESINDEXPROFILE: 'สินทรัพย์อ้างอิง',
			SECURITIESINDEXPROFILESHORT: 'สินทรัพย์อ้างอิง',
			FILING69DWFULL: 'Filing 69-DW-Full',
			FILING69DWSHORT: 'Filing 69-DW-Short',
			FILING69DWSHORTANDFULL: 'Filing 69-DW-Full/Short',
			COMPANYPROFILE: 'Company Profile',
			SUBSCRIPTIONPROFILE: 'Subscription Profile',
			MONITORING: 'Monitoring Board',
			IM: 'IM',
			PROFILE: 'Profile',
			QUOTA: 'สินทรัพย์อ้างอิง',
			SHORT: 'Submit Application',
			COMPANY_PROFILE_LIST: 'Company Profile',
			SUBSCRIPTION_PROFILE_MENU: 'Subscription Profile',
			FILING35DW_1: 'แบบคำขอ 35-DW-1',
			CORPORATE_ACTION: 'รายชื่อหุ้นอ้างอิงที่มี Corporate Action',
			ISSUED_ROOM: 'หุ้นอ้างอิงคงเหลือ',
			INCENTIVE_REPORT:'ผลการทำหน้าที่ MM / ส่วนลด listing fee',
			INQUIRY_LOG:'Inquiry',
			DWS_DOCUMENT:"ข้อมูลที่เกี่ยวข้อง Derivatives Warrant",
			ISOS_URL_REDIRECT:"แบบคำขอเพิ่ม Foreign Underlying "
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Do not have an account?',
				SIGNUP_BUTTON: 'Sign Up',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Your account has been successfully reset.',
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.',
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Username',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
			},
		},
		REFERENTTYPE: {
			null: 'เลือกทั้งหมด',
			0: 'เลขประจำตัวประชาชนในประเทศ',
			1: 'เลขประจำตัวประชาชนต่างประเทศ',
			2: 'เลขที่ใบต่างด้าว',
			3: 'เลขที่ Passport',
			4: 'เลขที่เสียภาษี(ต่างประเทศ)',
			5: 'เลขที่บริษัท',
			6: 'เลขที่มูลนิธิ',
			7: 'สมาคม',
			8: 'วัด',
			9: 'องค์กรที่ไม่ได้รับประโยชน์',
			A: 'เลขที่เสียภาษีคณะบุคคล / ห้างหุ้นส่วนสามัญ',
			B: 'BIC Code',
		},
		MODAL: {
			HEADER: {
				DELTECONFIRM: 'ยืนยันการลบข้อมูล',
				CONFIRM_QUOTA: 'Confirm การจองสินทรัพย์อ้างอิง',
				CANCEL_QUOTA: 'ยกเลิกการจองสินทรัพย์อ้างอิง',
				SEND_APP: 'ยืนยันส่ง Filing 69-DW-Short',
				CONFIRM_RECEIVE: 'ยืนยันกดรับ Filing 69-DW-Short',
				CONFIRM_APPROVE: 'ยืนยันกดอนุมัติ Filing 69-DW-Short',
				CONFIRM_REJECT_APPROVE:
					'ยืนยันยกเลิกอนุมัติ Filing 69-DW-Short',
				CONFIRM_REJECT_RECEIVE: 'ยืนยันยกเลิกกดรับ Filing 69-DW-Short',
				CONFIRM_ROLLBACK_STEP: 'ยืนยันการถอยสถานะ Filing 69-DW-Short',
			},
			BUTTON: {
				CLOSE: 'ปิด',
				DELETE: 'ลบ',
				SUBMIT: 'ยืนยัน',
				BACK: 'ย้อนกลับเพื่อแก้ไข',
			},
		},
		BUTTON: {
			SUBMIT: ' บันทึก',
			BACK: 'กลับ',
			CLOSE: 'ปิด',
			CONFIRM: 'Confirm',
			CANCEL: 'ยกเลิก',
			SIGN: 'รับรอง',
			BROWSE: 'Browse',
			SEND: 'Send',
		},
		ALERT_MESSAGE: {
			HEADER: {
				FILING_69_DW_SHORT: 'Filing 69-DW-Short',
				DOCUMENT: 'Download เอกสาร',
				SUBMIT_APPLICATION_FULL: 'Filling 69-DW-Full',
			},
			SUCCESS: 'สำเร็จ',
			FAILED: 'ไม่สำเร็จ',
			SUBMIT_SUCCESS: 'บันทึกรายการ สำเร็จ',
			CONFIRM_SUCCESS: 'Confirm รายการ สำเร็จ',
			CONFIRM_FAILED: 'Confirm รายการ ไม่สำเร็จ',
			EDIT_SUCCESS: 'แก้ไขรายการ สำเร็จ',
			SEND_SUCCESS: 'ส่งรายการ สำเร็จ',
			SEND_BATCH_SUCCESS: 'ส่ง Filing 69-DW-Short สำเร็จ',
			SIGN_SUCCESS: 'รับรองรายการสำเร็จ',
			PLEASE_INPUT: 'กรุณาระบุ',
			PLEASE_INPUT_GREATER_THAN_OR_EQUAL_TO_1000:
				'กรุณาระบุข้อมูลอย่างน้อย 1,000 ล้าน',
			PLEASE_INPUT_EMAIL_CORRECT: 'กรุณาระบุ อิเมลให้ถูกต้อง',
			PLEASE_INPUT_COMPANY: 'กรุณาเลือกบริษัท',
			PLEASE_SELECT_FILE: 'กรุณาเลือกไฟล์',
			UPLOAD_FILE_SUCCESS: 'อัพโหลดไฟล์ สำเร็จ',
			UPLOAD_FILE_FAILED: 'อัพโหลดไฟล์ ไม่สำเร็จ',
			DELETE_FILE_SUCCESS: 'ลบไฟล์ สำเร็จ',
			DELETE_FILE_FAILED: 'ลบไฟล์ ไม่สำเร็จ',
			ERROR_MESSAGE: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
			DELETE_SUCCESS: 'ลบรายการ สำเร็จ',
			VALIDATE_INPUT: 'กรุณาระบุให้ครบถ้วน',
			DOWNLOAD_SUCCESS: 'Download สำเร็จ',
			PLEASE_INPUT_DW_SYMBOL: 'กรุณาเลือก DW Symbol',
			DUPLICATED_DW_CODE: 'ชื่อย่อถูกใช้งานแล้ว',
			OVER_DW_SERIES: 'รุ่น DW ของหุ้นอ้างอิงที่เลือกเกินกำหนดแล้ว',
			UNAVAIABLE_RESERVED_UNDERLYING:
				'หุ้นอ้างอิงไม่เพียงพอ คงเหลือ {{remain}} หุ้น',
			EXCEED_RESERVED_UNDERLYING:
				'ท่านจองหุ้นอ้างอิงเกินกว่า {{percent}}% ของทุนชำระ',
			INVALID_INFORMATION: 'กรุณากรอกข้อมูลครบถ้วนและถูกต้อง',
			FURTHER_HAS_EXIST:
				'ไม่อนุญาตให้จองหุ้นสามัญอ้างอิง/ดัชนีอ้างอิง เนื่องจาก มี Further Issue รอสั่ง Trade',
			FURTHER_NOT_ALLOWED:
				'ไม่อนุญาตให้จองหุ้นสามัญอ้างอิง/ดัชนีอ้างอิง เนื่องจากไม่เป็นไปตามหลักเกณฑ์ที่ กลต. กำหนด',
			CANNOT_CANCEL_QUOTA:
				'ไม่สามารถยกเลิกได้ เนื่องจากอยู่ใน Filing 69-DW-Short แล้ว',
			CANCEL_SUCCESS: 'ยกเลิกสินทรัพย์อ้างอิง สำเร็จ',
			CANCEL_FAILED: 'ไม่สามารถยกเลิกสินทรัพย์อ้างอิง',
			CANCEL_SHELF_ISSUER_SUCCESS: 'ยกเลิกแบบคำขอ 35-DW-1 สำเร็จ',
			CANCEL_SHELF_ISSUER_FAILED: 'ไม่สามารถยกเลิกแบบคำขอ 35-DW-1',
			CANCEL_SUBMIT_APPLICATION_FULL_SUCCESS:
				'ยกเลิก Filing 69-DW-Full สำเร็จ',
			CANCEL_SUBMIT_APPLICATION_FULL_FAILED:
				'ไม่สามารถยกเลิก Filing 69-DW-Full',
			INVALID_SHELF_ISSUER_EXPIRED:
				'อนุญาตให้สร้างแบบคำขอ 35-dw-1 เมื่อมีข้อมูลวันประชุมผู้ถือหุ้น หรือ วันสิ้นรอบบัญชี และให้ต่ออายุตั้งแต่ 60 วันก่อน Shelf หมดอายุเท่านั้น',
			WARN_CANCEL_SHELF_ISSUER:
				'ยืนยันการยกเลิกแบบคำขอ 35-DW-1 และระบบจะยกเลิกรายการ 69-DW-Full ที่อ้างถึงไปพร้อมกันโดยอัตโนมัติ',
			MAXIMUM_FILE_SIZE: 'ขนาดไฟล์ เกินกว่าที่ระบบกำหนด',
			MAXIMUM_FILE_NAME_SIZE: 'กรุณาอัพโหลดชื่อไฟล์ไม่เกิน 255 ตัวอักษร',
			PDF_ONY: 'กรุณาอัพโหลด pdf file',
			CHECK_BEFORE_SUBMIT: 'โปรดตรวจสอบความถูกต้องก่อนกดบันทึก',
			SPECIAL_FEATURE: 'โปรดอธิบายลักษณะพิเศษเฉพาะรุ่นให้ชัดเจน',
			INVALID_FA_FILE: 'กรุณาแนบเอกสารกรณี เป็น/เคยเป็น FA',
			INVALID_NC_NCR_FILE:
				'กรุณากรอกข้อมูลและแนบเอกสาร NC/NCR ให้ครบถ้วน',
			INVALID_UNDERLYING_HISTORICAL_VOL:
				'โปรดตรวจสอบความถูกต้อง เนื่องจาก Underlying มีจำนวนวันซื้อขายมากกว่า 90 วัน ค่าความผันผวนในอดีตควรมากกว่า 0',
			WARN_UNDERLYING_HISTORICAL_VOL:
				'โปรดตรวจสอบความถูกต้อง เนื่องจาก Underlying มีจำนวนวันซื้อขายน้อยกว่า 90 วัน',
			CANCEL_UNLOCK_ATTACH_DOCUMENT_SUCCESS: 'ยกเลิกการเพิ่มเอกสารสำเร็จ',
			REJECT_UNLOCK_ATTACH_DOCUMENT_SUCCESS: 'ปฏิเสธการเพิ่มเอกสารสำเร็จ',
			APPROVE_UNLOCK_ATTACH_DOCUMENT_SUCCESS:
				'อนุมัติการเพิ่มเอกสารสำเร็จ',
			SIGN_UNLOCK_ATTACH_DOCUMENT_SUCESS: 'ส่งเอกสารสำเร็จ',
			CONFLICT_DATA_NOT_UP_TO_DATE:
				'ไม่สามารถทำรายการได้ เนื่องจากข้อมูลไม่ใช่ข้อมูลปัจจุบัน กรุณาทำการแก้ไขใหม่อีกครั้ง',
			CANCEL_SUBMIT_APPLICATION_SHORT: 'ยืนยันการยกเลิก Filing 69-DW-Short',
			CANCEL_SUBMIT_APPLICATION_SHORT_SUCCESS: 'ยกเลิก Filing 69-DW-Short สำเร็จ',
			CANCEL_SUBMIT_APPLICATION_SHORT_FAILED: 'ไม่สามารถยกเลิก Filing 69-DW-Short',
			ALLOWANCE: {
				TITLE: {
					QUOTA: 'ไม่อนุญาตให้จองสินทรัพย์อ้างอิง เนื่องจาก..',
					SHORT: 'ไม่อนุญาตให้บันทึกรายการ 69-DW-Short เนื่องจาก..',
					SIGN_DW: 'ไม่อนุญาตให้รับรองเอกสาร เนื่องจาก..',
					SEND_SHORT: 'ไม่อนุญาตให้ส่งรายการ 69-DW-Short เนื่องจาก..',
					CREATE_FULL: 'ไม่อนุญาตให้บันทึกรายการ 69-DW-Full เนื่องจาก..',
					SEND_FULL: 'ไม่อนุญาตให้ส่งรายการ 69-DW-Full เนื่องจาก..'
				},
				INDEX_LICENSE: 'ไม่พบ Index license',
				SHELF: 'ไม่พบการต่ออายุ Shelf approval',
				TSD: 'ไม่พบการเป็นสมาชิกนายทะเบียนหลักทรัพย์ TSD',
				MARKET_MAKER: 'ไม่พบข้อมูล Market Maker',
				UNPAID: 'ท่านยังชำระค่าธรรมเนียมไม่ครบถ้วนตามเวลาที่ตลาดหลักทรัพย์ฯ กำหนด โปรดติดต่อฝ่ายการเงินและบัญชี โทร 02-009-9690-1 หรือส่งอีเมลมาที่ AccountingBilling-SET@set.or.th',
				// tslint:disable-next-line:max-line-length
				UNPAID_FULL:
					'ไม่สามารถทำรายการได้ เนื่องจากท่านยังชำระค่าธรรมเนียมไม่ครบถ้วนตามเวลาที่ตลาดหลักทรัพย์ฯ กำหนด โปรดติดต่อฝ่ายการเงินและบัญชี โทร 02-009-9690-1 หรือส่งอีเมลมาที่ AccountingBilling-SET@set.or.th',
				ACCESS_TIME:
					'ช่วงเวลาจองหุ้นอ้างอิง : ทุกวันทำการ เวลา 7:30-23:30 น.',
				FINANCIAL_UPTODATE:
					'ใน company profile งวด/ปี งบการเงินไม่เป็นปัจจุบัน โปรดตรวจสอบ',
				FINANCIAL_SENT:
					'ไม่พบการเผยแพร่ข้อมูลงบการเงินล่าสุด โปรดตรวจสอบ',
			},
			CANNOT_SUBMIT_SUBMITAPP: {
				TITLE: 'ไม่สามารถบันทึก Filing 69-DW-Short',
				LIMIT_TIME:
					'ไม่สามารถส่ง Filing 69-DW-Short (T+1) หลังเวลา 11.30 น.',
				AUTHORIZED_PERSON:
					'จะต้องมีการกรอกโครงสร้างการถือหุ้น, รายชื่อกรรมการ และรายชื่อผู้บริหาร และต้องเลือกผู้มีอำนาจลงนามอย่างน้อย 1 คน',
				MARKET_MAKER:
					'ต้องมีข้อมูลผู้ดูแลสภาพคล่อง และมีการกรอกข้อกำหนดในการดูแลสภาพคล่อง โดยค่าจะต้องไม่เป็น 0 ',
				ATTACHED_DOCUMENT:
					'ต้องมีเอกสารแนบ สำเนามติคณะกรรมการ/ที่ประชุมผู้ถือหุ้น ที่อนุมัติให้ออกใบสำคัญแสดงสิทธิอนุพันธ์ที่จะเสนอขาย',
				INTEREST_RATE: 'ต้องกรอกอัตราดอกเบี้ยผิดนัด',
				IM: 'ต้องอัพโหลดเอกสารสรุปข้อสนเทศ และผ่านการรับรองจากเจ้าหน้าที่ตลาดหลักทรัพย์ฯ',
				SMART: 'คุณสมบัติความเสี่ยง ไม่เป็นไปตามเกณฑ์',
				RICH: 'คุณสมบัติการเงิน ไม่เป็นไปตามเกณฑ์',
				NCR: 'ต้องมีข้อมูล NC/NCR ครบถ้วนและ NCR ต้องมีค่ามากกว่าหรือเท่ากับ 7 ',
				// tslint:disable-next-line:max-line-length
				// SHAREHOLDER: 'พบผู้ถือหุ้นในบริษัทที่ออกหุ้น และผู้ถือหุ้นในบริษัทที่ออกหุ้นอ้างอิง ที่ถือหุ้นเกินกว่าร้อยละ 25 ของจำนวนหุ้นที่มีสิทธิออกเสียงทั้งหมด มีชื่อและนามสกุลตรงกัน กรุณาตรวจสอบข้อมูลผู้ถือหุ้นในระบบ SETSMART',
				// CHIEF: 'พบข้อมูล ผู้บริหารของบริษัทผู้ออก DW และชื่อผู้บริหารของบริษัทหุ้นอ้างอิง มีชื่อและนามสกุลตรงกัน กรุณาตรวจสอบข้อมูลผู้บริหาร',
				// BOARD_DIRECTOR: 'พบกรรมการของบริษัทที่ออกหุ้น และกรรมการของบริษัทที่ออกหุ้นอ้างอิง มีชื่อและนามสกุลตรงกัน กรุณาตรวจสอบข้อมูลกรรมการในระบบ SETSMART',
				SHAREHOLDER:
					'พบข้อมูลของ Issuer ตรงกับข้อมูลผู้ถือหุ้นของ underlying โปรดยืนยันคุณสมบัติการไม่เป็นบุคคลภายใน',
				CHIEF: 'พบข้อมูลของ Issuer ตรงกับข้อมูลผู้บริหาร underlying',
				BOARD_DIRECTOR:
					'พบข้อมูลของ Issuer ตรงกับข้อมูลกรรมการของ underlying โปรดยืนยันคุณสมบัติการไม่เป็นบุคคลภายใน',
				ENABLE_FILING_SHORT_FIRST_LINE:
					'ไม่สามารถดำเนินการยื่น 69-DW-Short ได้เนื่องจากการขอต่ออายุ 35-DW-1 ของปีปัจจุบันได้รับการอนุมัติแล้ว',
				ENABLE_FILING_SHORT_SECOND_LINE:
					'ท่านจะสามารถยื่น 69-DW-Short ได้อีกครั้งเมื่อการขอต่ออายุ 69-DW-full ของปีปัจจุบันได้รับการอนุมัติ',
			},
			SEND_FAILED: 'ส่งรายการไม่สำเร็จ',
			CANCEL_ROOM_FAILED: 'ยกเลิกรายการไม่สำเร็จ',
			SIGN_FAILED: 'รับรองรายการไม่สำเร็จ',
			SIGN_DISCLAIMER_WARNING: 'กรุณายืนยันการรับรองรายการ',
			SIGN_REMARK: 'หมายเหตุ: ท่านต้องรับรองเอกสารใหม่อีกครั้งหากจำนวนสินทรัพย์อ้างอิงบางรายการเกินโควตาที่กำหนด',
			INCOMPLETE_NCR: 'กรุณาระบุข้อมูล NC/NCR ให้ครบถ้วน',
			SIGN_ERROR_MESSAGE: {
				'DIGITALSIGN-01':
					'ขอให้ท่านตรวจสอบและลงลายมือชื่อแบบ Digital Signature ให้ถูกต้อง',
				'DIGITALSIGN-02':
					'ไม่พบสิทธิในการจัดส่งข้อมูลในระบบ DWS ขอให้ท่านตรวจสอบการมอบอำนาจและการจัดทำ EF-3',
				'DIGITALSIGN-03':
					'พบว่าข้อมูลไม่สอดคล้องระหว่างต้นทาง-ปลายทาง ขอให้ท่านตรวจสอบข้อมูลให้ถูกต้องและส่งใหม่อีกครั้ง',
				'DIGITALSIGN-04':
					'พบว่าใบรับรองที่ท่านใช้ลงลายมือชื่อดิจิทัล ไม่ได้ออกโดยหน่วยงาน ที่สำนักงาน ก.ล.ต.ยอมรับ ขอให้ตรวจสอบหน่วยงานที่ยอมรับให้ใช้ลงลายมือชื่อดิจิทัล',
				'DIGITALSIGN-05':
					'พบว่าใบรับรองที่ท่านใช้ลงลายมือชื่อดิจิทัลหมดอายุหรือ ถูกเพิกถอน ขอให้ท่านตรวจสอบกับหน่วยงานที่ออกใบรับรองให้ถูกต้องและส่งใหม่อีกครั้ง',
			},
			FURTHER_DATE:
				'วันที่กระจายได้ครบถ้วนไม่เป็นไปตามเงื่อนไข การรับ DW',
			PASSWORD: {
				CHECK_PASSWORD_FAILED: 'รหัสผ่านไม่ถูกต้อง',
				CHECK_PASSWORD_SUCCESS: 'รหัสผ่านถูกต้อง',
				RESET_PASSWORD_EMAIL_SUCCESS:
					'เปลี่ยนรหัสผ่านสำเร็จ กรุณาตรวจสอบอีเมล',
				RESET_PASSWORD_EMAIL_FAILED:
					'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
			},
			PLEASE_SELECT_AUTHORIZED_PERSON: 'กรุณาเลือกผู้มีอำนาจลงนาม',
			PLEASE_SELECT_COMMITTEE_OR_CEO_AT_LEAST_ONE:
				'กรุณาเลือกกรรมการผูกพันหรือ CEO อย่างน้อย 1 ท่าน',
			PLEASE_SELECT_INTERNAL_CONTROL_DATE:
				'กรุณาเลือกวันที่ประเมินระบบการควบคุมภายใน',
			PLEASE_SELECT_ANNUAL_REPORT_YEAR:
				'กรุณาระบุข้อมูลขออ้างอิงแบบแสดงรายการข้อมูลประจำปี',
			NO_STATUS_ITEM: 'ไม่พบรายการ Status',
			DW_CLOSE_PRICE: 'ราคาใช้สิทธิต่างจากราคาปิดล่าสุดของ underlying {{percent}}% ท่านยืนยันจะดำเนินการต่อหรือไม่'
		},
		SELECT_ALL: 'เลือกทั้งหมด',
		SELECT_STATUS: '{{count}} ตัวเลือก',
		SELECT_FEATURE: 'ยื่น Feature',
		SELECT: 'เลือก',
		SEARCH: 'ค้นหา',
		RESET: 'รีเซ็ต',
		DATA_NOT_FOUND: 'ไม่พบข้อมูล',
		C: 'เลขประจำตัวประชาชนในประเทศ',
		OTHER: 'อื่นๆ',
		UPLOAD_FILE: 'เพิ่มเอกสาร',
		ACTIVITY_LOG:'Activity log',
		EXPAND: 'ขยาย',
		COLLAPSE: 'ย่อ',
		FILE_UPDATE: 'เอกสารมีการเปลี่ยนแปลง',
		DISPLAY: {
			offerType: {
				DL: 'Direct Listing',
				PO: 'Public Offering',
				PP: 'Private Placement',
			},
			dwType: {
				P: 'Put',
				C: 'Call',
			},
			exerciseType: {
				A: '',
				E: 'European',
			},
			settlementStyle: {
				C: 'Cash Settlement',
			},
			securitiesIndex: {
				A: 'SET50',
				B: 'SET51-100 + Market Cap.',
				D: 'INDEX (SET)',
				F: 'INDEX (Foreign)',
				G: 'Foreign Stock'
			},
			settlementPriceIndexType: {
				A: 'ราคาปิดของดัชนีหลักทรัพย์อ้างอิง',
				B: 'ราคาของสัญญาซื้อขายล่วงหน้า',
				C: 'ค่าเฉลี่ยของราคาปิดของดัชนีหลักทรัพย์อ้างอิง 5 วันทำการสุดท้าย',
			},
		},
		CURRENCY: {
			THB: 'บาท',
			USD: 'ดอลลาร์สหรัฐ',
			HKD: 'ดอลลาร์ฮ่องกง',
			JPY: 'เยน',
			CNY: 'หยวน (CNY)',
			CNH: 'หยวน (CNH)',
   			EUR: 'ยูโร',
			GBP: 'ปอนด์สเตอร์ลิง'
		},
		SIXTY_NINE_FULL: {
			CONFIRM: {
				HEADER: 'Confirm',
				AUTHORIZED_PERSON:
					'เมื่อท่านยืนยันการเลือกผู้ลงนามแล้วจะไม่สามารถทำการแก้ไขได้อีก',
				CONFIRM_MESSAGE:
					'กรุณากด "ยืนยัน" เพื่อดำเนินการในขั้นตอนต่อไป',
				CANCEL_MESSAGE: 'หรือกด "ยกเลิก" เพื่อตรวจสอบข้อมูลอีกครั้ง',
			},
		},
		THIRTYFIVEDWONE: {
			TITLE: 'แบบคำขอ 35-DW-1',
			CREATE: {
				HEADER: 'สร้างแบบคำขอ 35-DW-1',
			},
			TABLE_HEADER: {
				ID: 'รหัสรายการ',
				STATUS: 'สถานะ',
				EFFECTIVE_DATE: 'วันที่มีผล',
				EXPIRE_DATE: 'วันที่หมดอายุ',
				DELETE: 'ลบ',
				CANCEL: 'ยกเลิก',
				SENDER: 'ส่ง',
				ALLOWEDTYPE: 'ประเภทการอนุมัติ',
				REF_TO_SUBMIT_APP: 'อ้างถึง 69-DW-Full',
			},
			STATUS: {
				DRAFTINCOMPLETE: 'Draft Incomplete',
				READYTOSEND: 'Ready to Send',
				WAITFORHARDCOPY: 'Wait for hard copy',
				SECCONSIDERING: 'SEC Considering',
				APPROVED: 'Approved',
				CANCELED: 'Cancelled',
			},
			SIGNTYPE: {
				HARD_COPY: 'Hard Copy',
			},
			CONFIRM: {
				HEADER: 'Confirm',
				MESSAGE:
					'กรุณากด "บันทึก" และตรวจสอบความถูกต้องของข้อมูล <b><u>ทุกครั้ง</u></b> ก่อนที่ท่านจะกดปุ่ม "Confirm" ระบบจะจัดส่งอีเมล์ไปยังคณะกรรมการทุกท่านที่เกี่ยวข้องในการลงนามความถูกต้องของข้อมูล',
				MESSAGE_CONFIRM_AUTHORIZED:
					'เมื่อท่านยื่นยันผู้ลงนามแล้วจะไม่สามารถทำการแก้ไขได้อีก',
				CONFIRM_MESSAGE:
					'กรุณากด "ยืนยัน" เพื่อดำเนินการในขั้นตอนต่อไป',
				CANCEL_MESSAGE: 'หรือกด "ยกเลิก" เพื่อตรวจสอบข้อมูลอีกครั้ง',
				CANCEL_AUTHORIZED_MESSAGE:
					'หรือกด "ยกเลิก" เพื่อกลับไปขั้นตอนการเลือกผู้ลงนาม',
			},
		},
		SUBMITAPPLICATION: {
			FILTER: {
				DATE: 'วันที่ทำรายการ',
				STATUS_FILING: 'สถานะ Filing',
				STATUS_LISTING: 'สถานะ Listing',
				TO: 'ถึง',
			},
			TABLE_HEADER: {
				DATE: 'วันที่ทำรายการ',
				BATCH_NO: 'Batch No.',
				TRADING_DATE: 'Trading Date',
				DW_NAME: 'ชื่อ DW',
				STATUS_FILING: 'สถานะ Filing',
				STATUS_LISTING: 'สถานะ Listing',
				APPROVED_DATE: 'วันที่อนุมัติ',
				CANCEL: 'ยกเลิก',
				SEND: 'ส่ง',
				REF_TO_SHELF_ISSUER: 'อ้างถึง 35-DW-1',
			},
			REQUIRED: {
				DW_FULL_SUMMARY: 'กรุณาอัปโหลดเอกสาร ส่วนที่ 1 ข้อมูลสรุป',
				ISSUER_SUMMARY:
					'กรุณาอัปโหลดเอกสาร ส่วนที่ 2 ผู้ออกใบสำคัญแสดงสิทธิอนุพันธ์',
			},
		},
		STATUS_COMMON: {
			DRAFT_INCOMPLETE: 'Draft Incomplete',
			DRAFT_COMPLETE: 'Draft Complete',
			WAIT_TO_VERIFY: 'Wait to Verify',
			WAIT_TO_APPROVE: 'Wait to Approve',
			APPROVED: 'Approved',
			REJECTED: 'Rejected',
			READY_TO_SEND: 'Ready to Send',
			SEC_CONSIDERING: 'SEC Considering',
			WAIT_FOR_HARD_COPY: 'Wait for hard copy',
			WAIT_FOR_SUBMITTING: 'Wait for submitting',
			FIRST_APPROVE: 'นับ 1',
			FIRST_WAIT_FOR_HARD_COPY: 'นับ 1 - Wait for hard copy',
			FIRST_DRAFT_INCOMPLETE: 'นับ 1 - Draft Incomplete',
			FIRST_READY_TO_SEND: 'นับ 1 - Ready to Send',
			FIRST_SEC_CONSIDERING: 'นับ 1 - SEC Considering',
			CANCELLED: 'Cancelled',
			'-': '-',
		},
		SIGN_TYPE_COMMON: {
			HARD_COPY: 'Hard Copy',
		},
		CONSIDERING_ACTION: {
			FIRST_APPROVE: 'นับ 1',
			APPROVE: 'อนุมัติ',
			REVERT: 'ถอยสถานะ',
		},
		SHELF_EXPIRED: 'Shelf Expire',
		MONITOR: {
			TRADING_DATE: 'Trading Date',
			FILING_69_DW_SHORT: 'Filing-69-DW-Short',
			REQUEST_35_DW_2: 'Request-35-DW-2',
			BYPASS_69_DW: 'By pass'
		},
		QUOTA_EXCEL: {
			HEADER: 'จองสินทรัพย์อ้างอิงโดยวิธี Upload Excel File',
			HEADER_RESULT: 'ผลการจองสินทรัพย์อ้างอิงโดยวิธี Upload Excel File',
			DOWNLOAD_TEMPLATE: 'Download Template/Manual',
			SEE_RESULT: 'ดูรายการจองหุ้นอ้างอิง',
			RESULT: 'ผลการจองหุ้นอ้างอิง',
			TEMPLATE_LOCAL: 'Local',
			TEMPLATE_FOREIGN: 'Foreign',
			SAVE_BUTTON: 'Upload',
			RESET_BUTTON: 'Reset',
			RESERVE_SUCCESS: 'จองสำเร็จ',
			RESERVE_ERROR: 'จองไม่สำเร็จ',
			RESERVE_COUNT: 'รายการ',
			BROWSE_EXCEL_ONLY: 'Browse Excel File (.xlsx)',
			SUBSCRIPTION_PROFILE: 'ผู้ดูแลสภาพคล่อง'
		},
		ISSUED_ROOM: {
			SEQUENCE: 'ลำดับ',
			SECURITY_SYMBOL: 'สินทรัพย์อ้างอิง',
			REMAIN_ROOM: 'หุ้นอ้างอิงคงเหลือ (หุ้น)',
			DATE: 'ข้อมูล ณ วันที่ {{date}}',
			MAX_UNDERLYING_ROOM : 'หุ้นอ้างอิงสูงสุดที่จัดสรรได้ (หุ้น:รุ่น)',
			MAX_ISSUED_ROOM : 'หุ้นอ้างอิงสูงสุดที่จัดสรรได้ (หุ้น:สินทรัพย์อ้างอิง)'
		},
		INCENTIVE_REPORT:{
			HEADER:'ผลการทำหน้าที่ MM / ส่วนลด listing fee',
			MONTH_YEAR:'Incentive (เดือน-ปี)',
			AVERAGE_DW:'ค่าเฉลี่ยของผลการปฏิบัติหน้าที่การดูแลสภาพคล่องสำหรับ DW',
			DISCOUNT_DW:'ส่วนลด DW Listing fee',
			MORE_DISCOUNT_DW:'ส่วนลด DW Listing fee (เพิ่มเติม) ในการปฏิบัติหน้าที่ดูแลสภาพคล่องสำหรับ DR/ETF (เฉพาะที่เป็น Cross Border Product)'
		},
		DWS_DOC:{
			DW_INFO:"ข้อมูลที่เกี่ยวข้อง",
			DW:"Derivatives Warrant",
			INFO:"ข้อมูล",
			DOWNLOAD:"Download / Link",
			ABOUT:"เกี่ยวกับโปรแกรม DWS",
			OTHER:"ข้อมูลที่สำคัญจากแหล่งอี่น",
			DWS_MANUAL:"คู่มือหน้าจอของ DWS (PDF)",
			RESEND_MANUAL:"คู่มือการใช้ฟังก์ชั่น Resend (PDF)",
			WORKING_CALENDAR:"ปฏิทินวันทำการของระบบ DWS (Excel)",
			UNDERLYING:"หลักทรัพย์ (Underlying)",
			LOCAL_DW:"สินทรัพย์อ้างอิง (SET website)",
			FOREIGN_DW:"สินทรัพย์ต่างประเทศอ้างอิง (Excel)",
			SEC_WEB:"สำนักงาน ก.ล.ต. (SEC Website)",
			SET_WEB:"ตลาดหลักทรัพย์ฯ (SET Website)",
			ERROR_CASE:"กรณีที่รับรองเอกสารพบ Error",
			CONCLUDE_DW:"สรุปหลักเกณฑ์การออกเสนอขายใบสำคัญแสดงสิทธิอนุพันธ์"

		},
		CHECK_ROOM_RESULT:{
			HEADER:"ผลการตรวจสอบรายการสินทรัพย์อ้างอิง",
			REMARK:"หมายเหตุ: ระบบจะสร้างเอกสารประกอบการยื่นใหม่เฉพาะสินทรัพย์อ้างอิงที่ตรวจสอบสำเร็จ",
			SEQUENCE:"ลำดับที่",
			SECURITY:"สินทรัพย์อ้างอิง",
			DW_NAME:"ชื่อ DW",
			RESULT:"ผลการตรวจสอบ",
			CANCEL:"ยกเลิก",
			SEND_SUBMITAPP:"สร้างเอกสารประกอบการยื่นใหม่",
			CANCEL_SEND_SUBMITAPP:"ยกเลิกการส่งเอกสาร",
			FAILED:"ไม่สำเร็จ",
			SUCCESS:"สำเร็จ"
		}
	},
};
