// Angular
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { BlankComponent } from './views/pages/blank/blank.component'
import { AppGuard } from './app.guard'

const routes: Routes = [
	// {path: 'auth', loadChildren: 'app/views/pages/auth/auth.module#AuthModule'},
	{
		path: 'authen',
		component: BlankComponent,
		children: [
			{
				path: '',
				loadChildren: './core/auth/authentication.module#AuthenticationModule'
			}
		]
	},
	{ path: '', redirectTo: 'dws', pathMatch: 'full' },
	{
		path: 'dws',
		loadChildren: 'src/app/views/themes/default/theme.module#ThemeModule',
		canActivate: [AppGuard],
	},

	{ path: '**', redirectTo: 'dws/error/403', pathMatch: 'full' }
	// {path: '**', redirectTo: 'error/403', pathMatch: 'full'},
]

@NgModule({
	declarations: [BlankComponent],
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
