import { Injectable } from '@angular/core';
import { API } from '@app/shared/app.constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClientService } from '../api-client.service';

@Injectable()
export class CorporateActionService {

  constructor(
    private apiService: ApiClientService,
  ) { }

  getQuotaCorporateAction(): Observable<any> {
    const url = API.GET_QUOTA_CORPORATE_ACITON;
    return this.apiService.get<any>(url).pipe(map(b => b[0]));
  }

}
