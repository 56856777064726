import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewContainerRef, ElementRef, Renderer2 } from '@angular/core';
import { Action, ActionType } from '@app/shared/models/action'
import { isNullOrUndefined } from 'util';
import { DOMAIN } from '@app/shared/app.constant';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'kt-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {
  @ViewChild('elementLink', { read: ElementRef }) elementLink: ElementRef;

  public actionType = ActionType;

  @Input() action: Action
  @Output() eventClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    setTimeout(() => this.setActive(), 150)
  }

  onClick(action: string, item: any, spliceIndex: string) {
    this.eventClick.emit({ action, item, spliceIndex })
  }

  setActive() {
    const url = this.router.url;
    if (!isNullOrUndefined(url) && !isNullOrUndefined(this.action.link)) {
      const currentUrl = this.serializeUrl(url);
      const actionUrl = this.serializeUrl(this.action.link);
      if (currentUrl === actionUrl) {
        this.renderer.addClass(this.elementLink.nativeElement, 'active');
      }
    }
  }


  private serializeUrl(url: string): string {
    return (url || '').replace(DOMAIN.URL, '');
  }
}
