import { Injectable } from '@angular/core';
import { DocumentList } from '@app/shared/components/document-list/document-list.component';

@Injectable()
export class DocumentListService {

  public readonly additionalShelfIssuerDocCode = 'VIA12';
  public readonly additionalSubmitApplicationShortDocCode = 'VSA23';
  public readonly additionalSubmitApplicationFullDocCode = 'VFA14';
  public readonly additionalSubmitApplicationFullFeatureDocCode = 'VSA23';
  public readonly unlockDocumentCode = 'VFA19';

  constructor() { }

  public getWaitDocumentShelfIssuer(): DocumentList[] {
    return [
      {
        label: 'แบบรับรองความถูกต้องของข้อมูล (SEC)',
        formName: 'VIA13',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
    ].slice();
  }

  public getDocumentShelfIssuer(isShowVIA13?: boolean): DocumentList[] {
    return isShowVIA13 ? this.getDocumentShelfIssuerWithVIA13() : this.getDocumentShelfIssuerWithoutVIA13();
  }

  public getDocumentShelfIssuerWithVIA13(): DocumentList[] {
    return [
      {
        label: 'แบบคำขอ 35-DW-1 (SEC)',
        formName: 'VIA11',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'แบบรับรองความถูกต้องของข้อมูล (SEC)',
        formName: 'VIA13',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'สำเนามติคณะกรรมการ หรือ ที่ประชุมผู้ถือหุ้น (SEC)',
        formName: 'VIA01',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'สำเนาหนังสือรับรองบริษัท (ไม่เกิน 3 เดือนก่อนยื่นคำขออนุญาต) (SEC)',
        formName: 'VIA02',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'หนังสือรับทราบภาระหน้าที่ภายหลังการเสนอขาย (กรณีมิใช่บริษัทจดทะเบียน) (SEC)',
        formName: 'VIA04',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'ข้อกำหนดสิทธิมาตรฐาน',
        children: [
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (หุ้นอ้างอิง) (SEC)',
            formName: 'VIA05',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          },
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (ดัชนีหลักทรัพย์อ้างอิง) (SEC)',
            formName: 'VIA06',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          },
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (ดัชนีหลักทรัพย์ต่างประเทศ) (SEC)',
            formName: 'VIA07',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          },
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (หน่วยลงทุนของกองทุนรวมอีทีเอฟ) (SEC)',
            formName: 'VIA14',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          },
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (หลักทรัพย์ต่างประเทศ) (SEC)',
            formName: 'VIA15',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          }
        ],
      },
      {
        label: 'หนังสือมอบอํานาจลงลายมือชื่อรับรองความถูกต้องของข้อมูล กรณี 35-DW-1 (ถ้ามี) (SEC)',
        formName: 'VIA08',
        canDelete: false,
        canBrowse: false,
        canDownload: false
      },
      {
        label: 'สำเนาสัญญาให้บุคคลอื่นมีอำนาจเด็ดขาดในการบริหารงาน (ถ้ามี) (SEC)',
        formName: 'VIA03',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'หนังสือแสดงข้อผูกพันต่อสำนักงานว่า จะทำสัญญา back to back agreement กับบุคคลที่กำหนดก่อนเสนอขายในแต่ละครั้ง (ถ้ามี) (SEC)',
        formName: 'VIA09',
        canDownload: true,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'สัญญา back to back agreement (SEC)',
        formName: 'VIA16',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      {
        label: 'หนังสือแสดงข้อผูกพันต่อสำนักงานว่า จะจัดให้มีผู้ค้ำประกันที่เข้าตามเกณฑ์ ก่อนเสนอขายในแต่ละครั้ง (ถ้ามี) (SEC)',
        formName: 'VIA10',
        canDownload: true,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'สัญญาผู้ค้ำประกัน (SEC)',
        formName: 'VIA17',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
    ].slice();
  }

  public getDocumentShelfIssuerWithoutVIA13(): DocumentList[] {
    return [
      {
        label: 'แบบคำขอ 35-DW-1 (SEC)',
        formName: 'VIA11',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'สำเนามติคณะกรรมการ หรือ ที่ประชุมผู้ถือหุ้น (SEC)',
        formName: 'VIA01',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'สำเนาหนังสือรับรองบริษัท (ไม่เกิน 3 เดือนก่อนยื่นคำขออนุญาต) (SEC)',
        formName: 'VIA02',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'หนังสือรับทราบภาระหน้าที่ภายหลังการเสนอขาย (กรณีมิใช่บริษัทจดทะเบียน) (SEC)',
        formName: 'VIA04',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'ข้อกำหนดสิทธิมาตรฐาน',
        children: [
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (หุ้นอ้างอิง) (SEC)',
            formName: 'VIA05',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          },
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (ดัชนีหลักทรัพย์อ้างอิง) (SEC)',
            formName: 'VIA06',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          },
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (ดัชนีหลักทรัพย์ต่างประเทศ) (SEC)',
            formName: 'VIA07',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          },
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (หน่วยลงทุนของกองทุนรวมอีทีเอฟ) (SEC)',
            formName: 'VIA14',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          },
          {
            label: 'ข้อกำหนดสิทธิฉบับหลัก (หลักทรัพย์ต่างประเทศ) (SEC)',
            formName: 'VIA15',
            canDownload: false,
            canDelete: false,
            canBrowse: false,
          }
        ],
      },
      {
        label: 'หนังสือมอบอํานาจลงลายมือชื่อรับรองความถูกต้องของข้อมูล กรณี 35-DW-1 (ถ้ามี) (SEC)',
        formName: 'VIA08',
        canDelete: false,
        canBrowse: false,
        canDownload: false
      },
      {
        label: 'สำเนาสัญญาให้บุคคลอื่นมีอำนาจเด็ดขาดในการบริหารงาน (ถ้ามี) (SEC)',
        formName: 'VIA03',
        canDownload: false,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'หนังสือแสดงข้อผูกพันต่อสำนักงานว่า จะทำสัญญา back to back agreement กับบุคคลที่กำหนดก่อนเสนอขายในแต่ละครั้ง (ถ้ามี) (SEC)',
        formName: 'VIA09',
        canDownload: true,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'สัญญา back to back agreement (SEC)',
        formName: 'VIA16',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      {
        label: 'หนังสือแสดงข้อผูกพันต่อสำนักงานว่า จะจัดให้มีผู้ค้ำประกันที่เข้าตามเกณฑ์ ก่อนเสนอขายในแต่ละครั้ง (ถ้ามี) (SEC)',
        formName: 'VIA10',
        canDownload: true,
        canDelete: false,
        canBrowse: false,
      },
      {
        label: 'สัญญาผู้ค้ำประกัน (SEC)',
        formName: 'VIA17',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
    ].slice();
  }

  public getDocumentSubmitApplicationShort(): DocumentList[] {
    return [
      {
        label: 'แบบคำขอ 35-DW-2 (SEC)',
        formName: 'VSA24',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      {
        label: 'สำเนามติคณะกรรมการ/ที่ประชุมผู้ถือหุ้น ที่อนุมัติให้ออกใบสำคัญแสดงสิทธิอนุพันธ์ที่จะเสนอขาย (SEC)',
        formName: 'VSA25',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      {
        label: 'เอกสารรายงานอันดับความน่าเชื่อถือฉบับล่าสุด (SEC)',
        formName: 'VSA26',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      {
        label: 'สัญญา back to back agreement (SEC)',
        formName: 'VSA34',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      {
        label: 'สัญญาผู้ค้ำประกัน (SEC)',
        formName: 'VSA35',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      {
        label: 'หนังสือขอจดข้อจำกัดการโอน (SEC)',
        formName: 'VSA27',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      {
        label: 'หนังสือมอบอำนาจลงลายมือชื่อรับรองความถูกต้อง (SEC)',
        formName: 'VSA29',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      {
        label: 'แบบคำขอให้รับ DW เป็นหลักทรัพย์จดทะเบียน (SET)',
        formName: 'VSA01',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
      // {
      //   label: 'สรุปข้อสนเทศภาษาไทย (SET)',
      //   formName: 'VSA30',
      //   canDownload: false,
      //   canDelete: false,
      //   canBrowse: false
      // },
      // {
      //   label: 'สรุปข้อสนเทศภาษาอังกฤษ (SET)',
      //   formName: 'VSA31',
      //   canDownload: false,
      //   canDelete: false,
      //   canBrowse: false
      // },
      {
        label: 'หนังสือยืนยัน NC / NCR (SEC)',
        formName: 'VSA32',
        canDownload: false,
        canDelete: false,
        canBrowse: false
      },
    ];
  }

  public getDocumentSubmitApplicationFull(isShowVFA16?: boolean): DocumentList[] {
    return isShowVFA16 ? this.getDocumentSubmitApplicationFullWithVFA16() : this.getDocumentSubmitApplicationFullWithoutVFA16();
  }

  public getDocumentSubmitApplicationFeatureFull(): DocumentList[] {
    return [
      { label: 'แบบคำขอ 35-DW-2 (SEC)', formName: 'VSA24' },
      { label: 'สำเนามติคณะกรรมการ/ที่ประชุมผู้ถือหุ้น ที่อนุมัติให้ออกใบสำคัญแสดงสิทธิอนุพันธ์ที่จะเสนอขาย (SEC)', formName: 'VSA25' },
      { label: 'เอกสารรายงานอันดับความน่าเชื่อถือฉบับล่าสุด (SEC)', formName: 'VSA26' },
      { label: 'สัญญา back to back agreement (SEC)', formName: 'VSA34' },
      { label: 'สัญญาผู้ค้ำประกัน (SEC)', formName: 'VSA35' },
      { label: 'หนังสือขอจดข้อจำกัดการโอน (SEC)', formName: 'VSA27' },
      { label: 'หนังสือมอบอำนาจลงลายมือชื่อรับรองความถูกต้อง (SEC)', formName: 'VSA29' },
      { label: 'แบบคำขอให้รับ DW เป็นหลักทรัพย์จดทะเบียน (SET)', formName: 'VSA01' },
      // { label: 'สรุปข้อสนเทศภาษาไทย (SET)', formName: 'VSA30' },
      // { label: 'สรุปข้อสนเทศภาษาอังกฤษ (SET)', formName: 'VSA31' },
      { label: 'หนังสือยืนยัน NC / NCR (SEC)', formName: 'VSA32' },
    ].map(({ label, formName }) => {
      return {
        label,
        formName,
        canDelete: false,
        canBrowse: false,
        canDownload: true,
      };
    })
  }

  public getDocumentSubmitApplicationWaitingFull(): DocumentList[] {
    return [
      { label: 'ส่วนที่ 4 การรับรองความถูกต้องของข้อมูล (SEC)', formName: 'VFA15' },
    ].map(({ label, formName }) => {
      return {
        label,
        formName,
        canDelete: false,
        canBrowse: false,
        canDownload: true,
      };
    })
  }

  public getDocumentSubmitApplicationFullWithVFA16(): DocumentList[] {
    return [
      { label: 'ปกหน้า (SEC)', formName: 'VFA01' },
      { label: 'Factsheet (SEC)', formName: 'VFA17' },
      { label: 'ส่วนที่ 1 ข้อมูลสรุป (SEC)', formName: 'VFA02' },
      { label: 'ส่วนที่ 2 ผู้ออกใบสำคัญแสดงสิทธิอนุพันธ์ (SEC)', formName: 'VFA03' },
      { label: 'ส่วนที่ 3 ผู้ค้ำประกันการชำระหนี้ตามใบสำคัญแสดงสิทธิอนุพันธ์ และผู้ที่เป็นคู่สัญญาบริหารความเสี่ยงของบริษัทที่ออกใบสำคัญแสดงสิทธิอนุพันธ์ (ถ้ามี) (SEC)', formName: 'VFA04' },
      { label: 'ส่วนที่ 4 การรับรองความถูกต้องของข้อมูล (SEC)', formName: 'VFA16' },
      { label: 'เอกสารแนบ 1.1 ข้อกำหนดสิทธิฉบับหลัก (หุ้นอ้างอิง) (SEC)', formName: 'VFA05' },
      { label: 'เอกสารแนบ 1.2 ข้อกำหนดสิทธิฉบับหลัก (ดัชนีหลักทรัพย์อ้างอิง) (SEC)', formName: 'VFA06' },
      { label: 'เอกสารแนบ 1.3 ข้อกำหนดสิทธิฉบับหลัก (ดัชนีหลักทรัพย์ต่างประเทศ) (SEC)', formName: 'VFA07' },
      { label: 'เอกสารแนบ 1.4 ข้อกำหนดสิทธิฉบับหลัก (หน่วยลงทุนของกองทุนรวมอีทีเอฟ) (SEC)', formName: 'VFA08' },
      { label: 'เอกสารแนบ 1.5 ข้อกำหนดสิทธิฉบับหลัก (หลักทรัพย์ต่างประเทศ) (SEC)', formName: 'VFA09' },
      { label: 'เอกสารแนบ 2 งบการเงิน (SEC)', formName: 'VFA10' },
      { label: 'เอกสารแนบ 3 แบบรายงานประจำปี (แบบ 56-1 / แบบ 56-DW) (SEC)', formName: 'VFA11' },
      { label: 'เอกสารแนบ 4 ข้อกำหนดสิทธิของใบสำคัญแสดงสิทธิอนุพันธ์ เฉพาะส่วนที่เพิ่มเติมจากข้อกำหนดสิทธิหลักที่ได้ยื่นต่อสำนักงานแล้ว (SEC/SET)', formName: 'VFA12' },
      { label: 'เอกสารแนบ 5 แบบเปรียบเทียบคุณสมบัติของผู้ยื่นคำขอและใบสำคัญแสดงสิทธิอนุพันธ์  (SET)', formName: 'VFA13' },
      { label: 'เอกสารแสดงรายละเอียดข้อมูลเพิ่มเติม/ข้อมูลที่เปลี่ยนแปลง (เช่น กรณี เป็น/เคยเป็น FA) (SEC)', formName: 'VFA18' },

    ].map(({ label, formName }) => {
      return {
        label,
        formName,
        canDelete: false,
        canBrowse: false,
        canDownload: true,
      };
    })
  }

  public getDocumentSubmitApplicationFullWithoutVFA16(): DocumentList[] {
    return [
      { label: 'ปกหน้า (SEC)', formName: 'VFA01' },
      { label: 'Factsheet (SEC)', formName: 'VFA17' },
      { label: 'ส่วนที่ 1 ข้อมูลสรุป (SEC)', formName: 'VFA02' },
      { label: 'ส่วนที่ 2 ผู้ออกใบสำคัญแสดงสิทธิอนุพันธ์ (SEC)', formName: 'VFA03' },
      { label: 'ส่วนที่ 3 ผู้ค้ำประกันการชำระหนี้ตามใบสำคัญแสดงสิทธิอนุพันธ์ และผู้ที่เป็นคู่สัญญาบริหารความเสี่ยงของบริษัทที่ออกใบสำคัญแสดงสิทธิอนุพันธ์ (ถ้ามี) (SEC)', formName: 'VFA04' },
      { label: 'เอกสารแนบ 1.1 ข้อกำหนดสิทธิฉบับหลัก (หุ้นอ้างอิง) (SEC)', formName: 'VFA05' },
      { label: 'เอกสารแนบ 1.2 ข้อกำหนดสิทธิฉบับหลัก (ดัชนีหลักทรัพย์อ้างอิง) (SEC)', formName: 'VFA06' },
      { label: 'เอกสารแนบ 1.3 ข้อกำหนดสิทธิฉบับหลัก (ดัชนีหลักทรัพย์ต่างประเทศ) (SEC)', formName: 'VFA07' },
      { label: 'เอกสารแนบ 1.4 ข้อกำหนดสิทธิฉบับหลัก (หน่วยลงทุนของกองทุนรวมอีทีเอฟ) (SEC)', formName: 'VFA08' },
      { label: 'เอกสารแนบ 1.5 ข้อกำหนดสิทธิฉบับหลัก (หลักทรัพย์ต่างประเทศ) (SEC)', formName: 'VFA09' },
      { label: 'เอกสารแนบ 2 งบการเงิน (SEC)', formName: 'VFA10' },
      { label: 'เอกสารแนบ 3 แบบรายงานประจำปี (แบบ 56-1 / แบบ 56-DW) (SEC)', formName: 'VFA11' },
      { label: 'เอกสารแนบ 4 ข้อกำหนดสิทธิของใบสำคัญแสดงสิทธิอนุพันธ์ เฉพาะส่วนที่เพิ่มเติมจากข้อกำหนดสิทธิหลักที่ได้ยื่นต่อสำนักงานแล้ว (SEC/SET)', formName: 'VFA12' },
      { label: 'เอกสารแนบ 5 แบบเปรียบเทียบคุณสมบัติของผู้ยื่นคำขอและใบสำคัญแสดงสิทธิอนุพันธ์  (SET)', formName: 'VFA13' },
      { label: 'เอกสารแสดงรายละเอียดข้อมูลเพิ่มเติม/ข้อมูลที่เปลี่ยนแปลง (เช่น กรณี เป็น/เคยเป็น FA) (SEC)', formName: 'VFA18' },

    ].map(({ label, formName }) => {
      return {
        label,
        formName,
        canDelete: false,
        canBrowse: false,
        canDownload: true,
      };
    })
  }

  checkXLSXFileType(fileName: string) {
    return fileName.match(/\.([^\.]+)$/)[1] === 'xlsx';
  }

  checkPdfFileType(fileName: string) {
    return fileName.match(/\.([^\.]+)$/)[1].toUpperCase() === 'PDF'
  }
}
